import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateValidationParam { validation: any }
interface CreateValidationResult { validation: any }
interface CreateValidationError { error: string }

export default {
    createvalidation: ac.async<CreateValidationParam, CreateValidationResult, CreateValidationError>(ActionTypes.CREATE_VALIDATION),
}