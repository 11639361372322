import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateAgentInfoParam { agentinfo: any }
interface CreateAgentInfoResult { agentinfo: any }
interface CreateAgentInfoError { error: string }

interface GetAgentInfoParam { userid: string }
interface GetAgentInfoResult { agentinfo: any }
interface GetAgentInfoError { error: string }

interface UpdateAgentInfoParam { userid: string, agentinfo: any }
interface UpdateAgentInfoResult { agentinfo: any }
interface UpdateAgentInfoError { error: string }

export default {
    createagentinfo: ac.async<CreateAgentInfoParam, CreateAgentInfoResult, CreateAgentInfoError>(ActionTypes.CREATE_AGENTINFO),
    getagentinfobyuserid: ac.async<GetAgentInfoParam, GetAgentInfoResult, GetAgentInfoError>(ActionTypes.GET_AGENTINFOBYUSERID),
    updateagentinfobyuserid: ac.async<UpdateAgentInfoParam, UpdateAgentInfoResult, UpdateAgentInfoError>(ActionTypes.UPDATE_AGENTINFOBYUSERID),
}