import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import Wrapper from 'components/Wrapper'

const Home = () => {
    const dispatch = useDispatch()
    const github = useSelector((state: any) => state.github)
    const isLoading = github.status === 'running'
    const repositories = github.repositories
    useEffect(() => {
        //Mount
        console.log('mount')
        return () => {
            console.log('unmount')
        }
    }, [])
    return (
        <Wrapper>
            <Button
                onClick={() => {
                    //dispatch(actions.fetchRepositories.started({}))
                }}
                disabled={isLoading}
            >
                Fetch repositories
            </Button>
        </Wrapper>
    )
}

export default Home