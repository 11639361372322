// Notes
// Template taken from CourseManagerSetup.tsx
// This page has more complex tasks so will skip to the rest first

import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles'
import modal from 'actions/modal'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import TextFieldPassword1 from 'components/Inputs/TextFieldPassword1'
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import textbg from 'assets/textfield.svg'
import logo from 'assets/logo.png'
// import BackBtn from 'assets/TraineeUserManagement/TraineeUserAccountManagementBackBtnpng.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import Calendar from 'assets/BaseImg/calendar.svg'

import 'assets/stylesheets/application.css'
// import Slider1 from 'components/Slider/Slider1'
import Slider1 from 'components/Slider/Slider2'
import { yellow } from '@material-ui/core/colors'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'
import AccountInfoUser from 'components/AccountInfoUser'
import classes from '*.module.css'

import axios from 'axios';

// password
import TextfieldBG from 'assets/textfield.svg'
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



const useStyles = makeStyles(() => ({
  login: {
    width: '100%',
    marginTop: '10px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    }
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',
    paddingBottom: '1rem',
  },
  //back and save buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  container5: {
    width: "100vw",
    height: "84vh",
    position: "absolute",
    textAlign: "center",
    marginTop: '7vh'
  },
  header: {
    fontFamily: "Impact",
    fontSize: "2vw",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  // text input field
  input: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    width: '20rem',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    textAlign: 'center',
    fontSize: '1.2rem',
    letterSpacing: '1px',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&:focus': {
      border: 'none',
      outline: 'none',
    }
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  inputWrapperHidden: {
    position: 'relative',
    opacity: 0,
    img: {
      opacity: 0,
      display: "none"
    }
  },
  // for the column
  container6: {
    width: "100vw",
    height: "71vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "1rem"
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    width: "25vw",
  },
  // text field password
  Passwordinput: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    marginLeft: '2rem',
    textAlign: 'center',
    width: '20rem',
    paddingRight: '0px',
    fontSize: '1.2rem',
    letterSpacing: '1px',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '& input': {
      padding: 0,
      textAlign: 'center',
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  PasswordinputWrapper: {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  PasswordnoBorder: {
    border: "none",
  },
  PasswordInputA: {
    color: 'white',
  },
  inputDate: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '19rem',
    textAlign: 'center',

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
      textAlign: 'center',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputDateClicked: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '19rem',
    textAlign: 'center',
    marginLeft: '3rem',
    height: '60px',

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const TraineeProfileSetup = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  // retrieving data from reducer
  const name = useSelector((state: any) => state.auth.loggedinuser.user.name)
  const accessToken = useSelector((state: any) => state.auth.loggedinuser.token.accessToken)
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const groupname = useSelector((state: any) => state.auth.loggedinuser.user.traineeinfo.groupname)
  const email = useSelector((state: any) => state.auth.loggedinuser.user.email)
  const passwordFromReducer = useSelector((state: any) => state.auth.loggedinuser.user.password)

  // date field
  const [Datevalues, setDatevalues] = useState({type: false});
  const [hasDateInputBeenClick1, sethasDateInputBeenClick1] = useState(false)
  const TypeChange = () => {
    setDatevalues({ ...Datevalues, type: true });
  };

  // text field password
  const [values, setValues] = useState({
    password: passwordFromReducer,
    showPassword: false,
  });

  const handleChange = (prop: any) => (event: any) => {
    settraineePasswordInput(event.target.value)
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  // data to be sent to Backend
  const [traineeNameInput, settraineeNameInput] = useState('')
  const [traineeEmailInput, settraineeEmailInput] = useState('')
  const [traineePasswordInput, settraineePasswordInput] = useState('TRAINEE PASSWORD*')
  const [traineeDateOfBirthInput, settraineeDateOfBirthInput] = useState('')
  const [traineeGroupInput, settraineeGroupInput] = useState('')

  // retrieving from inputs
  const traineeNameRetrieve = (inputValue: any) => {
    settraineeNameInput(inputValue)
  }

  const traineeEmailRetrieve = (inputValue: any) => {
    settraineeEmailInput(inputValue)
  }

  const traineeDateOfBirthRetrieve = (inputValue: any) => {
    settraineeDateOfBirthInput(inputValue)
  }

  const traineeGroupRetrieve = (inputValue: any) => {
    settraineeGroupInput(inputValue)
  }

  // save button activation
  const saveButtonClicked = () => {
    dispatch(modal.show.started({type: 'saveProfileSetup', source: ''}))
    sentToBackEnd()
  }

  const UpdateUserProfile = async () => {
    const DataOfUser = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/${id}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        "password": traineePasswordInput,
      }
    })
    return DataOfUser
  }

  const UpdateTraineeProfile = async () => {
    const req = {
      "groupname": traineeGroupInput,
      "dateofbirth": traineeDateOfBirthInput
    }
    const DataOfUser = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/traineeinfo/${traineeProfileId}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
      data: req
    })
    return DataOfUser
  }
  let traineeDataRequired: { dateOfBirth: any; groupname: any;}[] = []
  let HoldingtraineeDataRequired: { dateOfBirth: any; groupname: any; }[] = []
  const [traineeProfileInfo, settraineeProfileInfo] = useState(traineeDataRequired)
  const [traineeProfileId, settraineeProfileId] = useState('NIL')
  const GetTraineeProfile = async () => {
    const DataOfUser = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/traineeinfo/${id}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      }
    })
    // get trainee data
    // create a state to store the info
    // push the required data into a array
    // set the state with the array
    settraineeGroupInput(DataOfUser.data.data[0].groupname)
    settraineeDateOfBirthInput(DataOfUser.data.data[0].dateofbirth)
    // HoldingtraineeDataRequired.push({
    //   dateOfBirth: DataOfUser.data.dateofbirth,
    //   groupname: DataOfUser.data.groupname
    // })
    settraineeProfileInfo(HoldingtraineeDataRequired)
    settraineeProfileId(DataOfUser.data.id)
    return DataOfUser
  }

  // axios
  function sentToBackEnd() {
    if(traineePasswordInput != 'TRAINEE PASSWORD*'){
      UpdateUserProfile()
    }
    UpdateTraineeProfile()
  }
  const temp = () => {
    let x = 1
  }

  const [placeholder, setplaceholder] = useState(false)
  const [startOfPage, setstartOfPage] = useState(false)
  useEffect(() => {
      if (startOfPage === false) {
      GetTraineeProfile()
      setstartOfPage(true)
    } else {
      temp()
    }
  })

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoUser name={name} />
      </div>
      <div className={classes.container5}>
        <div className="row justify-content-center">
          <div className="col-6">
            <h3 className={classes.header}>TRAINEE PROFILE SETUP</h3>
          </div>
        </div>
        <div className="row justify-content-center pb-3">
          <div className="col-6">
            <h4 className={classes.requiredfield}>* INDICATES REQUIRED FIELD</h4>
          </div>
        </div>
        <div>
          <div className={classes.container6}>
            <div className={classes.column1}>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                {/* <input className={classes.input} placeholder="TRAINEE NAME*" type="text" id="fname" name="fname" onChange={(e) => traineeNameRetrieve(e.target.value)} autoComplete="off"/> */}
                <p className={classes.input}>{name}</p>
              </div>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                {/* <input className={classes.input} placeholder="TRAINEE EMAIL*" type="text" id="fname" name="fname" onChange={(e) => traineeEmailRetrieve(e.target.value)}/> */}
                <p className={classes.input}>{email}</p>
              </div>
              <div className={classes.PasswordinputWrapper}>
                <img src={TextfieldBG} alt="TextfieldBG" />
                <OutlinedInput
                  className={classes.Passwordinput}
                  type={values.showPassword ? 'text' : 'password'}
                  value={traineePasswordInput}
                  classes={{ notchedOutline: classes.Passwordinput }}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className={classes.PasswordInputA}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </div>
            </div>
            <div className={classes.column1}>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                {/* <input autoComplete="off" className={hasDateInputBeenClick1 === true ? classes.inputDateClicked : classes.inputDate} value={traineeDateOfBirthInput?.replace(/T.,'').split('-').join('-')}
                  type={Datevalues.type ? 'date' : 'text'} min="2021-01-01" max="2022-12-31"
                  onChange={(e) => traineeDateOfBirthRetrieve(e.target.value)} onClick={() => TypeChange()} /> */}
                  <p className={classes.input}>{traineeDateOfBirthInput?.replace(/T.*/,'').split('-').join('-')}</p>
              </div>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                {/* <input autoComplete="off" value={groupname} className={classes.input} placeholder={traineeGroupInput} type="text" onChange={(e) => traineeGroupRetrieve(e.target.value)} /> */}
                <p className={classes.input}>{groupname}</p>
              </div>
              <div className={classes.inputWrapperHidden}>
                <img src={textbg} alt="bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/trainee/overview'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="SaveSymbol" />
          <p>SAVE</p>
        </div>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default TraineeProfileSetup;
