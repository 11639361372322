import styled from 'styled-components'

const LoginWrapper = styled.div`
    &&&& {
        width: 400px;
        padding: 40px;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 12px;
    }
`

export default LoginWrapper
