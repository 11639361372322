import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { push } from 'connected-react-router'
import modal from 'actions/modal'
import Simplebar from 'components/Scroll/simplebar'
import SimpleBarReact from "simplebar-react";
import { makeStyles } from '@material-ui/core/styles';
import IconSwiper from 'components/platform/IconSwiperGallery'
import ToggleButton4Choice from 'components/Slider/ToggleButton4Choice'
import bg from 'assets/platform/addFiles.png'
import board from 'assets/platform/boards/board.png';
import LeverPuzzle from 'assets/platform/galleryBoard/APTP03_4levers-BW.png'
import LeverPuzzleActive from 'assets/platform/galleryBoard/APTP03_4levers.png'
import Telephone from 'assets/platform/galleryBoard/APTP06_Telephone_with_keypad-BW.png'
import TelephoneActive from 'assets/platform/galleryBoard/APTP06_Telephone_with_keypad.png'
import GlassCase from 'assets/platform/galleryBoard/APTT06_glasscase-BW.png'
import GlassCaseActive from 'assets/platform/galleryBoard/APTT06_glasscase.png'
import BankSafe from 'assets/platform/galleryBoard/APTT08A-BankSafeWheel-BW.png'
import BankSafeActive from 'assets/platform/galleryBoard/APTT08A-BankSafeWheel.png'
import Radio from 'assets/platform/galleryBoard/APTT09A-Radio-BW.png'
import RadioActive from 'assets/platform/galleryBoard/APTT09A-Radio.png'
import Notepad from 'assets/platform/galleryBoard/APTT05_notepad-BW.png'
import NotepadActive from 'assets/platform/galleryBoard/APTT05_notepad.png'
import iPad from 'assets/platform/galleryBoard/APTT11A_ipad-BW.png'
import iPadActive from 'assets/platform/galleryBoard/APTT11A_ipad.png'
import Laptop from 'assets/platform/galleryBoard/APTT12A_laptop-BW.png'
import LaptopActive from 'assets/platform/galleryBoard/APTT12A_laptop.png'
import Blackboard from 'assets/platform/galleryBoard/APTT13_blackboard-BW.png'
import BlackboardActive from 'assets/platform/galleryBoard/APTT13_blackboard.png'
import Whiteboard from 'assets/platform/galleryBoard/APTT14_whiteboard-BW.png'
import WhiteboardActive from 'assets/platform/galleryBoard/APTT14_whiteboard.png'
import noneNew from 'assets/platform/galleryBoard/none.png'
import noneSelected from 'assets/platform/galleryBoard/propsType/noneSelected.png'
import puzzle from 'assets/platform/galleryBoard/puzzle.png'
import puzzleSelected from 'assets/platform/galleryBoard/propsType/puzzleSelected.png'
import trigger from 'assets/platform/galleryBoard/trigger.png'
import triggerSelected from 'assets/platform/galleryBoard/propsType/triggerSelected.png'
import levelSequenceTop from 'assets/platform/galleryBoard/levelSequenceTop.png'
import levelSequenceBottom from 'assets/platform/galleryBoard/levelSequenceBottom.png'
import PDFupload from 'assets/platform/galleryBoard/PDFBig.png'
import save from 'assets/platform/save.png'
import exit from 'assets/platform/exit.png'
import quizTextUpload from 'assets/platform/galleryBoard/galleryBoardSlider/quizTextUpload.png'
import { propsActions } from 'actions/propsActions';
import propsNew from 'actions/props';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  propsContainer: {
    right: '4vh',
    width: '50vh',
    bottom: '6vh',
    height: '80vh',
    position: 'fixed',
  },
  propsBackground: {
    position: 'absolute',
    top: 0,
    zIndex: -1,
    width: '56vh',
    '& img': {
      width: '50vh',
      position: 'fixed',
      right: '4vh',
      bottom: '6vh'
    }
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4vh 3vw',
  },
  propsContent: {
    padding: '60px 50px',
    '& h3': {
      color: theme.palette.primary.main,
    }
  },
  props: {
    overflow: 'hidden',
    "& img": {
      float: 'left',
      cursor: 'pointer',
      width: '50%'
    }
  },
  line: {
    color: theme.palette.primary.main,
    position: 'relative',
    borderColor: theme.palette.primary.main,
    width: '100%',
  },
  propsParam: {
    /*padding: '20px',
    height: '69vh' */
  },
  propsParamP: {
    color: '#fff',
    width: '100%',
    fontFamily: 'impact',
    fontSize: '2vh',
    letterSpacing: '1px'
  },
  paramType: {
    overflow: 'hidden',
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '8vh',
    "& img": {
      width: '2vw',
      float: 'left',
      paddingRight: '10px',
      cursor: 'pointer'
    },
    "& img:last-child": {
      paddingRight: '0px',
    }
  },
  paramContent: {
    width: '100%',
    marginTop: '5%',
  },
  paramMessage: {
    padding: '1rem 1rem',
    border: '2px solid #fff',
    height: '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "& p": {
      color: '#ffffff',
      textTransform: 'uppercase',
      fontSize: '10px',
    },
  },
  PDFcase: {
    display: 'flex',
    '& img': {
      width: '4rem',
      height: '4rem'
    }
  },
  PDFWordcase: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginBottom: '0rem',
      fontSize: '1rem'
    }
  },
  areaText1: {
    background: 'transparent',
    width: '100%',
    height: '21%',
    color: '#FFD949',
    fontSize: '1.6vh',
    lineHeight: '1.5',
    border: 'none'
  },
  areaText2: {
    background: 'transparent',
    width: '100%',
    height: '80%',
    color: '#fff',
    fontSize: '1.6vh',
    lineHeight: '1.5',
    border: 'none'
  },
  paramWeblink: {
    display: 'flex',
    flexDirection: "column",
    "& > p": {
      color: theme.palette.primary.main,
    },
    "& > input": {
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      // width: '18.5vw',
      flexGrow: '1',
      height: '4vh',
      color: '#fff',
      paddingLeft: 15

    }
  },
  paramQuizText: {
    border: '2px solid #fff',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      cursor: 'pointer',
    },
    '& p': {
      color: '#ffffff',
      fontSize: '9px',
      textAlign: 'right',
      lineHeight: '11px',
      paddingBottom: '5px',
      textTransform: 'uppercase',
      paddingRight: '6px',
      wordSpacing: '2px',
      margin: 0,
    }
  },
  quizTextUploadWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: "0.5rem",
    '& input': {
      width: '6.5rem',
      position: 'absolute',
      opacity: 0
    }
  },
  quizTextQns: {
    flexGrow: 1,
    background: 'transparent',
    color: '#fff',
    fontSize: '1.6vh',
    lineHeight: '1.5',
    marginBottom: '0.5rem',
    height: '10vh'
  },
  paramQuizText1: {
    // border: '2px solid #fff',
    height: '44vh',
    overflow: 'hidden',

    '& p': {
      color: '#ffffff',
      fontSize: '10px',
      textAlign: 'right',
      lineHeight: '11px',
      textTransform: 'uppercase',
      wordSpacing: '2px',
      margin: 0,
    }
  },
  ExtraBorder: {
    border: '2px solid #fff',
    height: '38vh',
    display: 'flex',
    flexDirection: 'column',
  },
  quizChoice: {
    display: 'flex',
    justifyContent: "center",
    height: '28vh'
  },
  quizChoiceQns: {
    flexGrow: 1,
    background: 'transparent',
    color: '#fff',
    fontSize: '1.6vh',
    lineHeight: '1.5',
    height: '10vh',
    marginBottom: '0.5rem',
  },
  textUpload: {
    width: '6vw',
    cursor: 'pointer',
  },
  textUploadWrapper: {
    display: 'flex',
    justifyContent: "flex-end",
    padding: "0.5rem",
    '& input': {
      width: '5vw',
      height: '18px',
      position: 'absolute',
      opacity: 0
    }
  },
  quizChoiceHeader: {
    display: 'flex',
    flexDirection: 'column',

    '& p': {
      color: '#ffffff',
      fontSize: '9px',
      textAlign: 'right',
      lineHeight: '11px',
      paddingBottom: '5px',
      textTransform: 'uppercase',
      paddingRight: '6px',
      wordSpacing: '2px',
    }
  },
  quizChoiceAnswer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '0rem',
    justifyItems: 'center',
    alignItems: 'center',
    height: '18vh',
    width: '10.6vh',
    marginLeft: '0px',
  },
  quizChoiceAnsGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
  quizChoiceAns: {
    background: 'transparent',
    color: '#fff',
    fontSize: '1.6vh',
    lineHeight: '1.5',
    height: '100%',
    width: '100%',
  },
  quizChoiceAnswerWrapper: {
    display: 'flex',
    '& input': {
      width: '4rem',
      position: 'absolute',
      paddingTop: '2vh',
      opacity: 0
    }
  },
  propname: {
    display: 'flex',
    '& p': {
      color: '#fff'
    },
  },
  title: {
    color: '#FFD947',
    fontFamily: 'impact',
    position: 'relative',
    fontSize: '3vh'
  },
  propsType: {
    overflow: 'hidden',
    color: '#3F4040',
    fontFamily: 'impact',
    position: 'relative',
    fontSize: '2vh',
    marginBottom: '10px',
  },
  propGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: "center",
  },
  test1: {
    paddingTop: '1rem',
    width: '12vh',
    height: 'auto',
    flexGrow: 1,
    position: 'relative',
    top: '1rem'
  },
  test2: {
    paddingTop: '1rem',
    width: '11vh',
    height: 'auto',
    flexGrow: 1,
    paddingRight: '1.1rem'
  },
  test3: {
    width: '11vh',
    height: 'auto',
    paddingTop: '1.4rem',
    paddingRight: '1.1rem'
  },
  nameChoice: {
    color: '#00000',
    backgroundColor: '#FED700',
    fontFamily: 'impact',
    position: 'relative',
    left: '2rem',
    fontSize: '1.5vh',
    marginBottom: '0',
    width: '6rem',
    textAlign: 'center',
    marginLeft: '10px'
  },
  typeChoice: {
    display: 'flex',
    position: 'relative',
    marginTop: '1.5rem'
  },
  saveExit: {
    paddingRight: '7vh',
    position: 'fixed',
    bottom: '9vh',
    right: '0vw',
    '& img': {
      width: '15vh',
      cursor: 'pointer',
    }
  },
  testExtra: {
    position: 'absolute',
    bottom: '2000px'
  },
  BGimage: {
    width: '100%'
  },
  filesAdd: {
    paddingTop: '1rem',
    cursor: 'pointer'
  },
  buttonUpload: {
    float: 'right',
    position: 'relative',
    top: '13vh',
    paddingRight: '1rem',
    cursor: 'pointer'
  },
  quizTextHeader: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      width: '5rem',
      position: 'relative',
      left: '15vw'
    }
  },
  quizTextHeaderWord1: {
    fontSize: '0.7rem',
    letterSpacing: '1.5px',
    color: 'black',
    fontFamily: 'impact',
    paddingTop: '0.2rem'
  },
  quizTextHeaderWord2: {
    fontSize: '0.7rem',
    fontFamily: 'impact',
    paddingTop: '5px',
    letterSpacing: '1.5px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  quizTextImageUpload: {
    width: '11%',
    height: '100%',
    paddingLeft: '1rem',
    marginLeft: '3rem',
    cursor: 'pointer'
  },
  textArea: {
    display: 'flex',
    height: '22.5vh',
    marginLeft: '0px'
  },
    textAreaAns1:{
      background: 'transparent',
      width: '20.8vh',
      height: '100%',
      color: '#fff',
      fontSize: '1.6vh',
      lineHeight: '1.5',
    },
  textAreaAns2: {
    background: 'transparent',
    flexGrow: 1,
    color: '#fff',
    fontSize: '1.6vh',
    lineHeight: '1.5',
  },
  qcImageUpload: {
    width: '3.8vw',
    cursor: 'pointer',
    paddingBottom: '0.5rem'
  },
  quizAnswer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    marginTop: '1rem',

    '& p': {
      color: '#FDD84D',
      width: '9.3rem',
      marginTop: '1.04rem',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginRight: '13px',
    }
  },
  hotspotContainer: {
    display: 'flex',
    marginBottom: '1rem',
  },
  hotspot: {
    color: '#FFFFFF',
    margin: '0rem',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: "2vh",
    // paddingRight: '1rem'
  },
  hotspotInfo: {
    color: '#FFD949',
    margin: '0rem',
    fontFamily: 'impact',
    fontSize: "2vh",
    letterSpacing: '1px',
    paddingLeft: '1rem'
  },
  propTypeOption: {
    display: 'flex',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  propTypeCasing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
    height: '90px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& img': {
    }
  },
  propTypeCasingActive: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
    height: '90px',
    justifyContent: 'space-between',

    '& img': {
      width: '49px',
    }
  },
  propTypeName: {
    color: '#797979',
    backgroundColor: '#404040',
    marginRight: '1rem',
    marginLeft: '1rem',
    textAlign: "center"
  },
  propTypeNameActive: {
    color: '#000000',
    backgroundColor: '#FDD700',
    marginRight: '1rem',
    marginLeft: '1rem',
    textAlign: "center",
    fontFamily: 'Myriad Pro'
  },
  propTypeNameCasing: {
    backgroundColor: '#404040',
    cursor: 'pointer',
  },
  propTypeNameCasingActive: {
    backgroundColor: '#FDD700',
    position: 'relative',
  },
  propNamePic: {
    marginBottom: '2rem',

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      '& > div': {
        width: '30%',
      }
    },
  },
  propNamePicBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& p': {
      color: '#797979',
      backgroundColor: '#404040',
      fontSize: "0.9rem",
      textAlign: 'center',

      '&.active': {
        color: '#000000',
        backgroundColor: '#FDD700',
      }
    },
    '& img': {
      width: '100%'
    }
  },
  level: {
    display: 'flex',
    paddingLeft: '1rem',
    fontSize: '2vh',
    alignItems: 'center',
    '& p': {
      color: "#797979",
      margin: '0px .2vw',
      paddingTop: '4px',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '0.9vw',
    },
    '& input': {
      width: '2.4vw',
      height: '2vh',
      backgroundColor: '#3F3F3F',
      border: 'none',
      marginLeft: '0.5rem',
      color: '#797979'
    },
    '& .buttons': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& button': {
        border: 'none',
        margin: '0px .1vw',
        borderRadius: '2px',
        fontSize: '1vw',
        fontFamily: 'Impact',
        width: '1.4vw',
        lineHeight: '1.2vw',

        '&.active': {
          backgroundColor: '#FDD700',
          color: '#000000',
          pointerEvents: 'none',
        }
      }
    },

    '& .buttonsphone': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',

      '& button': {
        border: 'none',
        margin: '.18vw .1vw',
        borderRadius: '2px',
        fontSize: '1vw',
        fontFamily: 'Impact',
        width: 'calc(100% * (1/6) - 2px)',
        lineHeight: '1.2vw',

        '&.active': {
          backgroundColor: '#FDD700',
          color: '#000000',
          pointerEvents: 'none',
        }
      }
    },
  },
  sTop:{
    width: '100%',
    position: 'relative',
    left: '9px',
    top: '16px',
    marginBottom: '10px',
  },
  sBottom: {
    left: '9px',
    width: '100%',
    position: 'relative',
    marginBottom: '10px',
    marginTop: '4px',
  },
  scroll: {
    height: 'auto',
    marginTop: '2rem',
    scrollSnapStop: 'always'
  },
  simpleBar: {
    maxHeight: '67vh'
  },
  propsBoxShown: {
    display: 'block',
  },
  propsBoxHidden: {
    display: 'none',
  },
  unique: {
    border: 'none',
    margin: '0 auto',
    background: '#fdd701',
    textTransform: 'uppercase',
    fontSize: '0.85vw',
    fontFamily: 'Impact',
    letterSpacing: '0.5px',
    marginLeft: '5.8vw',
    marginTop: '-5px',
    display: 'block',
  },
  unique2: {
    border: 'none',
    margin: '0 auto',
    background: '#fdd701',
    textTransform: 'uppercase',
    fontSize: '0.85vw',
    fontFamily: 'Impact',
    letterSpacing: '0.5px',
    marginLeft: '5.8vw',
    marginTop: '4px',
    display: 'block',
  },
  file: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '7px',

    '& button': {
      borderRadius: '20px',
      background: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      width: 'auto',
      height: '16px',
      lineHeight: '12px',
      fontSize: '9px',
      textAlign: 'center',
      marginTop: '-2px',
    }
  },
  paramMessagefile : {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '7px',

    '& button': {
      borderRadius: '20px',
      background: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      width: 'auto',
      marginLeft: '4px',
      height: '16px',
      lineHeight: '12px',
      fontSize: '9px',
      textAlign: 'center',
      marginTop: '-2px',
    }
  },
  selectednumber: {
    color: '#fdd701',
    fontWeight: 'bold',
    fontFamily: 'Impact',
    minWidth: '32px',
  }
}));

function PropBox(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeParam = useSelector((state: any) => state.propsReducer.activeParam)
  const locationType = useSelector((state: any) => state.propsReducer.locationType)

  const messageValueTitle = useSelector((state: any) => state.propsReducer.messageValueTitle)
  const messageValueContent = useSelector((state: any) => state.propsReducer.messageValueContent)
  const propsType = useSelector((state: any) => state.propsReducer.propsType)
  const propsName = useSelector((state: any) => state.propsReducer.propsName)
  const file = useSelector((state: any) => state.propsReducer.file)
  const propsdata = useSelector((state: any) => state.props.data)
  const weblinkValue = useSelector((state: any) => state.propsReducer.weblinkValue)

  const quizTextQuestion = useSelector((state: any) => state.propsReducer.quizTextQuestionValue)
  const quizTextAnswer1Value = useSelector((state: any) => state.propsReducer.quizTextAnswer1Value)
  const quizTextAnswer2Value = useSelector((state: any) => state.propsReducer.quizTextAnswer2Value)

  const quizChoiceQuestion = useSelector((state: any) => state.propsReducer.quizChoiceQnsvalue)
  const quizChoiceAnswerAvalue = useSelector((state: any) => state.propsReducer.quizChoiceAnswerAvalue)
  const quizChoiceAnswerBvalue = useSelector((state: any) => state.propsReducer.quizChoiceAnswerBvalue)
  const quizChoiceAnswerCvalue = useSelector((state: any) => state.propsReducer.quizChoiceAnswerCvalue)
  const quizChoiceAnswerDvalue = useSelector((state: any) => state.propsReducer.quizChoiceAnswerDvalue)
  const quizChoiceCorrectAns = useSelector((state: any) => state.propsReducer.quizChoiceCorrectAnsValue)

  const levelSequence1 = useSelector((state: any) => state.propsReducer.levelSequence1)
  const levelSequence2 = useSelector((state: any) => state.propsReducer.levelSequence2)
  const levelSequence3 = useSelector((state: any) => state.propsReducer.levelSequence3)
  const levelSequence4 = useSelector((state: any) => state.propsReducer.levelSequence4)

  const phoneNumber1 = useSelector((state: any) => state.propsReducer.phoneNumber1)
  const phoneNumber2 = useSelector((state: any) => state.propsReducer.phoneNumber2)
  const phoneNumber3 = useSelector((state: any) => state.propsReducer.phoneNumber3)
  const phoneNumber4 = useSelector((state: any) => state.propsReducer.phoneNumber4)

  const [isFirstVist, setIsFirstVist] = useState(true);
  const [formData, setFormData] = useState('');
  const [leverActive, setLeverActive] = useState(new Array);
  const [phoneActive, setPhoneActive] = useState(new Array);

  let iframe = document.getElementById('PyramidIFrame');
  let PropForm = new FormData();

  if(propsdata?.data[0]?.environment?.name == 'Pyramid'){
    iframe = document.getElementById('PyramidIFrame');
  } else if (propsdata?.data[0]?.environment?.name == 'Chamber') {
    iframe = document.getElementById('ChamberIFrame');
  } else if (propsdata?.data[0]?.environment?.name == 'Controlroom') {
    iframe = document.getElementById('CommandIFrame');
  }

  const setLevelSequenceFunc = (e: any) => {
    if(leverActive.length == 0){
      dispatch(propsActions.levelSequence1(e))
    }
    if(leverActive.length == 1){
      dispatch(propsActions.levelSequence2(e))
    }
    if(leverActive.length == 2){
      dispatch(propsActions.levelSequence3(e))
    }
    if(leverActive.length == 3){
      dispatch(propsActions.levelSequence4(e))
    }
    setLeverActive(activeButtons => [...activeButtons, e]);
  }

  const setPhoneNumberFunc = (e: any) => {
    if(phoneActive.length < 4){
      if(phoneActive.length == 0){
        dispatch(propsActions.phoneNumber1(e))
      }
      if(phoneActive.length == 1){
        dispatch(propsActions.phoneNumber2(e))
      }
      if(phoneActive.length == 2){
        dispatch(propsActions.phoneNumber3(e))
      }
      if(phoneActive.length == 3){
        dispatch(propsActions.phoneNumber4(e))
      }
      setPhoneActive(activeButtons => [...activeButtons, e]);
    }
  }

  const resetLevelSequence = () => {
    dispatch(propsActions.levelSequence1('?'));
    dispatch(propsActions.levelSequence2('?'));
    dispatch(propsActions.levelSequence3('?'));
    dispatch(propsActions.levelSequence4('?'));
    setLeverActive([]);
  }

  const resetPhoneNumber = () => {
    dispatch(propsActions.phoneNumber1('?'));
    dispatch(propsActions.phoneNumber2('?'));
    dispatch(propsActions.phoneNumber3('?'));
    dispatch(propsActions.phoneNumber4('?'));
    setPhoneActive([]);
  }

  const messageTitleOnChange = (e: any) => {
    dispatch(propsActions.updateMessageTitle(e.target.value))
  }

  const messageContentOnChange = (e: any) => {
    dispatch(propsActions.updateMessageContent(e.target.value))
  }

  const weblinkeOnChange = (e: any) => {
    dispatch(propsActions.updateWeblink(e.target.value))
  }

  const quizTextQns = (e: any) => {
    dispatch(propsActions.updateQuizQns(e.target.value))
  }

  const quizTextAnswer1 = (e: any) => {
    dispatch(propsActions.updateQuizText1(e.target.value))
  }

  const quizTextAnswer2 = (e: any) => {
    dispatch(propsActions.updateQuizText2(e.target.value))
  }

  const quizChoiceQns = (e: any) => {
    dispatch(propsActions.updateQuizChoiceQns(e.target.value))
  }

  const quizChoiceAnswerA = (e: any) => {
    dispatch(propsActions.updateQuizChoiceA(e.target.value))
  }

  const quizChoiceAnswerB = (e: any) => {
    dispatch(propsActions.updateQuizChoiceB(e.target.value))
  }

  const quizChoiceAnswerC = (e: any) => {
    dispatch(propsActions.updateQuizChoiceC(e.target.value))
  }

  const quizChoiceAnswerD = (e: any) => {
    dispatch(propsActions.updateQuizChoiceD(e.target.value))
  }

  const [options] = React.useState([{
    option1: "A",
    option2: "B",
    option3: "C",
    option4: "D",
  }]);

  const OnChangeFileInput = (e: any) => {
    let imageFile = e.target.files[0];
    const img = new Image();
    img.src = window.URL.createObjectURL(imageFile)

    if(imageFile != null) {
      img.onload = () => {
        if((img.width <= 800 && img.height <= 500) && imageFile.size < 1000000){
          dispatch(propsActions.file('****' + imageFile.name.substr(imageFile.name.length - 4)));
          setFormData(imageFile);
        } else {
          if(img.width > 800 || img.height > 500) {
            dispatch(modal.show.started({type: 'imageDimensionError', source: ''}))
          } else if(imageFile.size > 1000000) {
            dispatch(modal.show.started({type: 'fileSizeError', source: ''}))
          }
        }
      }
    }
  }

  const RemoveImage = () => {
    dispatch(propsActions.file(''));
    setFormData('');
  }

  useEffect(() => {
    if(props.selectedPropId != 1){
      if(isFirstVist) {
        setIsFirstVist(false);
        dispatch(propsNew.getpropsbycourseidandlocationid.started({courseid: props.courseId,
          locationname: props.environmentName.charAt(0).toUpperCase() + props.environmentName.slice(1), locationid: props.selectedPropId, timer: 1000}))
      } else {
        dispatch(propsActions.updateWeblink(''))
        dispatch(propsActions.updateMessageTitle(''))
        dispatch(propsActions.updateMessageContent(''))
        dispatch(propsActions.updateQuizQns(''))
        dispatch(propsActions.updateQuizText1(''))
        dispatch(propsActions.updateQuizText2(''))
        dispatch(propsActions.updateQuizChoiceQns(''))
        dispatch(propsActions.updateQuizChoice(''))
        dispatch(propsActions.updateQuizChoiceA(''))
        dispatch(propsActions.updateQuizChoiceB(''))
        dispatch(propsActions.updateQuizChoiceC(''))
        dispatch(propsActions.updateQuizChoiceD(''))
        dispatch(propsActions.file(''));
        setFormData('');
        dispatch(propsNew.getpropsbycourseidandlocationid.started({courseid: props.courseId,
          locationname: props.environmentName.charAt(0).toUpperCase() + props.environmentName.slice(1), locationid: props.selectedPropId, timer: 0}))
      }
    }
  }, [props.selectedPropId]);

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  const setActivePropsFunc = (assprops: string) => {
    dispatch(propsActions.propsName(assprops));

    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        'assignableprops': assprops
      }, "*");
    }
  }

  const setActivePropsTypeFunc = (propstype: any) => {
    if(propstype == 'f2f31afa-cee7-4895-9f46-448b36c571ad') {
      dispatch(propsActions.propsType('f2f31afa-cee7-4895-9f46-448b36c571ad'))
    } else if (propstype == '0bacf55d-629b-4f09-ad3f-799024fe7617') {
      dispatch(propsActions.propsType('0bacf55d-629b-4f09-ad3f-799024fe7617'))
    } else if (propstype == '12423be0-f9d6-4902-9454-55f27a17b1a8') {
      dispatch(propsActions.propsType('12423be0-f9d6-4902-9454-55f27a17b1a8'))
    } else {
      dispatch(propsActions.propsType('1326a141-112d-41a1-b848-09887894dfc4'))
    }
  }

  const paramContent = (type: string) => {
    switch (type) {
      case "None":
        return null;
      case "Message":
        return (
          <div className={classes.paramMessage}>
            <textarea value={messageValueTitle} onChange={(e) => {messageTitleOnChange(e) }} placeholder="TITLE" className={classes.areaText1}></textarea>
            <textarea value={messageValueContent} onChange={(e) => { messageContentOnChange(e) }} placeholder="ENTER TEXT" className={classes.areaText2}></textarea>
          </div>
        )
      case "Image":
        return (
          <div className={classes.paramMessage}>
            <div className="uploaderBox">
              <img src={bg} id="upfile1" className="pic" />
              <input autoComplete="off" type="file" accept="image/png,image/jpeg,image/jpg" className="load" onChange={(e)=> OnChangeFileInput(e)} />
            </div>
            {file != '' ? <div className={classes.paramMessagefile}><p>File: {file}</p><button onClick={(e)=> RemoveImage()}>Remove</button></div> : null }
            <div className={classes.PDFcase}>
              <img src={PDFupload} alt="PDFupload" />
              <div className={classes.PDFWordcase}>
                <p>MAX FILE SIZE: 1MB<br />*IMAGE DIMENSION: 800X500 PIXELS.</p>
              </div>
            </div>
          </div>
        )
      case "Weblink":
        return (
          <div className={classes.paramWeblink}>
            <p>ENTER URL</p>
            <input autoComplete="off" type="text" value={weblinkValue} onChange={(e) => {weblinkeOnChange(e) }} placeholder="Please fill in weblink" />
          </div>
        )
      case "QuizText":
        return (
          <div className={classes.paramQuizText}>
            <textarea className={classes.quizTextQns} value={quizTextQuestion} onChange={(e) => { quizTextQns(e) }} placeholder="TYPE QUESTION HERE [MAX. 200 WORDS]">
            </textarea>
            <div className={classes.quizTextUploadWrapper}>
              <input autoComplete="off" type="file" id="test" name="upload" accept="image/png,image/jpeg,image/jpg" onChange={(e)=> OnChangeFileInput(e)}/>
              <img src={quizTextUpload} alt="quizTextUpload" className={classes.textUpload}/>
            </div>
            {file != '' ? <div className={classes.file}><p>File: {file}</p><button onClick={(e)=> RemoveImage()}>Remove</button></div> : null }
            <p>MAX FILE SIZE: 1MB<br />*IMAGE DIMENSION: 800X500 pixels.</p>
            <div className={classes.textArea}>
              <textarea className={classes.textAreaAns1} value={quizTextAnswer1Value} onChange={(e) => {quizTextAnswer1(e) }} placeholder="TYPE ANSWER 1 HERE [MAX. 2 WORDS]">
              </textarea>
              <textarea className={classes.textAreaAns2} value={quizTextAnswer2Value} onChange={(e) => { quizTextAnswer2(e) }} placeholder="[OPTIONAL] TYPE ANSWER 2 HERE [MAX. 2 WORDS]"></textarea>
            </div>
          </div>
        )
      case "QuizChoice":
        return (
          <div className={classes.paramQuizText1}>
            <div className={classes.ExtraBorder}>
              <div className={classes.quizChoiceHeader}>
                <textarea className={classes.quizChoiceQns} value={quizChoiceQuestion} onChange={(e) => { quizChoiceQns(e) }} placeholder="TYPE QUESTION HERE [MAX. 200 WORDS]">
                </textarea>
                <div className={classes.textUploadWrapper}>
                <input autoComplete="off" type="file" id="test" name="upload" accept="image/png,image/jpeg,image/jpg" onChange={(e)=> OnChangeFileInput(e)}/>
                  <img src={quizTextUpload} alt="quizTextUpload" className={classes.textUpload}/>
                </div>
                {file != '' ? <div className={classes.file}><p>File: {file}</p><button onClick={(e)=> RemoveImage()}>Remove</button></div> : null }
                <p>MAX FILE SIZE: 1MB<br />*IMAGE DIMENSION: 800X500 pixels.</p>
              </div>
              <div className={classes.quizChoice}>
                <div className={classes.quizChoiceAnsGroup}>
                  <textarea className={classes.quizChoiceAns} value={quizChoiceAnswerAvalue} onChange={(e) => { quizChoiceAnswerA(e) }} placeholder="TYPE ANSWER A [MAX. 2 WORDS]"></textarea>
                </div>
                <div className={classes.quizChoiceAnsGroup}>
                  <textarea className={classes.quizChoiceAns} value={quizChoiceAnswerBvalue} onChange={(e) => { quizChoiceAnswerB(e) }} placeholder="TYPE ANSWER B [MAX. 2 WORDS]"></textarea>
                </div>
                <div className={classes.quizChoiceAnsGroup}>
                  <textarea className={classes.quizChoiceAns} value={quizChoiceAnswerCvalue} onChange={(e) => { quizChoiceAnswerC(e) }} placeholder="TYPE ANSWER C [MAX. 2 WORDS]" ></textarea>
                </div>
                <div className={classes.quizChoiceAnsGroup}>
                  <textarea className={classes.quizChoiceAns} value={quizChoiceAnswerDvalue} onChange={(e) => { quizChoiceAnswerD(e) }} placeholder="TYPE ANSWER D [MAX. 2 WORDS]"></textarea>
                </div>
              </div>
            </div>
            <div className={classes.quizAnswer}>
              <p>CORRECT ANSWER:</p>
              <ToggleButton4Choice options={options} />
            </div>
          </div>
        );
    }
  }

  const updateProps = (activeParam: string) => {
    let value;
    let propstype;
    let hint: any;
    let assignablevalue: any;
    let propid;
    let numbererror;
    let propsnameerror;

    if(locationType != 'Fixed') {
      propid = propsName;
      propstype = propsType;
      if(propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617'){
        if(propsName == 'APTP03') {
          if(levelSequence1 == '?' || levelSequence2 == '?' || levelSequence3 == '?' || levelSequence4 == '?'){
            dispatch(modal.show.started({type: 'complete4Digit', source: ''}));
            numbererror = true;
          } else {
            assignablevalue = `${levelSequence1}${levelSequence2}${levelSequence3}${levelSequence4}`
            hint = 'Solve 4 Lever Puzzle';
            resetPhoneNumber();
            numbererror = false;
          }
        } else if (propsName == 'APTP06') {
          if(phoneNumber1 == '?' || phoneNumber2 == '?' || phoneNumber3 == '?' || phoneNumber4 == '?'){
            dispatch(modal.show.started({type: 'complete4Digit', source: ''}));
            numbererror = true;
          } else {
            assignablevalue = `${phoneNumber1}${phoneNumber2}${phoneNumber3}${phoneNumber4}`
            hint = 'Telephone Call (4 Digit)';
            resetLevelSequence();
            numbererror = false;
          }
        }
      } else {
        if (propsName == 'APTT06') {
          hint = 'Open Glass Box and press button';
        } else if (propsName == 'APTT08A') {
          hint = 'Open Safe';
        } else if (propsName == 'APTT09A') {
          hint = 'On Radio';
        } else if (propsName == 'APTT05') {
          hint = 'Read Note';
        } else if (propsName == 'APTT11A') {
          hint = 'On Ipad';
        } else if (propsName == 'APTT12A') {
          hint = 'On Laptop';
        } else if (propsName == 'APTT13') {
          hint = 'Read Blackboard';
        } else if (propsName == 'APTT14') {
          hint = 'Read Whiteboard';
        }
        assignablevalue = '';
      }
    } else {
      propid = propsdata?.data[0].propid;
      propstype = '1326a141-112d-41a1-b848-09887894dfc4';
      assignablevalue = '';
      hint = '';
    }

    if(propsType != '1326a141-112d-41a1-b848-09887894dfc4' && propsName == ''){
      propsnameerror = true;
      dispatch(modal.show.started({type: 'propsNameError', source: ''}));
    } else {
      propsnameerror = false;
    }

    PropForm.append('propstype', propstype);
    PropForm.append('hint', hint);
    PropForm.append('assignablevalue', assignablevalue);
    PropForm.append('propid', propid);
    PropForm.append('file', formData);

    if(numbererror || propsnameerror){
      return false;
    }

    switch (activeParam) {
      case 'Weblink':
        value = weblinkValue;
        PropForm.append('popuptype', 'ac77a695-d1b4-4e9e-9e89-d4def334039b');
        PropForm.append('popupvalue', value);
        patchProps(propsdata?.data[0].id);
        dispatch(propsActions.updateMessageTitle(''))
        dispatch(propsActions.updateMessageContent(''))
        dispatch(propsActions.updateQuizQns(''))
        dispatch(propsActions.updateQuizText1(''))
        dispatch(propsActions.updateQuizText2(''))
        dispatch(propsActions.updateQuizChoiceQns(''))
        dispatch(propsActions.updateQuizChoice(''))
        dispatch(propsActions.updateQuizChoiceCorrectAns(''))
        break;
      case 'Message':
        value = `${messageValueTitle}|${messageValueContent}`
        PropForm.append('popuptype', 'a23d4513-428e-4109-97be-bffb9f63ca8c');
        PropForm.append('popupvalue', value);
        patchProps(propsdata?.data[0].id);
        dispatch(propsActions.updateWeblink(''))
        dispatch(propsActions.updateQuizQns(''))
        dispatch(propsActions.updateQuizText1(''))
        dispatch(propsActions.updateQuizText2(''))
        dispatch(propsActions.updateQuizChoiceQns(''))
        dispatch(propsActions.updateQuizChoice(''))
        dispatch(propsActions.updateQuizChoiceCorrectAns(''))
        break;
      case 'QuizText':
        value = `${quizTextQuestion}|${quizTextAnswer1Value}|${quizTextAnswer2Value}`
        PropForm.append('popuptype', '8adf15a0-0be9-4f91-8ab6-8c6d6749a581');
        PropForm.append('popupvalue', value);
        patchProps(propsdata?.data[0].id);
        dispatch(propsActions.updateWeblink(''))
        dispatch(propsActions.updateMessageTitle(''))
        dispatch(propsActions.updateMessageContent(''))
        dispatch(propsActions.updateQuizChoiceQns(''))
        dispatch(propsActions.updateQuizChoice(''))
        dispatch(propsActions.updateQuizChoiceCorrectAns(''))
        break;
      case 'QuizChoice':
        value = `${quizChoiceQuestion}|${quizChoiceAnswerAvalue}|${quizChoiceAnswerBvalue}|${quizChoiceAnswerCvalue}|${quizChoiceAnswerDvalue}|${quizChoiceCorrectAns}`
        PropForm.append('popuptype', '5289249c-4231-419e-92f7-896adb673634');
        PropForm.append('popupvalue', value);
        patchProps(propsdata?.data[0].id);
        dispatch(propsActions.updateWeblink(''))
        dispatch(propsActions.updateMessageTitle(''))
        dispatch(propsActions.updateMessageContent(''))
        dispatch(propsActions.updateQuizQns(''))
        dispatch(propsActions.updateQuizText1(''))
        dispatch(propsActions.updateQuizText2(''))
        break;
      case 'Image':
          PropForm.append('popuptype', '6878208f-9300-4213-8f8d-d6b2699eeb16');
          PropForm.append('popupvalue', '');
          patchProps(propsdata?.data[0].id);
          dispatch(propsActions.updateWeblink(''))
          dispatch(propsActions.updateMessageTitle(''))
          dispatch(propsActions.updateMessageContent(''))
          dispatch(propsActions.updateQuizQns(''))
          dispatch(propsActions.updateQuizText1(''))
          dispatch(propsActions.updateQuizText2(''))
          break;
      default:
        PropForm.append('popuptype', 'f278aed4-ba91-453d-baa3-033a7ae206fa');
        PropForm.append('popupvalue', '');
        patchProps(propsdata?.data[0].id);
        dispatch(propsActions.updateWeblink(''))
        dispatch(propsActions.updateMessageTitle(''))
        dispatch(propsActions.updateMessageContent(''))
        dispatch(propsActions.updateQuizQns(''))
        dispatch(propsActions.updateQuizText1(''))
        dispatch(propsActions.updateQuizText2(''))
        dispatch(propsActions.updateQuizChoiceQns(''))
        dispatch(propsActions.updateQuizChoice(''))
        dispatch(propsActions.updateQuizChoiceCorrectAns(''))
    }
    dispatch(modal.show.started({type: 'saveProps', source: ''}));
  }

  const patchProps = async (id: any) => {
    const DataOfUser = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/props/${id}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: PropForm
    })
    return DataOfUser;
  }

  return (
    <div className={props.propsBoxShown ? classes.propsBoxShown : classes.propsBoxHidden }>
      <Simplebar />
      <div className={classes.propsContainer}>
          <div className={classes.propsBackground}>
            <img src={board} alt="board" />
          </div>
        <div className={classes.scroll}>
          <SimpleBarReact className={classes.simpleBar}>
            <div className={classes.innerContainer}>
              <h5 className={classes.title}>PROPS AUTHORING</h5>
              <div className={classes.hotspotContainer}>
                <h5 className={classes.hotspot}>HOTSPOT:</h5>
                <h5 className={classes.hotspotInfo}>{props.currentPropsIndex}/{props.totalPropCount} {propsdata != null ? propsdata?.data[0].name : '' }</h5>
              </div>
              {locationType != 'Fixed' ? <p className={classes.propsType}>PROPS TYPE:</p> : null}
              {locationType != 'Fixed' ? <div className={classes.propTypeOption}>
                  <div className={classes.propGroup} onClick={() => {setActivePropsTypeFunc(''); setActivePropsFunc('')}}>
                    {(propsType == '1326a141-112d-41a1-b848-09887894dfc4')
                      ?
                    <div className={classes.propTypeCasingActive} >
                      <img src={noneSelected} alt="noneSelected" />
                      <div className={classes.propTypeNameCasingActive}>
                        <p className={classes.propTypeNameActive}>None</p>
                      </div>
                    </div>
                      :
                    <div className={classes.propTypeCasing}>
                      <img src={noneNew} alt="noneNew" />
                      <div className={classes.propTypeNameCasing}>
                        <p className={classes.propTypeName}>None</p>
                      </div>
                    </div>
                    }
                  </div>
                  <div className={classes.propGroup} onClick={() => setActivePropsTypeFunc('f2f31afa-cee7-4895-9f46-448b36c571ad')}>
                    {(propsType == 'f2f31afa-cee7-4895-9f46-448b36c571ad')
                      ?
                      <div className={classes.propTypeCasingActive}>
                      <img src={triggerSelected} alt="triggerSelected" />
                        <div className={classes.propTypeNameCasingActive}>
                        <p className={classes.propTypeNameActive}>Trigger</p>
                        </div>
                      </div>
                      :
                      <div className={classes.propTypeCasing}>
                      <img src={trigger} alt="trigger" />
                        <div className={classes.propTypeNameCasing}>
                        <p className={classes.propTypeName}>Trigger</p>
                        </div>
                      </div>
                    }
                  </div>
                  <div className={classes.propGroup} onClick={() => setActivePropsTypeFunc('0bacf55d-629b-4f09-ad3f-799024fe7617')}>
                    {(propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617') ?
                      <div className={classes.propTypeCasingActive}>
                        <img src={puzzleSelected} alt="puzzleSelected" />
                        <div className={classes.propTypeNameCasingActive}>
                          <p className={classes.propTypeNameActive}>Puzzle</p>
                        </div>
                      </div>
                      :
                      <div className={classes.propTypeCasing}>
                        <img src={puzzle} alt="puzzle" />
                        <div className={classes.propTypeNameCasing}>
                          <p className={classes.propTypeName}>Puzzle</p>
                        </div>
                      </div>
                    }
                  </div>
                  {/* <div className={classes.propGroup} onClick={() => setActivePropsTypeFunc('12423be0-f9d6-4902-9454-55f27a17b1a8')}>
                    {(propsType == '12423be0-f9d6-4902-9454-55f27a17b1a8')
                      ?
                      <div className={classes.propTypeCasingActive}>
                        <img src={displaySelected} alt="displaySelected" />
                        <div className={classes.propTypeNameCasingActive}>
                          <p className={classes.propTypeNameActive}>Display</p>
                        </div>
                      </div>
                      :
                      <div className={classes.propTypeCasing}>
                        <img src={display} alt="display" />
                        <div className={classes.propTypeNameCasing}>
                          <p className={classes.propTypeName}>Display</p>
                        </div>
                      </div>}
                  </div> */}
              </div> : null}
              {(locationType != 'Fixed' && (propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617' || propsType == 'f2f31afa-cee7-4895-9f46-448b36c571ad')) ? <div className={classes.typeChoice}>
                <p className={classes.propsType}>PROPS NAME:</p>
              </div> : null}
              {(locationType != 'Fixed' && propsType != '') ? <div className={classes.propNamePic}>
                {propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617' ?
                <div>
                  <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTP03')}>
                    {propsName == 'APTP03' ? <img src={LeverPuzzleActive} alt="4 Lever Puzzle" /> : <img src={LeverPuzzle} alt="4 Lever Puzzle" /> }
                    <p className={propsName == 'APTP03' ? 'active' : ''}>4 LEVER PUZZLE</p>
                  </div>
                  <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTP06')}>
                    {propsName == 'APTP06' ? <img src={TelephoneActive} alt="Telephone" /> : <img src={Telephone} alt="Telephone" /> }
                    <p className={propsName == 'APTP06' ? 'active' : ''}>TELEPHONE</p>
                  </div>
                  <div className={classes.propNamePicBox}></div>
                </div> : null }
                {propsType == 'f2f31afa-cee7-4895-9f46-448b36c571ad' ?
                  <div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT06')}>
                      {propsName == 'APTT06' ? <img src={GlassCaseActive} alt="Glass Case" /> : <img src={GlassCase} alt="Glass Case" /> }
                      <p className={propsName == 'APTT06' ? 'active' : ''}>GLASS CASE</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT08A')}>
                      {propsName == 'APTT08A' ? <img src={BankSafeActive} alt="Bank Safe" /> : <img src={BankSafe} alt="Bank Safe" /> }
                      <p className={propsName == 'APTT08A' ? 'active' : ''}>BANK SAFE</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT09A')}>
                      {propsName == 'APTT09A' ? <img src={RadioActive} alt="Radio" /> : <img src={Radio} alt="Radio" /> }
                      <p className={propsName == 'APTT09A' ? 'active' : ''}>RADIO</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT05')}>
                      {propsName == 'APTT05' ? <img src={NotepadActive} alt="Notepad" /> : <img src={Notepad} alt="Notepad" /> }
                      <p className={propsName == 'APTT05' ? 'active' : ''}>NOTEPAD</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT11A')}>
                      {propsName == 'APTT11A' ? <img src={iPadActive} alt="iPad" /> : <img src={iPad} alt="iPad" /> }
                      <p className={propsName == 'APTT11A' ? 'active' : ''}>IPAD</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT12A')}>
                      {propsName == 'APTT12A' ? <img src={LaptopActive} alt="Laptop" /> : <img src={Laptop} alt="Laptop" /> }
                      <p className={propsName == 'APTT12A' ? 'active' : ''}>LAPTOP</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT13')}>
                      {propsName == 'APTT13' ? <img src={BlackboardActive} alt="Blackboard" /> : <img src={Blackboard} alt="Blackboard" /> }
                      <p className={propsName == 'APTT13' ? 'active' : ''}>BLACKBOARD</p>
                    </div>
                    <div className={classes.propNamePicBox} onClick={() => setActivePropsFunc('APTT14')}>
                      {propsName == 'APTT14' ? <img src={WhiteboardActive} alt="Whiteboard" /> : <img src={Whiteboard} alt="Whiteboard" /> }
                      <p className={propsName == 'APTT14' ? 'active' : ''}>WHITEBOARD</p>
                    </div>
                    <div className={classes.propNamePicBox}></div>
                  </div> : null }
              </div> : null}
              {(locationType != 'Fixed') && (propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617') && (propsName == 'APTP03') ?
              <div>
                <img src={levelSequenceTop} alt="levelSequenceTop" className={classes.sTop} />
                <div className={classes.level}>
                  <p>SEQUENCE:</p>
                  <div className="buttons">
                    <button className={leverActive.includes(1) ? 'active' : ''} onClick={(e) => {setLevelSequenceFunc(1)}}>1</button>
                    <button className={leverActive.includes(2) ? 'active' : ''} onClick={(e) => {setLevelSequenceFunc(2)}}>2</button>
                    <button className={leverActive.includes(3) ? 'active' : ''} onClick={(e) => {setLevelSequenceFunc(3)}}>3</button>
                    <button className={leverActive.includes(4) ? 'active' : ''} onClick={(e) => {setLevelSequenceFunc(4)}}>4</button>
                  </div>
                  <p>SELECTED NUMBER:</p>
                  <div className={classes.selectednumber}>
                  {`${levelSequence1}${levelSequence2}${levelSequence3}${levelSequence4}`}
                  </div>
                </div>
                <button className={classes.unique} onClick={(e) => {resetLevelSequence()}}>Reset Answer</button>
                <img src={levelSequenceBottom} alt="levelSequenceBottom" className={classes.sBottom}/>
              </div> : null}
              {(locationType != 'Fixed') && (propsType == '0bacf55d-629b-4f09-ad3f-799024fe7617') && (propsName == 'APTP06') ?
              <div>
                <img src={levelSequenceTop} alt="levelSequenceTop" className={classes.sTop} />
                <div className={classes.level}>
                  <p>4 Nos. (0-9):</p>
                  <div className="buttonsphone">
                    <button className={phoneActive.includes(0) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(0)}}>0</button>
                    <button className={phoneActive.includes(1) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(1)}}>1</button>
                    <button className={phoneActive.includes(2) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(2)}}>2</button>
                    <button className={phoneActive.includes(3) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(3)}}>3</button>
                    <button className={phoneActive.includes(4) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(4)}}>4</button>
                    <button className={phoneActive.includes(5) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(5)}}>5</button>
                    <button className={phoneActive.includes(6) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(6)}}>6</button>
                    <button className={phoneActive.includes(7) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(7)}}>7</button>
                    <button className={phoneActive.includes(8) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(8)}}>8</button>
                    <button className={phoneActive.includes(9) ? 'active' : ''} onClick={(e) => {setPhoneNumberFunc(9)}}>9</button>
                  </div>
                  <p>SELECTED NUMBER:</p>
                  <div className={classes.selectednumber}>
                    {`${phoneNumber1}${phoneNumber2}${phoneNumber3}${phoneNumber4}`}
                  </div>
                </div>
                <button className={classes.unique2} onClick={(e) => {resetPhoneNumber()}}>Reset Answer</button>
                <img src={levelSequenceBottom} alt="levelSequenceBottom" className={classes.sBottom}/>
              </div>
               : null}
              <div className={classes.propsParam}>
                <p className={classes.propsParamP}>POP UP UPON TASK COMPLETE</p>
                {props.selectedPropId != 1 ? <IconSwiper /> : null }
                <div className={classes.paramContent}>
                  {paramContent(activeParam)}
                </div>
              </div>
            </div>
          </SimpleBarReact>
        </div>
            <div className={classes.saveExit}>
              <img src={save} alt="save" onClick={() => updateProps(activeParam)} />
              <img src={exit} alt="exit" onClick={() => dispatch (push('/corporate/authoringplatform', props.editData))} />
            </div>
      </div>
    </div>
  );
}

export default PropBox;
