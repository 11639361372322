import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import LoginWrapper from '../components/LoginWrapper'
import logo from '../assets/logo.png'
import TextField1 from 'components/Inputs/TextField1'
import textbg from 'assets/textfield.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import submit from 'assets/submitYellow.png'
import BaseLayoutAdjustable from '../components/form/BaseLayoutAdjustable'
import bg from '../assets/bg.png'
import { push } from 'connected-react-router'
import modal from 'actions/modal'
import auth from 'actions/auth'
import TextFieldPassword from 'components/Inputs/TextFieldPassword'
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormHelperText } from '@material-ui/core';
import { truncateSync } from 'fs';
import emailvalidationActions from 'actions/emailvalidation';
import user from 'actions/user';

const useStyles = makeStyles(() => ({
  checkbox:{
      color:'#fff!important',
      float:'left',
  },
  logo :{
      width:'100%'
  },
  header1 :{
    color:'#ffffff',
    fontSize: '18px',
  },
  fieldbar: {

  },
  forgotPassword :{
      color:'#fff',
      paddingTop:10,
      cursor:'pointer'
  },
  login: {
      width:'80%',
      marginTop:'30px',
      minWidth:'150px',
      maxWidth:'170px',
      margin:'0 auto',
      cursor:'pointer',
      '& img' : {
          width:'100%'
      }
  },
  // username
  input: {
  background: 'transparent',
  position: 'absolute',
  border: 'none',
  textAlign: 'center',
  fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
  color: '#ffd84d',
  lineHeight: '60px',
  padding: '0px 66px',
  width: '100%',
  letterSpacing: '1px',

  '&::-webkit-autofill': {
    border: '1px solid green',
    background: '#000000 !important'
  },

  '&::-webkit-autofill-hover': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::-webkit-autofill-focus': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::-internal-autofill-selected': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::placeholder': {
    textAlign: 'center',
    color: '#ffd84d83',
    textTransform: 'uppercase',
    border: 'none'
  },
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  },
  inputWrapper: {
  position: 'relative',
  display: 'flex',
  },
  // for password
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    lineHeight: '60px',
    padding: '0px 66px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperPass: {
  position: 'relative'
  },
  togglebutton: {
  position: 'absolute',
  right: '30px',
  top: '30px',
  },
  noBorderPass: {
  border: "none",
  },
  InputAPass: {
  color: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    color: '#ffffff',
    fontFamily: 'Lato',
    fontSize: '12px',
    width: '100%',
    textAlign: 'center',
    padding: '0px 20px',

    '& a': {
      color: '#ffffff',
    }
  },
  disclaimer: {
    fontSize: '10px',
    color: '#ffffff',
    wordSpacing: '1px',
  }
}));

const ResetPassword = () => {
  const dispatch = useDispatch();
  const classes  = useStyles();

  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated)
  const emailvalidation = useSelector((state: any) => state.emailvalidation.data)
  const querystring = useSelector((state: any) => state.router.location.query);

  const [password, setPassword] = React.useState('empty')
  const [passwordConfirm, setPasswordConfirm] = React.useState('empty')
  const [isitFirstTime, setisitFirstTime] = useState(true)
  const [allowEnter, setallowEnter] = useState(true)

  const loginValidation = () => {
    if(password == '' || passwordConfirm == '') {
      dispatch(modal.show.started({type: 'emptyField', source: ''}))
    } else {
      if(password != passwordConfirm) {
        dispatch(modal.show.started({type: 'passwordNotMatch', source: ''}))
      } else {
        if(password.length < 8){
          dispatch(modal.show.started({type: 'passwordLength', source: ''}))
        } else {
          dispatch(user.updateuserbyid.started({userid: querystring.token.split('__')[0],
            user: {
              "password": password,
            }
          }))
          dispatch(emailvalidationActions.deleteemailvalidation.started({id: emailvalidation.id}))
          dispatch(modal.show.started({type: 'passwordChanged', source: ''}))
        }
      }
    }
  }

  const [values, setValues] = React.useState({
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showPasswordConfirm: false,
  });

  const handleChange = (event: any, prop: any) => {
    console.log(prop);
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value)
  };

  const handleChangeConfirm = (event: any, prop: any) => {
    console.log(prop);
    setValues({ ...values, [prop]: event.target.value });
    setPasswordConfirm(event.target.value)
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event: any) => {
    event.preventDefault();
  };

  function temp() {
    let x = 1
  }

  const upHandler = (key: any ) => {
    let keyInput = key.key
    let loginbutton = document.getElementById('login')
    if (keyInput === 'Enter' && allowEnter === true) {
      loginbutton !== null ? loginbutton.click() : temp()
      setallowEnter(false)
      setTimeout(() => {
        setallowEnter(true)
      }, 3000);
    }
  };

  function addEventListenerNow() {
    window.addEventListener("keyup", upHandler);
  }

  useEffect(() => {
    dispatch(emailvalidationActions.getemailvalidation.started({id: querystring.token}))
    if (isitFirstTime === true) {
      addEventListenerNow()
      setisitFirstTime(false)
    }
  }, [isAuthenticated])

  return (
    <BaseLayoutAdjustable bg={bg}>
          <LoginWrapper>
            <img className={classes.logo} src={logo} alt="logo"/>
            {emailvalidation == false ? <h1 className={classes.header1}>Link to reset password has expired!</h1> :
              <div>
                <div className={classes.inputWrapperPass}>
                <img src={textbg} alt="bg" />
                <input
                  placeholder='PASSWORD'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  autoComplete="new-password"
                  className={classes.inputPass}
                  onChange={(e) => handleChange(e, 'password')}
                />
                <InputAdornment position="end" className={classes.togglebutton}>
                      <IconButton className={classes.InputAPass}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
              </div>
              <div className={classes.inputWrapperPass}>
                <img src={textbg} alt="bg" />
                <input
                  placeholder='CONFIRM PASSWORD'
                  type={values.showPasswordConfirm ? 'text' : 'password'}
                  value={values.passwordConfirm}
                  autoComplete="new-password-confirm"
                  className={classes.inputPass}
                  onChange={(e) => handleChangeConfirm(e, 'passwordConfirm')}
                />
                <InputAdornment position="end" className={classes.togglebutton}>
                  <IconButton className={classes.InputAPass}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPasswordConfirm}
                    edge="end"
                  >
                    {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              </div>
              <div id="submit" className={classes.login} onClick={() => loginValidation()}>
                  <img src={submit} alt="login" id="loginButton"/>
              </div>
            </div>
            }
          </LoginWrapper>
      </BaseLayoutAdjustable>
  )
}

export default ResetPassword