import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface LoginParam { email: string, password: string, servertime: any }
interface LoginResult { loggedinuser: any }
interface LoginError { error: string }

interface LogoutParam { }
interface LogoutResult { }
interface LogoutError { error: string }

interface RegisterParam { user: any, userinfo: any }
interface RegisterResult { registereduser: any }
interface RegisterError { error: string }

interface UpdateSessionParam { numberofsession: number }
interface UpdateSessionResult { numberofsession: number }
interface UpdateSessionError { error: string }

interface AuthResetParam { }
interface AuthResetResult { }
interface AuthResetError { error: string }

export default {
    login: ac.async<LoginParam, LoginResult, LoginError>(ActionTypes.USER_LOGIN),
    logout: ac.async<LogoutParam, LogoutResult, LogoutError>(ActionTypes.USER_LOGOUT),
    register: ac.async<RegisterParam, RegisterResult, RegisterError>(ActionTypes.USER_REGISTER),
    updatesession: ac.async<UpdateSessionParam, UpdateSessionResult, UpdateSessionError>(ActionTypes.USER_UPDATESESSION),
    authclear: ac.async<AuthResetParam, AuthResetResult, AuthResetError>(ActionTypes.USER_AUTHRESET),
}