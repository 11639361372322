import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateCourseTypeParam { coursetype: any }
interface CreateCourseTypeResult { coursetype: any }
interface CreateCourseTypeError { error: string }

interface GetCourseTypeParam { userid: string }
interface GetCourseTypeResult { coursetype: any }
interface GetCourseTypeError { error: string }

interface UpdateCourseTypeParam { coursetypeid: string, coursetype: any }
interface UpdateCourseTypeResult { coursetype: any }
interface UpdateCourseTypeError { error: string }

interface DeleteCourseTypeParam { coursetypeid: string }
interface DeleteCourseTypeResult { coursetype: any }
interface DeleteCourseTypeError { error: string }

export default {
    createcoursetype: ac.async<CreateCourseTypeParam, CreateCourseTypeResult, CreateCourseTypeError>(ActionTypes.CREATE_COURSETYPE),
    getcoursetypebyuserid: ac.async<GetCourseTypeParam, GetCourseTypeResult, GetCourseTypeError>(ActionTypes.GET_COURSETYPEBYUSERID),
    updatecoursetype: ac.async<UpdateCourseTypeParam, UpdateCourseTypeResult, UpdateCourseTypeError>(ActionTypes.UPDATE_COURSETYPE),
    deletecoursetype: ac.async<DeleteCourseTypeParam, DeleteCourseTypeResult, DeleteCourseTypeError>(ActionTypes.DELETE_COURSETYPE),
}