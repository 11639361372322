import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import LoginWrapper from '../components/LoginWrapper'
import logo from '../assets/logo.png'
import TextField1 from 'components/Inputs/TextField1'
import textbg from 'assets/textfield.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import login from 'assets/LoginYellow.png'
import BaseLayoutAdjustable from '../components/form/BaseLayoutAdjustable'
import bg from '../assets/bg.png'
import { push } from 'connected-react-router'
import modal from 'actions/modal'
import auth from 'actions/auth'
import user from 'actions/user'
import TextFieldPassword from 'components/Inputs/TextFieldPassword'
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormHelperText } from '@material-ui/core';
import { truncateSync } from 'fs';

const useStyles = makeStyles(() => ({
  checkbox:{
      color:'#fff!important',
      float:'left',
  },
  logo :{
      width:'100%'
  },
  fieldbar: {

  },
  forgotPassword :{
      color:'#fff',
      paddingTop:10,
      cursor:'pointer'
  },
  login: {
      width:'80%',
      marginTop:'30px',
      minWidth:'150px',
      maxWidth:'170px',
      margin:'0 auto',
      cursor:'pointer',
      display: 'block',
      '& img' : {
          width:'100%'
      }
  },
  loginHidden: {
      display: 'none',
  },
  // username
  input: {
  background: 'transparent',
  position: 'absolute',
  border: 'none',
  textAlign: 'center',
  fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
  color: '#ffd84d',
  lineHeight: '60px',
  padding: '0px 66px',
  width: '100%',
  letterSpacing: '1px',

  '&::-webkit-autofill': {
    border: '1px solid green',
    background: '#000000 !important'
  },

  '&::-webkit-autofill-hover': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::-webkit-autofill-focus': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::-internal-autofill-selected': {
    border: '1px solid green',
    background: '#000000  !important'
  },

  '&::placeholder': {
    textAlign: 'center',
    color: '#ffd84d83',
    textTransform: 'uppercase',
    border: 'none'
  },
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  },
  inputWrapper: {
  position: 'relative',
  display: 'flex',
  },
  // for password
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    lineHeight: '60px',
    padding: '0px 66px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperPass: {
  position: 'relative'
  },
  togglebutton: {
  position: 'absolute',
  right: '30px',
  top: '30px',
  },
  noBorderPass: {
  border: "none",
  },
  InputAPass: {
  color: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    color: '#ffffff',
    fontFamily: 'Lato',
    fontSize: '12px',
    width: '100%',
    textAlign: 'center',
    padding: '0px 20px',

    '& a': {
      color: '#ffffff',
    }
  },
  disclaimer: {
    fontSize: '10px',
    color: '#ffffff',
    wordSpacing: '1px',
  }
}));

const Login = () => {
  const dispatch = useDispatch();
  const classes  = useStyles();
  const servertime = useSelector((state: any) => state.user.servertime)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const loginValidation = () => {
    if(isEmail(username)) {
      if(password == ''){
        dispatch(modal.show.started({type: 'wrongCredentials', source: ''}))
      } else {
        dispatch(auth.login.started({email: username, password: password, servertime }))
      }
    } else {
      dispatch(modal.show.started({type: 'notAnEmailAddress', source: ''}))
    }
  }

  const OpenForgetPassword = () => {
    dispatch(modal.show.started({type: 'forgetPassword', source: ''}))
  }

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value)
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [isitFirstTime, setisitFirstTime] = useState(true)
  useEffect(() => {
    if (isitFirstTime === true) {
      dispatch(user.getservertime.started({type: 'forgetPassword', source: ''}))
      setisitFirstTime(false)
    }
  }, [servertime])

  const isEmail = ( email: any ) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email) ) { return true }
    else { return false }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
          <LoginWrapper>
          <img className={classes.logo} src={logo} alt="logo"/>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="textbg" />
                <input autoComplete="new-email" required className={classes.input} placeholder='USERNAME' type='email' onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className={classes.inputWrapperPass}>
                <img src={textbg} alt="bg" />
                <input
                  placeholder='PASSWORD'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  autoComplete="new-password"
                  className={classes.inputPass}
                  onChange={handleChange('password')}
                  onKeyPress={(e) => e.key === 'Enter' && loginValidation()}
                />
                <InputAdornment position="end" className={classes.togglebutton}>
                      <IconButton className={classes.InputAPass}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                {/* <input className={classes.input} placeholder={props.placeholder} type={props.type} id="fname" name="fname" /> */}
              </div>
              <div className={classes.fieldbar}>
                  <FormControlLabel
                      className={classes.checkbox}
                      value="end"
                      control={<Checkbox className={classes.checkbox} />}
                      label="REMEMBER ME"
                      labelPlacement="end"
                  />
                  <p className={classes.forgotPassword} onClick={() => OpenForgetPassword()} >FORGOT PASSWORD</p>
              </div>
              <div id="login" className={classes.login} onClick={() => loginValidation()}>
                  <img src={login} alt="login" id="loginButton"/>
              </div>
              <p className={classes.disclaimer}>Please read the Terms of Use before you access the Website. By accessing the Website, you hereby accept and agree to be bound and abide by our Terms of Use, Privacy Policy and Disclaimers.</p>
          </LoginWrapper>
      </BaseLayoutAdjustable>
  )
}

export default Login