import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBg from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgshort.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import modal from 'actions/modal'
import users from 'actions/user'
import auth from 'actions/auth'
import TableBG from 'components/Tables/TableBG'
import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbar } from 'react-scrollbars-custom';
import SimpleBarReact from "simplebar-react";
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

// for editing page
import { corporatePageActions } from 'actions/coporatePageActions';


const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  hidden: {
    visibility: "hidden",
    pointerEvents: "none",
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: '100%',
    }
  },
  // back button and save button style
  backBtn1: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperDark: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperShort: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperShortDark: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '3rem',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  row2: {
    display: 'flex',
    marginTop: '1rem'
  },
  table: {
    minWidth: 650,
  },
  cell: {
    borderBottom: "none",
    color: "#FFD84C",
    alignItems: "center",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  cellHead: {
    borderBottom: "none",
    color: "#FFFFFE",
    alignItems: "center",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  // for scroll bar
  scroll: {
    width: '100%',
    height: '54vh',
    borderRadius: '4px',
    position: "relative",
  },
  // for new scroll bar
  simpleBar: {
    maxHeight: '51vh'
  },
  // highlight row
  highlightRow: {
    border: 'solid',
    borderWidth: '2px',
    color: 'white'
  },
  NohighlightRow: {
    border: 'none',
  },
  checkbox: {
    color: '#ffffff',
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const AgentListing = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  let arraytoStorekeyToHighLight: string[] = []

  const [selectedRow, setSelectedRow] = useState(arraytoStorekeyToHighLight)
  const [selectedRowData, setSelectedRowData] = useState()

  const [showEditPage, setshowEditPage] = useState(false)
  const [buttonShown, setButtonsShown] = useState(false)

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const name = useSelector((state: any) => state.auth.loggedinuser.user.name)
  const userdata = useSelector((state: any) => state.user.data)
  const deleted = useSelector((state: any) => state.user.deleted)

  const [showEditButton, setshowEditButton] = useState(false)

  useEffect(() => {
    if(deleted) {
      setSelectedRow(arraytoStorekeyToHighLight);
      setButtonsShown(false)
      setshowEditButton(false)
    }
    dispatch(users.getuserbyaccounttype.started({userid: id, accounttype: 'AGENT'}))
  }, [id, deleted])

  const rowInfo = (f: any, checked: any, key: any) => {
    dispatch(corporatePageActions.performanceRecordsTableRowIndex(userdata.data.indexOf(f), checked))
  }

  const checkBoxInfo = (event: ChangeEvent<HTMLInputElement>, userdata: any) => {
    let checked = event.target.checked

    if (checked === true) {
      selectedRow.push(userdata.id)
    } else {
      selectedRow.splice(selectedRow.indexOf(userdata.id), 1)
    }

    // 0
    if (selectedRow.length < 1) {
      setButtonsShown(false)
    } else {
      // 1
      if (selectedRow.length < 2) {
        setshowEditButton(true)
        setButtonsShown(true)
        setSelectedRowData(userdata);
      } else {
        setButtonsShown(true)
        setshowEditButton(false)
        rowInfo(userdata, true, selectedRow)
      }
    }
  };

  const [headers] = React.useState([{
    col1: 'AGENT NAME',
    col2: 'AGENT COUNTRY',
    col3: 'AGENT EMAIL',
    col4: 'AGENT CONTACTS',
    col5: 'AGENT STATUS',
    col6: 'SELECT',
    key: "0",
  }]);

  function deleteButton() {
    let ids = selectedRow.join(",")
    dispatch(modal.show.started({type: 'DeleteUserAccount', source: ids}))
  }

  const EditButtons = (shown: any) => {
    if (shown === true) {
      if (showEditButton === true) {
        return (
          <div className={classes.row2}>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>CREATE AGENT /<br />RESELLER</p>
            </div>
            <div className={classes.inputWrapperShort} onClick={() => dispatch (push('/admiral/editagentaccount', selectedRowData))}>
              <img src={yellowBg} alt="bg" />
              <p>EDIT AGENT /<br />RESELLER DETAILS</p>
            </div>
            <div className={classes.inputWrapperShort} onClick={() => deleteButton()} >
              <img src={yellowBg} alt="bg" />
              <p>DELETE AGENT /<br />RESELLER</p>
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes.row2}>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>CREATE AGENT /<br />RESELLER</p>
            </div>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>EDIT AGENT /<br />RESELLER DETAILS</p>
            </div>
            <div className={classes.inputWrapperShort} onClick={() => deleteButton()} >
              <img src={yellowBg} alt="bg" />
              <p>DELETE AGENT /<br />RESELLER</p>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={classes.row2}>
          <div className={classes.inputWrapper} onClick={() => dispatch(push('/admiral/createagent'))}>
            <img src={yellowBg} alt="bg" />
            <p>CREATE AGENT /<br />RESELLER</p>
          </div>
          <div className={classes.inputWrapperShortDark}>
            <img src={darkBG} alt="bg" />
            <p>EDIT AGENT /<br />RESELLER DETAILS</p>
          </div>
          <div className={classes.inputWrapperShortDark}>
            <img src={darkBG} alt="bg" />
            <p>DELETE AGENT /<br />RESELLER</p>
          </div>
        </div>
      )
    }
  }

  function tableRow(tabeInfo: any, arrayToCompare: any) {
    if (arrayToCompare.includes(tabeInfo.id) === true) {
      return (
        <TableRow key={tabeInfo.id} className={classes.highlightRow} >
          <TableCell className={classes.cell} align="center" >{tabeInfo.name}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.agentinfo != null ? tabeInfo.agentinfo.country : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.email}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.agentinfo != null ? tabeInfo.agentinfo.contacts : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.status ? 'Active': 'Inactive'}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    }
    else {
      return (
        <TableRow key={tabeInfo.id} className={classes.NohighlightRow} >
          <TableCell className={classes.cell} align="center" >{tabeInfo.name}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.agentinfo != null ? tabeInfo.agentinfo.country : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.email}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.agentinfo != null ? tabeInfo.agentinfo.contacts : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.status ? 'Active': 'Inactive'}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
          <div className={classes.topInfo}>
            <img src={logo} alt="logo" className={classes.logo} />
            <AccountInfoNew />
          </div>
          <div className={showEditPage === true ? classes.hidden : classes.container2}>
            <h3 className={classes.header}>AGENTS / RESELLERS MANAGEMENT</h3>
            <TableBG bg={tablebg}>
              <div className={classes.scroll}>
                <SimpleBarReact className={classes.simpleBar}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      {headers.map((col) =>
                        <TableRow key={col.key}>
                          <TableCell className={classes.cellHead} align="center">{col.col1}</TableCell>
                          <TableCell className={classes.cellHead} align="center">{col.col2}</TableCell>
                          <TableCell className={classes.cellHead} align="center">{col.col3}</TableCell>
                          <TableCell className={classes.cellHead} align="center">{col.col4}</TableCell>
                          <TableCell className={classes.cellHead} align="center"> {col.col5}</TableCell>
                          <TableCell className={classes.cellHead} align="center"> {col.col6}</TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody>
                      {userdata != null ?
                          userdata.data.map((row: any) =>
                            tableRow(row, selectedRow)
                          )
                        : null}
                    </TableBody>
                  </Table>
                </SimpleBarReact>
                {/* </CustomScrollbars> */}
              </div>
            </TableBG>
            {EditButtons(buttonShown)}
          </div>
          <div className={/* buttonShown === true || showEditPage === true ? classes.hidden :  */classes.backBtn} onClick={() => dispatch (push('/admiral/selection'))}>
            <img src={BackSymbol} alt="BackSymbol" />
            <p>BACK</p>
          </div>
          {/* <div className={classes.tips} onClick={() => dispatch(modal.show.started({type: 'showTips', source: ''}))} >
            <img src={tips}/>
            <p>TIPS</p>
          </div>
          <div className={classes.tutorials} onClick={() => dispatch(push('/corporate/videotutorials'))} >
            <img src={tutorials}/>
            <p>TUTORIALS</p>
          </div> */}
          <a className={classes.manual} href="https://www.createscape.com.sg/documents/admiral-account-user-manual.pdf" target="_blank" >
            <img src={manual}/>
            <p>MANUAL</p>
          </a>
      </BaseLayoutAdjustable>
  )
}

export default AgentListing;
