import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/agentinfo'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const createAgentInfo: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createagentinfo.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/agentinfo`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.agentinfo
        }).pipe(
            map(data => {
                return actions.createagentinfo.done({
                    params: param.payload,
                    result: { agentinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createagentinfo.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getAgentInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getagentinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/agentinfo/${param.payload.userid}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getagentinfobyuserid.done({
                    params: param.payload,
                    result: { agentinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getagentinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateAgentInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateagentinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/agentinfo/${param.payload.userid}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.agentinfo
        }).pipe(
            map(data => {
                return actions.updateagentinfobyuserid.done({
                    params: param.payload,
                    result: { agentinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateagentinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)