import styled from 'styled-components'

interface Obj {
  readonly bg: string;
}

const TableBG = styled.div`
    background: url(${(props: Obj) => props.bg});
    margin-left: auto;
    margin-right: auto;
    width: 80vw;
    height: 56vh;
    padding: 1rem;
`

export default TableBG;
