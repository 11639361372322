import styled from 'styled-components'

interface Obj {
  readonly bg: string;
}

const BaseLayoutGallery = styled.div`
    background: url(${(props: Obj) => props.bg})center center / cover;
    width:100vw;
    height:100vh;
    position:fixed;
    left:0;
`

export default BaseLayoutGallery;
