import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';
import AccountInfoNew from 'components/AccountInfoNew'
import modal from 'actions/modal'
import BaseLayoutAdjustable from '../../../components/form/BaseLayoutAdjustable'

import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'

import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import textbg from 'assets/textfield.svg'

import axios from 'axios';
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

const useStyles = makeStyles(() => ({
    TypeSetuprequiredfield: {
        color: "#FFD84C",
        fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
        letterSpacing: '1px',
        paddingBottom: '1rem',
    },
    trainingcourse: {
        cursor: 'pointer',
        width: '32rem'
    },
  headerTypeSetup: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  inputTypeSetup: {
    background: 'transparent',
    position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,0%)',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    fontSize: "1.2rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperTypeSetup: {
    position: 'relative'
  },
  // save and back buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },

  externalRowTypeSetup: {
    display: 'flex',
    position: "relative",
    height: "45vh",
    bottom: "0rem"
  },
  row1TypeSetup: {
    display: 'flex',
    flexDirection: 'column',
    width: "43rem",
    height: "17rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '39rem'
    }
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const CourseTypeSetup = () => {
  const dispatch = useDispatch()
  const classes  = useStyles();

  const [CourseTypeName, setCourseTypeName] = React.useState('empty')
  const [CourseTypeDescription, setCourseTypeDescription] = React.useState('empty')
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const [Placeholder, setPlaceHolder] = React.useState(false)

  function retrieveInfoFromInput(inputType: any, input: any) {
    switch (inputType) {
      case 'coursetype':
        setCourseTypeName(input)
      case 'description':
        setCourseTypeDescription(input)
      default:
        setPlaceHolder(true)
    }
  }

  // save button activation
  const saveButtonClicked = () => {
    if (CourseTypeName === "empty" || CourseTypeDescription === "empty") {
      alert('the name input or the description input is empty')
    } else {
      UpdateProps(CourseTypeName, CourseTypeDescription)
      dispatch(modal.show.started({type: 'saveCourseType', source: ''}))
    }
  }

  const UpdateProps = async (name: string, description: string) => {
    const req = {
      "name": name,
      "description": description,
      "user": id
    }
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/coursetype`,
      headers: {
        "content-type": "application/json",
      },
      data: req
    })
    return response;
  }

    return (
        <BaseLayoutAdjustable bg={bg}>
            <div className={classes.topInfo}>
              <img src={logo} alt="logo" className={classes.logo} />
              <AccountInfoNew />
            </div>
          <div className="containerCoporateProfileSetup">
            <div className="row justify-content-center">
              <h3 className={classes.headerTypeSetup}>COURSE TYPE SETUP</h3>
            </div>
            <div className="row justify-content-center pb-3">
              <h4 className={classes.TypeSetuprequiredfield}>* INDICATES REQUIRED FIELD</h4>
            </div>
            <div className={classes.externalRowTypeSetup}>
              <div className={classes.row1TypeSetup}>
                <div className={classes.inputWrapperTypeSetup}>
                  <img src={textbg} alt="bg" />
                <input autoComplete="off" className={classes.inputTypeSetup} placeholder="COURSE TYPE NAME*" type="text"  onChange={(e) => retrieveInfoFromInput('coursetype', e.target.value)} />
                </div>
                <div className={classes.inputWrapperTypeSetup}>
                  <img src={textbg} alt="bg" />
                  <input autoComplete="off" className={classes.inputTypeSetup} placeholder="COURSE TYPE DESCRIPTION*" type="text" onChange={(e) => retrieveInfoFromInput('description', e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
            <div className={classes.pageBottom}>
          <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/coursetypelisting'))}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
              <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
                <img src={SaveSymbol} alt="BackSymbol" />
                <p>SAVE</p>
              </div>
            </div>
            <div className={classes.tips} onClick={() => dispatch(modal.show.started({type: 'showTips', source: ''}))} >
              <img src={tips}/>
              <p>TIPS</p>
            </div>
            <div className={classes.tutorials} onClick={() => dispatch(push('/corporate/videotutorials'))} >
              <img src={tutorials}/>
              <p>TUTORIALS</p>
            </div>
            <div className={classes.manual} onClick={() => dispatch(push('/corporate/manual'))} >
              <img src={manual}/>
              <p>MANUAL</p>
            </div>
        </BaseLayoutAdjustable>
    )
}

export default CourseTypeSetup;
