import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';

import yes from 'assets/CourseManagerManagement/YesYellow.png'
import no from 'assets/CourseManagerManagement/No.png'
import OkayBtn from 'assets/OkayBtn.png'
import Background from 'assets/Background.jpg'
import yellowBg from 'assets/BaseImg/yellowBgwithDesign.png'
import darkBg from 'assets/BaseImg/darkBgwithDesign.png'
import textfieldbg from 'assets/textfield.svg'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import next from 'assets/tipsandtuts/next.svg'
import back from 'assets/tipsandtuts/back.svg'
import finish from 'assets/tipsandtuts/finish.svg'
import tips1 from 'assets/tipsandtuts/tip1.jpg'
import tips2 from 'assets/tipsandtuts/tip2.jpg'

import profilesetup1 from 'assets/manual/Profile Setup - 1.jpg'
import profilesetup2 from 'assets/manual/Profile Setup - 2.jpg'
import profilesetup3 from 'assets/manual/Profile Setup - 3.jpg'
import profilesetup4 from 'assets/manual/Profile Setup - 4.jpg'
import profilesetup5 from 'assets/manual/Profile Setup - 5.jpg'

import coursetypecreate1 from 'assets/manual/COURSE TYPE MANAGEMENT - 1.jpg'
import coursetypecreate2 from 'assets/manual/COURSE TYPE MANAGEMENT - 2.jpg'
import coursetypecreate3 from 'assets/manual/COURSE TYPE MANAGEMENT - 3.jpg'
import coursetypecreate4 from 'assets/manual/COURSE TYPE MANAGEMENT - 4.jpg'
import coursetypecreate5 from 'assets/manual/COURSE TYPE MANAGEMENT - 5.jpg'

import coursetypeedit1 from 'assets/manual/EDIT COURSE TYPE - 1.jpg'
import coursetypeedit2 from 'assets/manual/EDIT COURSE TYPE - 2.jpg'
import coursetypeedit3 from 'assets/manual/EDIT COURSE TYPE - 3.jpg'
import coursetypeedit4 from 'assets/manual/EDIT COURSE TYPE - 4.jpg'
import coursetypeedit5 from 'assets/manual/EDIT COURSE TYPE - 5.jpg'
import coursetypeedit6 from 'assets/manual/EDIT COURSE TYPE - 6.jpg'

import coursetypeedelete1 from 'assets/manual/DELETE COURSE TYPE - 1.jpg'
import coursetypeedelete2 from 'assets/manual/DELETE COURSE TYPE - 2.jpg'
import coursetypeedelete3 from 'assets/manual/DELETE COURSE TYPE - 3.jpg'

import coursemanagement1 from 'assets/manual/COURSE MANAGEMENT - 1.jpg'
import coursemanagement2 from 'assets/manual/COURSE MANAGEMENT - 2.jpg'
import coursemanagement3 from 'assets/manual/COURSE MANAGEMENT - 3.jpg'
import coursemanagement4 from 'assets/manual/COURSE MANAGEMENT - 4.jpg'
import coursemanagement5 from 'assets/manual/COURSE MANAGEMENT - 5.jpg'
import coursemanagement6 from 'assets/manual/COURSE MANAGEMENT - 6.jpg'

import coursemanagementedit1 from 'assets/manual/EDIT COURSE – 1.jpg'
import coursemanagementedit2 from 'assets/manual/EDIT COURSE – 2.jpg'
import coursemanagementedit3 from 'assets/manual/EDIT COURSE – 3.jpg'
import coursemanagementedit4 from 'assets/manual/EDIT COURSE – 4.jpg'
import coursemanagementedit5 from 'assets/manual/EDIT COURSE – 5.jpg'
import coursemanagementedit6 from 'assets/manual/EDIT COURSE – 6.jpg'

import coursemanagementdelete1 from 'assets/manual/DELETE COURSE - 1.jpg'
import coursemanagementdelete2 from 'assets/manual/DELETE COURSE - 2.jpg'
import coursemanagementdelete3 from 'assets/manual/DELETE COURSE - 3.jpg'

import traineecreate1 from 'assets/manual/CREATE TRAINEE - 1.jpg'
import traineecreate2 from 'assets/manual/CREATE TRAINEE - 2.jpg'
import traineecreate3 from 'assets/manual/CREATE TRAINEE - 3.jpg'
import traineecreate4 from 'assets/manual/CREATE TRAINEE - 4.jpg'
import traineecreate5 from 'assets/manual/CREATE TRAINEE - 5.jpg'
import traineecreate6 from 'assets/manual/CREATE TRAINEE - 6.jpg'
import traineecreate7 from 'assets/manual/CREATE TRAINEE - 7.jpg'

import traineeedit1 from 'assets/manual/EDIT TRAINEE – 1.jpg'
import traineeedit2 from 'assets/manual/EDIT TRAINEE – 2.jpg'
import traineeedit3 from 'assets/manual/EDIT TRAINEE – 3.jpg'
import traineeedit4 from 'assets/manual/EDIT TRAINEE – 4.jpg'
import traineeedit5 from 'assets/manual/EDIT TRAINEE – 5.jpg'
import traineeedit6 from 'assets/manual/EDIT TRAINEE – 6.jpg'
import traineeedit7 from 'assets/manual/EDIT TRAINEE – 7.jpg'

import traineedelete1 from 'assets/manual/DELETE TRAINEE – 1.jpg'
import traineedelete2 from 'assets/manual/DELETE TRAINEE – 2.jpg'
import traineedelete3 from 'assets/manual/DELETE TRAINEE – 3.jpg'

import trainingsessions1 from 'assets/manual/TRAINING SESSIONS – 1.jpg'
import trainingsessions2 from 'assets/manual/TRAINING SESSIONS – 2.jpg'
import trainingsessions3 from 'assets/manual/TRAINING SESSIONS – 3.jpg'
import trainingsessions4 from 'assets/manual/TRAINING SESSIONS – 4.jpg'
import trainingsessions5 from 'assets/manual/TRAINING SESSIONS – 5.jpg'

import videoborder from 'assets/tipsandtuts/videoborder.png'
import corporate from 'assets/tipsandtuts/corporate.mp4'
import coursetypevideo from 'assets/tipsandtuts/coursetype.mp4'
import trainee from 'assets/tipsandtuts/trainee.mp4'
import authoring from 'assets/tipsandtuts/authoring.mp4'

import performancerecordsBG from 'assets/PerformanceRecords/performanceRecordsBG.png'
import hourGlass from 'assets/PerformanceRecords/hourGlass.png'

import modal from 'actions/modal'
import user from 'actions/user'
import auth from 'actions/auth'
import course from 'actions/course'
import coursetype from 'actions/coursetype'
import gameresult from 'actions/gameresult';
import SwiperCore, { EffectFade, Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'assets/stylesheets/application.css'

SwiperCore.use([EffectFade, Pagination, Navigation]);

const useStyles = makeStyles((theme: Theme) => createStyles({
  modal: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  paper: {
    width: '100%',
    outline: 'none',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%,-50%)',
    '& h1': {
      color: '#fff',
      marginBottom: '5%',
      fontSize: '6vh'
    },
  },
  papervideotuts: {
    background: `url(${Background}) center center / cover`,
    width: '100%',
    outline: 'none',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    height: '100%',
    transform: 'translate(0%,-50%)',
    '& .videowrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',

      '& .topborder': {
        width: '100%',
        maxWidth: '980px',
        display: 'flex',
      },

      '& .bottomborder': {
        width: '100%',
        maxWidth: '980px',
        display: 'flex',
        justifyContent: 'flex-end',
      }
    },
    '& h3': {
      color: '#FFD140',
      fontSize: '2rem',
      fontFamily: 'impact',
      textTransform: 'uppercase',
    },
    '& video': {
      width: '100%',
      maxWidth: '980px',
    },
  },
  backCancel: {
    width: '100%',
    outline: 'none',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%,-50%)',
    '& img': {
      width: '16vw%',
      cursor: 'pointer'
    }
  },
  warn: {
    color: '#FCDA55',
    fontFamily: 'impact',
    letterSpacing: '1px',
    paddingBottom: '1rem',
    fontSize: '8vh'
  },
  warningWords: {
    color: '#FFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '6vh'
  },
  warningWords1: {
    color: '#FFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    paddingBottom: '4rem',
    fontSize: '6vh'
  },
  forget: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    '& h3': {
      color: '#FFD60F',
      marginBottom: '5%'
    },
    '& h4': {
      color: '#FFFFFE',
      marginBottom: '5%'
    },
    '& img': {
      width: '56vw',
    },
    alignItems: 'center'
  },
  cancelBackBtn: {
    display: 'flex',
    justifyContent: 'center',
    height: '10vh'
  },
  Button: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: '4rem',
    marginTop: "4rem",
    marginBottom: "4rem",
    '& img': {
      width: '100%',
    },
    '& p': {
      color: '#FFFFFF',
      position: 'absolute',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '3rem',
      marginBottom: '0rem'
    }
  },
  BackButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: '0rem',
    bottom: '0rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    marginBottom: '2rem',
    '& img': {
      width: '100%',
    },
    '& p': {
      color: '#FFD84C',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '1.2rem',
      paddingLeft: '0.5rem'
    }
  },
  forgetPass: {
    color: '#FFD84E',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '3rem',
  },
  enterEmailAddress: {
    color: '#FFFFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '3rem',
    width: '80rem'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // for input box
  input: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '45px',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    color: '#ffd84d',
    fontSize: '3rem',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapper: {
    marginTop: '3rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  GameResults: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    '& h1': {
      color: '#fff',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginTop: '7rem',
      marginBottom: '3rem',
      fontSize: '3rem'
    }
  },
  gameResultBG: {
    width: '68rem',
    zIndex: -1
  },
  forAlignment: {
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
  },
  recordsMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: "56rem",
    height: "26rem",
    justifyContent: "space-between",
  },
  hintsUsed: {
    display: "flex",
    alignItems: 'center',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginTop: '2rem',
      marginRight: '1rem'
    },
    '& img': {
      width: '3rem',
    }
  },
  quizScoreTimeTakenBox: {
    display: 'flex',
    alignItems: "flex-end",
    paddingBottom: '11rem',
    justifyContent: 'center',
  },
  takenTime: {
    display: 'flex',
    alignItems: 'center',
    height: '1.6rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '1.2rem'
    }
  },
  portion1Box: {
    display: 'flex',
    width: '28rem',
    justifyContent: 'center'
  },
  portion1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "16rem",
    marginTop: '7rem'
  },
  scoreQuiz: {
    color: '#FFFFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '1.2rem',
    margin: '0rem'
  },
  questionsAnswered: {
    display: 'flex',
    width: '23rem',
    alignItems: 'center',
    justifyContent: 'center'
  },
  outOf10: {
    color: "#FFFFFF",
    // width: "10rem",
    fontFamily: "ds-digitalbold",
    margin: "0rem",
    letterSpacing: "1px",
    fontSize: "50px",
    paddingRight: "1rem",
  },
  QAC: {
    color: '#FFFFFF',
    fontFamily: 'ds-digitalbold',
    letterSpacing: '1px',
    marginRight: '1rem',
    width: '1rem',
    fontSize: '11px'
  },
  portion2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
  timeValue: {
    color: '#FFFFFF',
    fontFamily: 'ds-digitalbold',
    letterSpacing: '1px',
    fontSize: '50px'
  },

  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '40px',
    right: '80px',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  emailnotfound: {
    position: 'absolute',
    bottom: '-20px',
    color: '#ffffff',
    fontSize: '20px',
    fontFamily: 'Impact',
  },
  content: {

  },

  navigation: {
    bottom: '40px',
    position: 'absolute',
    zIndex: 1,
    color: '#f7d460',
    fontFamily: 'Impact',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    maxWidth: '1348px',
    margin: '0 auto',
    left: '80px',
    display: 'flex',

    '& .exit': {
      left: '10px',
      fontSize: '18px',
      cursor: 'pointer',

      '& img': {
        marginRight: '6px',
      },
    },
  },
  mySwiper: {
    width: '100vw',
    margin: '0rem',
    height: '100vh',

    '& .swiper-button-next': {
      color: 'yellow'
    },

    '& element': {
      marginRight: '0'
    },

    '& .swiper-slide': {

      '& > div': {
        height: '100vh',

        '& > img': {
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        },
        '& .rightnav': {
          position: 'absolute',
          right: '20px',
          bottom: '46px',
          width: '18vw',
          minWidth: '130px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',

          '& > img': {
            width: '50%',
          },
        },
      },
    }
  }
}));

export default function TransitionsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState<any>({});
  let arraytoStorekeyToHighLight: string[] = []
  let aggregatedid = ''
  const modalShown = useSelector((state: any) => state.modal.modalShown)
  const type = useSelector((state: any) => state.modal.data)
  const activeParam = useSelector((state: any) => state.props.activeParam)
  const weblink = useSelector((state: any) => state.props.weblinkValue)
  const messageTitle = useSelector((state: any) => state.props.messageValueTitle) // need to follow the console log next state props: {activeParam: "message"}
  const [traineeIds, setTraineeIds] = useState(arraytoStorekeyToHighLight)
  const [corporateIds, setCorporateIds] = useState(arraytoStorekeyToHighLight)
  const [emailNotFound, setEmailNotFound] = useState(false)

  const [formData, setData] = useState({
    email: '',
  });

  const [formError, setFormError] = useState({
      email: false,
  });

  const closeModal = (actions: any) => {
    if( actions != null ){
      if(actions.cleardata != null){
        switch(actions.cleardata) {
          case 'auth':
              dispatch(auth.authclear.started({}))
            break;
        }
      }

      if(actions.redirectpage != ''){
        dispatch(push(actions.redirectpage))
      }
    }

    setTimeout(() => {
      dispatch(modal.hide.started({}))
    }, 500)
  }

  const deleteRow = () => {
    handleRemoveRow()
    dispatch(modal.hide.started({}))
  }

  const afterGameResult = () => {
    dispatch(modal.hide.started({}));
  }

  const rows = useSelector((state: any) => state.modal.tableRows)
  const index = useSelector((state: any) => state.props.TableRowIndex)

  function handleRemoveRow() {
    let x = rows.splice(index, 1)
  };

  const returnBack = () => {
    dispatch(push('/corporate/overview'))
    dispatch(modal.hide.started({}))
  }

  const deleteRecordsForPerformance = (data: any) => {
    dispatch(gameresult.deletegameresult.started({gameresultids: data.source}))
    dispatch(modal.hide.started({}))
  }

  const deleteRecordsForPerformanceForTrainee = () => {
    dispatch(modal.hide.started({}))
    dispatch(push('/corporate/overview'))
  }

  const deleteRecords = async (id: any) => {
    const DataOfUser = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/gameresult/${id}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      }
    })
    return DataOfUser;
  }

  const includeTraineeIds = async (ids: any) => {
    ids.map(async (item: any, i: number, ids: any) => {
        const DataOfUser = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BACKEND_URL}/users/${item}/TRAINEE`,
          headers: {
            "content-type": "application/json",
          }
        })

        corporateIds.push(item);
        if (i + 1 === ids.length) {
          let corpids = corporateIds.join(',')
          dispatch(user.delete.started({userids: corpids}))
        }

        let datacollected = DataOfUser.data.data;

        datacollected.map((data: any, i: number, ids: any)=> {
          traineeIds.push(data.id);
          if (i + 1 === ids.length) {
            let traineeids = traineeIds.join(',')
            dispatch(user.delete.started({userids: traineeids}))
          }
        })
    })
  }
  const accessToken = useSelector((state: any) => state.user.accessToken)
  const getRandomString = (length: any) => {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  const submitButton = (formData: any) => {
    let err = [false];
    let email = formData.email;
    let token = getRandomString(40);
    let checker = (arr: any[]) => arr.every(v => v === false);

    if(isEmail(email)){
      axios.get('https://escaperoom.vmwgroup.com.sg/users/findbyemail/' + email).then(data => {

            setEmailNotFound(false);

            Object.entries(formData).map(([key, value]) => {
              if((key == 'email') && (value == '')) {
                  err[0] = true;
              } else if ((key == 'email') && (value != '')) {
                  err[0] = false;
              }
            });

            setFormError({...formError, email: err[0]});

            if(checker(err)) {
              axios.post('https://www.createscape.com.sg/mailer.php', {email, form: 'reset', token: data.data.id + '__' + token}, {
                  headers: { 'Content-Type': 'multipart/form-data' },
              }).then(data => {
                }
              ).catch(err => {
                  console.log(err)
                  return null
              });

              axios.post('https://escaperoom.vmwgroup.com.sg/emailvalidate', {token: data.data.id + '__' + token}, {
                  headers: { 'Content-Type': 'application/json' },
              }).then(data => {
                  dispatch(modal.hide.started({}))
                  }
              ).catch(err => {
                  console.log(err)
                  return null
              })
            } else {
                return false;
            }
        }
      ).catch(err => {
        setEmailNotFound(true);
        console.log(err)
        return null
      });
    }
  }

  const isEmail = ( email: any ) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email) ) { return true }
    else { return false }
  }

  const changeHandler = (e: { target: { name: any; value: any } }) => {
      setFormError({...formError, [e.target.name]: false});
      setData({...formData, [e.target.name]: e.target.value})
  }

  const deleteUserAccount = (data: any) => {
    includeTraineeIds(data.source)
    closeModal(null);
  }

  const deleteCourses = (data: any) => {
    dispatch(course.deletecourse.started({courseids: data.source}))
    closeModal(null);
  }

  const deleteCourseTypes = (data: any) => {
    dispatch(coursetype.deletecoursetype.started({coursetypeid: data.source}))
    closeModal(null);
  }

  const ModalType = (data: any) => {
    if (data == null) {
      //
    } else {
      switch (data.type) {
        case 'viewGameResult':
          return (
            <div>
              <div className={classes.GameResults}>
                <h1>TRAINING SESSIONS PERFORMANCE RECORDS</h1>
                <div className={classes.forAlignment}>
                  <img src={performancerecordsBG} alt="performancerecordsBG" className={classes.gameResultBG} />
                  <div className={classes.recordsMainContainer}>
                    <div className={classes.quizScoreTimeTakenBox}>
                      <div className={classes.portion1Box}>
                        <div className={classes.portion1}>
                          <p className={classes.scoreQuiz}>QUIZ SCORE:</p>
                          <div className={classes.questionsAnswered}>
                            <p className={classes.outOf10}>Quiz Score / 10</p>
                            <p className={classes.QAC}>QUESTIONS ANSWERED CORRECTLY</p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.portion1Box}>
                        <div className={classes.portion2}>
                          <div className={classes.takenTime}>
                            <img src={hourGlass} alt="hourGlass" />
                            <p>TIME TAKEN:</p>
                          </div>
                          <p className={classes.timeValue}>Time Taken</p>
                          {/* <p className={classes.timeValue}>01 : 30 : 00</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.backBtn} onClick={() => afterGameResult()}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
            </div>
          )
        case 'saveTraineeSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/traineelisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'saveCorporateSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/admiral/corporatelisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'saveAgentSetup':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
                <div>
                  <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/admiral/agentlisting', cleardata: 'auth'})} />
                </div>
              </div>
            )
        case 'saveCourseType':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
                <div>
                  <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/coursetypelisting', cleardata: 'auth'})} />
                </div>
              </div>
            )
        case 'imageDimensionError':
          return (
            <div className={classes.paper}>
              <h1>Image dimension is wrong.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'fileSizeError':
          return (
            <div className={classes.paper}>
              <h1>File size must be lower than 1MB.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'complete4Digit':
          return (
            <div className={classes.paper}>
              <h1>Complete the four digit code.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'propsNameError':
          return (
            <div className={classes.paper}>
              <h1>Please select a prop.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'cancelBack':
          return (
            <div className={classes.backCancel}>
              <h1 className={classes.warn}>WARNING!</h1>
              <h1 className={classes.warningWords}> ARE YOU SURE YOU WANT TO LEAVE THE GAME?</h1>
              <h1 className={classes.warningWords1}>YOU WILL NOT BE ABLE TO BE ABLE TO CONTINUE BACK ONCE YOU LEAVE.</h1>
              <div className={classes.cancelBackBtn}>
                <div className={classes.Button} onClick={() => dispatch(modal.hide.started({}))}>
                  <p>CANCEL</p>
                  <img src={darkBg} alt="cancel" />
                </div>
                <div className={classes.Button} onClick={() => returnBack()}>
                  <p>LEAVE</p>
                  <img src={yellowBg} alt="cancel" />
                </div>
              </div>
            </div>
          )
        case 'saveProps':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              {/* <h1>Changes have been saved.</h1> */}
              <div>
                {/* <img src={save} alt="save" onClick={() => updateProps(activeParam)} /> */}
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveCourseManagerSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/coursemanagerlisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'forgetPassword':
          return (
            <div>
              <div className={classes.forget}>
                <div className={classes.innerContainer}>
                  <p className={classes.forgetPass}>Forgot your password?</p>
                  <p className={classes.enterEmailAddress}>Enter your email address and we'll send you a link to reset your password</p>
                </div>
                {/* <TextField1 placeholder="Email Address" type="text" /> */}
                <div className={classes.inputWrapper}>
                  <img src={textfieldbg} alt="textfieldbg" />
                  {/* <form onSubmit={e => e.preventDefault()}> */}
                    <input className={classes.input} onChange={changeHandler} onKeyPress={(e) => e.key === 'Enter' && submitButton(formData)} placeholder="EMAIL ADDRESS" type="text" id="email" name="email" autoComplete="off" />
                    {emailNotFound != false ? <p className={classes.emailnotfound}>Email is not registered.</p> : null }
                  {/* </form> */}
                </div>
                <div className={classes.Button} onClick={() => submitButton(formData)}>
                  <p>RESET</p>
                  <img src={yellowBg} alt="cancel" />
                </div>
                {/* <div className="submit"></div> */}
              </div>
              {/* <div className={classes.BackButton}>
                      <img src={BackBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                    </div> */}
              <div className={classes.BackButton} onClick={() => dispatch(modal.hide.started({}))}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
            </div>

          )
        case 'showTips':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={tips1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={tips2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
          )
        case 'showProfileSetup':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={profilesetup1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={profilesetup2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={profilesetup3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={profilesetup4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={profilesetup5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showAddCourseType':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={coursetypecreate1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypecreate2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypecreate3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypecreate4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypecreate5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showEditCourseType':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={next} onClick={() => swiper.slideTo(5)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedit6} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(4)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showDeleteCourseType':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedelete1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedelete2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursetypeedelete3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showAddCourse':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                    effect={"fade"}
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    className={classes.mySwiper}
                    onSwiper={(swiper) => setSwiper(swiper)}
                  >
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement1} />
                        <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement2} />
                        <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement3} />
                        <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement4} />
                        <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement5} />
                        <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={next} onClick={() => swiper.slideTo(5)}/></div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={coursemanagement6} />
                        <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(4)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showEditCourse':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={next} onClick={() => swiper.slideTo(5)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementedit6} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(4)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showDeleteCourse':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementdelete1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementdelete2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={coursemanagementdelete3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showAddTrainee':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={next} onClick={() => swiper.slideTo(5)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate6} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(4)} /><img src={next} onClick={() => swiper.slideTo(6)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineecreate7} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(5)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showEditTrainee':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={next} onClick={() => swiper.slideTo(5)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit6} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(4)} /><img src={next} onClick={() => swiper.slideTo(6)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineeedit7} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(5)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showDeleteTrainee':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
                <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={traineedelete1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineedelete2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={traineedelete3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showPastTrainingRecord':
          return (
            <div className={classes.paper}>
              <div className={classes.content}>
              <Swiper
                  effect={"fade"}
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={0}
                  className={classes.mySwiper}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={trainingsessions1} />
                      <div className="rightnav"><img src={next} onClick={() => swiper.slideTo(1)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={trainingsessions2} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(0)} /><img src={next} onClick={() => swiper.slideTo(2)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={trainingsessions3} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(1)} /><img src={next} onClick={() => swiper.slideTo(3)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={trainingsessions4} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(2)} /><img src={next} onClick={() => swiper.slideTo(4)}/></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={trainingsessions5} />
                      <div className="rightnav"><img src={back} onClick={() => swiper.slideTo(3)} /><img src={finish} onClick={() => dispatch(modal.hide.started({}))} /></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classes.navigation}>
                <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                  <img src={BackSymbol} /> Exit
                </div>
              </div>
            </div>
        )
        case 'showVideoTuts':
            return (
              <div className={classes.papervideotuts}>
                {data.source === 'corporate' ?
                  <div className="videowrapper">
                    <h3>How to set up corporate account</h3>
                    <div className='topborder'><img src={videoborder}/></div>
                    <video controls autoPlay>
                      <source src={corporate} type="video/mp4" />
                    </video><div className='bottomborder'><img src={videoborder}/></div></div> : data.source === 'coursetype' ? <div className="videowrapper">
                    <h3>How to create/edit course type</h3>
                    <div className='topborder'><img src={videoborder}/></div>
                    <video controls autoPlay>
                    <source src={coursetypevideo} type="video/mp4" />
                  </video><div className='bottomborder'><img src={videoborder}/></div></div> : data.source === 'trainee' ? <div className="videowrapper">
                    <h3>How to create/edit trainee accounts</h3>
                    <div className='topborder'><img src={videoborder}/></div>
                    <video controls autoPlay>
                    <source src={trainee} type="video/mp4" />
                  </video><div className='bottomborder'><img src={videoborder}/></div></div> : <div className="videowrapper">
                    <h3>How to use the authoring platform</h3>
                    <div className='topborder'><img src={videoborder}/></div>
                    <video controls autoPlay>
                      <source src={authoring} type="video/mp4" />
                    </video>
                    <div className='bottomborder'><img src={videoborder}/></div>
                  </div>
                }
                <div className={classes.navigation}>
                  <div className="exit" onClick={() => dispatch(modal.hide.started({}))} >
                    <img src={BackSymbol} /> Back
                  </div>
                </div>
              </div>
            )
        case 'DeleteCourseManager':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course manager?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRow()} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'courseTypeRequired':
          return (
            <div className={classes.paper}>
              <h1>Course type is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseRequired':
          return (
            <div className={classes.paper}>
              <h1>Course is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'escapeRoomRequired':
          return (
            <div className={classes.paper}>
              <h1>Escape room is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseNameRequired':
          return (
            <div className={classes.paper}>
              <h1>Course name is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseDescriptionRequired':
          return (
            <div className={classes.paper}>
              <h1>Course description is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'gameDurationRequired':
          return (
            <div className={classes.paper}>
              <h1>Game duration is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'wrongCredentials':
          return (
            <div className={classes.paper}>
              <h1>Wrong email or password. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordNotMatch':
          return (
            <div className={classes.paper}>
              <h1>Password does not match!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'emptyField':
          return (
            <div className={classes.paper}>
              <h1>Fields must not be empty!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordLength':
          return (
            <div className={classes.paper}>
              <h1>Password must be atleast 8 characters!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordChanged':
          return (
            <div className={classes.paper}>
              <h1>Password changed successfully!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => {dispatch(modal.hide.started({})); dispatch(push('/'))}} />
            </div>
          </div>
          )
        case 'notAnEmailAddress':
          return (
            <div className={classes.paper}>
              <h1>Username must be a valid email address. Please try again!</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
          </div>
          )
        case 'selectAssignedCourse':
          return (
            <div className={classes.paper}>
              <h1>Please select assigned course to play a game!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'noSessionRemaining':
          return (
            <div className={classes.paper}>
              <h1>You do not have available session credit please purchase a package!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'formHasErrors':
            return (
              <div className={classes.paper}>
                <h1>The form has an invalid values.<br />Please review and answer all form fields!</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => {
                    dispatch(modal.hide.started({}))
                    dispatch(auth.authclear.started({}))
                }} />
              </div>
            </div>
            )
        case 'DeleteCourses':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteCourses(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteCourseType':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course type?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteCourseTypes(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteTrainingCouse':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this training course?</h1>
              <div>
                <img src={yes} alt="yes" />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'exceedSession':
          return (
            <div className={classes.paper}>
            <h1>Your do not have enough available sessions.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'conflictAccount':
          return (
            <div className={classes.paper}>
            <h1>Email address already in use.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'DeletePerformanceRecords':
          return (
            <div className={classes.paper}>
              <h1>The selected records has been deleted</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRecordsForPerformance(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
                {/* <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} /> */}
              </div>
            </div>
          )
        case 'DeletePerformanceRecordsForTrainee':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this training session performance records?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRecordsForPerformanceForTrainee()} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteUserAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this corporate account?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'deleteTraineeAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this trainee account?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteAgentAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this agent / reseller account?</h1>
              <div>
              <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveProfileSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveCourseSetup':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/courselisting', cleardata: 'auth'})} />
              </div>
            </div>
            )
        case 'startTrainingCourseSession':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        default:
          return (
            <h1>Default</h1>
          )
      }
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal}`}
      open={modalShown}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
        style: { backgroundColor: 'rgba(0,0,0,0.9)' }
      }}
    >
      <Fade in={modalShown}>
        {
          ModalType(type)
        }
      </Fade>
    </Modal>
  );
};
