import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/props'

export const propsState = {
    status: 'idle',
    data: null,
    deleted: false,
}
export default {
    props: reducerWithInitialState(propsState)
        .case(actions.createprops.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createprops.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.props },
            })
        })
        .case(actions.createprops.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.createinitialprops.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createinitialprops.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.props },
            })
        })
        .case(actions.createinitialprops.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getpropsbycourseid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getpropsbycourseid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.props },
            })
        })
        .case(actions.getpropsbycourseid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getpropsbycourseidandlocationid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getpropsbycourseidandlocationid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.props },
            })
        })
        .case(actions.getpropsbycourseidandlocationid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updateprops.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
            })
        })
        .case(actions.updateprops.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
            })
        })
        .case(actions.updateprops.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
            })
        })
        .case(actions.deleteprops.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.deleteprops.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.deleteprops.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
    ,
}
