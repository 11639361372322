import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import modal from 'actions/modal'

export const modalState = {
    modalShown: false,
    data: null,
}

export default {
    modal: reducerWithInitialState(modalState)
        .case(modal.show.started, (state, action) => {
            return immutable(state, {
                modalShown: { $set: false },
            })
        })
        .case(modal.show.done, (state, action) => {
            return immutable(state, {
                modalShown: { $set: true },
                data: { $set: action.result.data },
            })
        })
        .case(modal.show.failed, (state, action) => {
            return immutable(state, {
                modalShown: { $set: false },
            })
        })
        .case(modal.hide.started, (state, action) => {
            return immutable(state, {
                modalShown: { $set: true },
            })
        })
        .case(modal.hide.done, (state, action) => {
            return immutable(state, {
                modalShown: { $set: false },
            })
        })
        .case(modal.hide.failed, (state, action) => {
            return immutable(state, {
                modalShown: { $set: true },
            })
        })
    ,
}
