import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import bg from 'assets/bg.png'
import darkBg from 'assets/BaseImg/darkBgwithDesign.png'
import logo from 'assets/logo.png'
import 'assets/stylesheets/application.css'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable';
import yellowBgLong from 'assets/BaseImg/yellowBgLong.png'
import darkBgLong from 'assets/BaseImg/darkbgLong.png'
import AccountInfoNew from 'components/AccountInfoNew';
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'
import modal from 'actions/modal'

const useStyles = makeStyles(() => ({
  container1: {
    width: "100vw",
    height: "84vh",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  row1: {
    display: 'flex',
    flexDirection: 'column'
  },
  overview: {
        width:'250%',
        marginTop:'10px',
        minWidth:'150px',
        maxWidth:'170px',
        margin:'0 auto',
        cursor:'pointer',
        '& img' : {
            width:'250%',
        }
    },
  overviewHidden: {
    width: '250%',
    marginTop: '10px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    opacity: 0,
    '& img': {
      width: '250%',
    }
  },
    headerBar : {
        padding:'40px 0px',
        display: 'flex',
        float:'right',
        color:'white',
        marginright:'20px',
        justifyContent: 'center',
        '& img': {
            height: '50%'
        },
        '& h4': {
            paddingRight: '6px',
            paddingTop: '6px',
            float:'right',
            fontFamily: 'impact'
        },
        '& h5': {
            fontFamily: 'impact'
        }
    },
  mainContainer: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  topInfo: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    justifyContent: "space-between",
  },
  logo: {
    width: '12rem'
  },
  containerOverViewData: {
  padding: "40px",
  marginLeft: '5rem',
  marginRight: '2rem',
  color: "white",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  "& h4": {
    fontFamily: "Impact",
    margin: "10px",
  },
  '& h5':{
      fontFamily: "Impact",
      letterSpacing: "1px",
      marginBottom: '0rem'
    },
    '& row': {
      fontFamily: "Impact",
    }
  },
  hidden: {
    opacity: 0
  },
  inputWrapper: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
    }
  },
  inputWrapper1: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
      width: "21.5rem"
    }
  },
  inputWrapperHidden: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    opacity: 0,
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
    }
  },
  temporary : {
    display: 'flex',
    flexDirection: 'column',
    width: "100vw",
    height: "85vh",
    justifyContent: "center",
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const CorporateOrAgentSelection = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  useEffect(() => {
  });

  return (
    <div>
      <BaseLayoutAdjustable bg={bg}>
        <div className={classes.topInfo}>
          <img src={logo} alt="logo" className={classes.logo} />
          <AccountInfoNew />
        </div>
        <div className={classes.mainContainer}>
          <div className={classes.container1}>
            <div className={classes.row1}>
              <div className={classes.inputWrapper} onClick={() => dispatch(push('/admiral/agentlisting'))}>
                <img src={yellowBgLong} alt="bg" />
                <p>AGENTS / RESELLER MANAGEMENT</p>
              </div>
              <div className={classes.inputWrapper} onClick={() => dispatch(push('/admiral/corporatelisting'))}>
                <img src={yellowBgLong} alt="bg" />
                <p>CORPORATE ACCOUNT MANAGEMENT</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.tips} onClick={() => dispatch(modal.show.started({type: 'showTips', source: ''}))} >
          <img src={tips}/>
          <p>TIPS</p>
        </div>
        <div className={classes.tutorials} onClick={() => dispatch(push('/corporate/videotutorials'))} >
          <img src={tutorials}/>
          <p>TUTORIALS</p>
        </div> */}
        <a className={classes.manual} href="https://www.createscape.com.sg/documents/admiral-account-user-manual.pdf" target="_blank" >
          <img src={manual}/>
          <p>MANUAL</p>
        </a>
      </BaseLayoutAdjustable>
    </div>
  )
}

export default CorporateOrAgentSelection;
