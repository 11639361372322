import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import DeletePerformanceRecords from 'assets/PerformanceRecords/DeleteTrainingSessionPerformanceRecords.png'
import View from 'assets/PerformanceRecords/ViewTrainingSessionPerformanceRecords.png'
import HomeBtn from 'assets/CorporateProfileSetup/HomeBtn.png'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import AccountInfoNew from 'components/AccountInfoNew'
import modal from 'actions/modal'
import { InfoTable7Col } from 'components/Tables/InfoTable7Col'
import AccountInfo from 'components/AccountInfo';
import TableBG from 'components/Tables/TableBG'
import yellowBg from 'assets/BaseImg/yellowBgLong.png'
import darkBG from 'assets/BaseImg/darkbgLong.png'

const useStyles = makeStyles(() => ({

}));

const LoadingPage = () => {
  const dispatch = useDispatch()
  const classes = useStyles();
  useEffect(() => {
    // getUserData()
    setTimeout(function () { runRefresh() }, 5000);
  })
  function runRefresh() {
    dispatch(push('/performancerecords'))
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <h1>Loading Please Wait will redirect back to page with results</h1>
    </BaseLayoutAdjustable>
  )
}

export default LoadingPage;
