const keyMirror = require('fbjs/lib/keyMirror')

export const ActionTypes = keyMirror({
    USER_LOGIN: undefined,
    USER_LOGOUT: undefined,
    SERVER_TIME: undefined,
    USER_REGISTER: undefined,
    USER_UPDATESESSION: undefined,
    USER_AUTHRESET: undefined,
    USER_DELETE: undefined,
    UPDATE_USERBYID: undefined,
    GET_USERBYACCOUNTTYPE: undefined,
    SHOW_MODAL: undefined,
    HIDE_MODAL: undefined,
    FETCH_REPOSITORIES: undefined,
    CANCEL_FETCH: undefined,
    SHOW_ALERT: undefined,
    HIDE_ALERT: undefined,
    CREATE_TRAINEECOURSE: undefined,
    GET_TRAINEECOURSEBYUSERID: undefined,
    UPDATE_TRAINEECOURSEBYUSERID: undefined,
    SELECTED_TRAINEECOURSE: undefined,
    CREATE_ACCOUNTPACKAGE: undefined,
    GET_ACCOUNTPACKAGEBYUSERID: undefined,
    UPDATE_ACCOUNTPACKAGEBYUSERID: undefined,
    CREATE_CORPORATEINFO: undefined,
    GET_CORPORATEINFOBYUSERID: undefined,
    UPDATE_CORPORATEINFOBYUSERID: undefined,
    DEDUCT_CORPORATESESSION: undefined,
    CREATE_COURSEMANAGERINFO: undefined,
    GET_COURSEMANAGERINFOBYUSERID: undefined,
    UPDATE_COURSEMANAGERINFOBYUSERID: undefined,
    CREATE_TRAINEEINFO: undefined,
    GET_TRAINEEINFOBYUSERID: undefined,
    UPDATE_TRAINEEINFOBYUSERID: undefined,
    CREATE_AGENTINFO: undefined,
    GET_AGENTINFOBYUSERID: undefined,
    UPDATE_AGENTINFOBYUSERID: undefined,
    CREATE_COURSE: undefined,
    GET_COURSEBYUSERID: undefined,
    UPDATE_COURSE: undefined,
    DELETE_COURSE: undefined,
    CREATE_COURSETYPE: undefined,
    GET_COURSETYPEBYUSERID: undefined,
    UPDATE_COURSETYPE: undefined,
    DELETE_COURSETYPE: undefined,
    CREATE_ENVIRONMENT: undefined,
    GET_ENVIRONMENT: undefined,
    UPDATE_ENVIRONMENT: undefined,
    DELETE_ENVIRONMENT: undefined,
    CREATE_ESCAPEROOM: undefined,
    GET_ESCAPEROOM: undefined,
    UPDATE_ESCAPEROOM: undefined,
    DELETE_ESCAPEROOM: undefined,
    CREATE_PROPS: undefined,
    CREATE_INITIAL_PROPS: undefined,
    GET_PROPSBYCOURSEID: undefined,
    GET_PROPSBYCOURSEIDANDLOCATIONID: undefined,
    UPDATE_PROPS: undefined,
    DELETE_PROPS: undefined,
    GET_GAMERESULTBYUSERID: undefined,
    GET_GAMERESULTBYCORPORATEID: undefined,
    DELETE_GAMERESULT: undefined,
    CREATE_EMAILVALIDATION: undefined,
    GET_EMAILVALIDATION: undefined,
    DELETE_EMAILVALIDATION: undefined,
})

export const propsConstants = {
    PARAM_ACTIVE_REQUEST : 'PARAM_ACTIVE_REQUEST',
    PARAM_ACTIVE_REQUEST1: 'PARAM_ACTIVE_REQUEST1',
    PARAM_LOCATION_TYPE: 'PARAM_LOCATION_TYPE',
    PARAM_PROPS_TYPE: 'PARAM_PROPS_TYPE',
    PARAM_PROPS_NAME: 'PARAM_PROPS_NAME',
    PARAM_FILE: 'PARAM_FILE',
    PARAM_LEVEL_SEQUENCE_1: 'PARAM_LEVEL_SEQUENCE_1',
    PARAM_LEVEL_SEQUENCE_2: 'PARAM_LEVEL_SEQUENCE_2',
    PARAM_LEVEL_SEQUENCE_3: 'PARAM_LEVEL_SEQUENCE_3',
    PARAM_LEVEL_SEQUENCE_4: 'PARAM_LEVEL_SEQUENCE_4',
    PARAM_PHONE_NUMBER_1: 'PARAM_PHONE_NUMBER_1',
    PARAM_PHONE_NUMBER_2: 'PARAM_PHONE_NUMBER_2',
    PARAM_PHONE_NUMBER_3: 'PARAM_PHONE_NUMBER_3',
    PARAM_PHONE_NUMBER_4: 'PARAM_PHONE_NUMBER_4',
    PROPS_NAME_PARAM: 'PROPS_NAME_PARAM',
    PARAM_ACTIVE_SUCCESS : 'PARAM_ACTIVE_SUCCESS',
    PARAM_ACTIVE_FAILED  : 'PARAM_ACTIVE_FAILED',

    COURSE_MANAGER_SETUP_DETAILS: 'COURSE_MANAGER_SETUP_DETAILS',
    TABLE_ROW_INDEX: 'TableRowIndex',

    GET_PROPS_REQUEST : "GET_PROPS_REQUEST",
    GET_PROPS_SUCCESS : "GET_PROPS_SUCCESS",
    GET_PROPS_FAILED  : "GET_PROPS_FAILED",

    UPDATE_PROPS_REQUEST : "UPDATE_PROPS_REQUEST",
    UPDATE_PROPS_SUCCESS : "UPDATE_PROPS_SUCCESS",
    UPDATE_PROPS_FAILURE : "UPDATE_PROPS_FAILURE",

    UPDATE_MESSAGE_TITLE_REQUEST: "UPDATE_MESSAGE_TITLE_REQUEST",
    UPDATE_MESSAGE_CONTENT_REQUEST: "UPDATE_MESSAGE_CONTENT_REQUEST",

    UPDATE_WEBLINK_REQUEST: "UPDATE_WEBLINK_REQUEST",
    UPDATE_PDF_IMAGEUPLOAD_REQUEST: "UPDATE_PDF_IMAGEUPLOAD_REQUEST",

    UPDATE_QUIZ_QNS: "UPDATE_QUIZ_QNS",
    UPDATE_QUIZ_TEXT1: "UPDATE_QUIZ_TEXT1",
    UPDATE_QUIZ_TEXT2: "UPDATE_QUIZ_TEXT2",

    UPDATE_QUIZ_CHOICE_QUESTION: "UPDATE_QUIZ_CHOICE_QUESTION",
    UPDATE_QUIZ_CHOICE: "UPDATE_QUIZ_CHOICE",
    UPDATE_QUIZ_CHOICE_A: "UPDATE_QUIZ_CHOICE_A",
    UPDATE_QUIZ_CHOICE_B: "UPDATE_QUIZ_CHOICE_B",
    UPDATE_QUIZ_CHOICE_C: "UPDATE_QUIZ_CHOICE_C",
    UPDATE_QUIZ_CHOICE_D: "UPDATE_QUIZ_CHOICE_D",
    UPDATE_QUIZ_CHOICE_CORRECT_ANS: "UPDATE_QUIZ_CHOICE_CORRECT_ANS"
}

export const CoporateConstants = {
    UPDATE_DISPLAY_NAME: "UPDATE_DISPLAY_NAME",
    UPDATE_TRAINEE_COURSES_NAME: "UPDATE_TRAINEE_COURSES_NAME",
    UPDATE_TRAINEE_COURSES_ID: "UPDATE_TRAINEE_COURSES_ID",
    TRAINEE_ACCOUNT_SETUP: "TRAINEE_ACCOUNT_SETUP",
    TRAINEE_ACCOUNT_SETUP_SUCCESS: "TRAINEE_ACCOUNT_SETUP_SUCCESS",
    TRAINEE_ACCOUNT_TABLE: "TRAINEE_ACCOUNT_TABLE",
    HAS_COURSE_TYPES: "HAS_COURSE_TYPES",
    HAS_COURSES: "HAS_COURSES",
    PERFORMANCE_RECORDS_TABLE: "PERFORMANCE_RECORDS_TABLE",
    PERFORMANCE_RECORDS_BELONG_TO_COPORATEID: "PERFORMANCE_RECORDS_BELONG_TO_COPORATEID",
    PERFORMANCE_RECORDS_TABLE_ROW_INDEX: 'PERFORMANCE_RECORDS_TABLE_ROW_INDEX',
    PERFORMANCE_RECORDS_DELETE_IDS: 'PERFORMANCE_RECORDS_DELETE_IDS',
    TRIGGER_TO_SENT_DELETE_IDS: 'TRIGGER_TO_SENT_DELETE_IDS',
    GAME_RESULTS_INFORMATION: 'GAME_RESULTS_INFORMATION',
    CORPORATE_PROFILE_SETUP: "CORPORATE_PROFILE_SETUP",
    TRIGGER_TO_SEND_TO_BACKEND: 'TRIGGER_TO_SEND_TO_BACKEND',
    TRIGGER_TO_DELETE_USER_ACCOUNT: 'TRIGGER_TO_DELETE_USER_ACCOUNT',
    SESSION_ID_STORAGE: 'SESSION_ID_STORAGE'
}