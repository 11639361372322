import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/traineecourse'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import { Param } from '@nestjs/common'

export const createTraineeCourse: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createtraineecourse.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineecourses`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.traineecourse
        }).pipe(
            map(data => {
                return actions.createtraineecourse.done({
                    params: param.payload,
                    result: { traineecourse: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createtraineecourse.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getTraineeCoursesByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.gettraineecoursebyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineecourses/${param.payload.userid}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            mergeMap(data => {
                return merge(
                    of(
                        actions.gettraineecoursebyuserid.done({ params: param.payload, result: { traineecourses: data.response }}),
                        actions.setselectedtraineecourse.started({courseid: data.response.data[0].course.id}),
                    )
                )
            }),
            catchError(error => {
                /* Observable.of(actions.gettraineecoursebyuserid.failed({
                    params: param.payload,
                    error: error,
                })), */
                return of(actions.gettraineecoursebyuserid.failed({
                    params: param.payload,
                    error: error,
                }))
            }),
        ),
    ),
)

export const updateTraineeCourseByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatetraineecoursebyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineecourses/${param.payload.userid}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.traineecourse
        }).pipe(
            map(data => {
                return actions.updatetraineecoursebyuserid.done({
                    params: param.payload,
                    result: { traineecourse: data.response },
                })
            }),
            catchError(error => {
                /* Observable.of(actions.updatetraineecoursebyuserid.failed({
                    params: param.payload,
                    error: error,
                })), */
                return of(actions.updatetraineecoursebyuserid.failed({
                    params: param.payload,
                    error: error,
                }))
            }),
        ),
    ),
)

export const selectedTraineeCourse: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.setselectedtraineecourse.started),
    map(data => {
        return actions.setselectedtraineecourse.done({
            params: data.payload,
            result: { courseid: data.payload.courseid },
        })
    }),
)