import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import HomeBtn from '../../../assets/CorporateProfileSetup/HomeBtn.png'
import Edit from '../../../assets/CourseManagerManagement/EditCourseManagerYellow.png'
import Create from '../../../assets/CourseManagerManagement/CreatenewCourseManagerYellow.png'
import DeleteCourse from '../../../assets/CourseManagerManagement/DeleteCourseManagerYellow.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import textbg from 'assets/textfield.svg'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import Calendar from 'assets/BaseImg/calendar.svg'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBgshort from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgShort.png'

import modal from 'actions/modal'
import auth from 'actions/auth'
import traineeinfo from 'actions/traineeinfo'
import corporateinfo from 'actions/corporateinfo'
import course from 'actions/course'
import coursetype from 'actions/coursetype'
import TableBG from 'components/Tables/TableBG'

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import traineeCoursesAction from 'actions/traineecourse';
import { corporatePageActions } from 'actions/coporatePageActions'

import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'assets/stylesheets/pagination.min.css'
// import "swiper/components/pagination/pagination.min.css"
//import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Pagination, Navigation
} from 'swiper';

// experiment test swiper
import arrowLeft from 'assets/swiperItems/arrowYellowLeft.png'
import arrowRight from 'assets/swiperItems/arrowYellowRight.png'

// experiement toggle button
import enableButton from 'assets/ToggleButton/enableButton.png'
import disableButton from 'assets/ToggleButton/disableButton.png'
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapperContainer: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
  },
  inputWrapperContainerHidden: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
    position: "fixed",
    bottom: "74vw",
  },
  inputWrapperDate: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',

    '& p': {
      color: '#FFD60F',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem',
      height: "auto"
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '& p': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
      lineHeight: '60px',
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperHidden: {
    opacity: 0,
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.5vw',
      width: '20rem'
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperDropdown: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& .MuiInputBase-root': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
      maxWidth: '11vw',
    },
    '& .MuiSelect-icon': {
      color: "#FFD60F",
    },

    '& .MuiInput-underline::before': {
      display: 'none',
    },

    '& .MuiInput-underline::after': {
      display: 'none',
    },

    '& img': {
      width: '25vw',
    }
  },
  inputDate: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '20rem',
    textAlign: 'center',

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputDateClicked: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '20rem',
    textAlign: 'center',
    marginLeft: '3rem',
    height: '60px',

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  input: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: "23rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    padding: '0px 48px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    height: '100%',
    letterSpacing: '1px',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  togglebutton: {
    top: '0px',
    right: '25px',
    position: 'absolute',
    height: '100%',
    maxHeight: 'none',
  },
  InputAPass: {
    color: 'white',
  },
  row2: {
    display: 'flex',
    marginTop: '3rem'
  },
  // swiper
  swiperWrapper: {
    width: "100vw",
    height: "84vh",
  },
  //back and save buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  containerOverViewData: {
    color: "white",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& h4": {
      fontFamily: 'Impact',
      paddingRight: '30px',
      margin: '0',
      display: 'inline',
      marginBottom: '-4px',
    },
    '& h5':{
        fontFamily: "Impact",
        letterSpacing: "1px",
        marginBottom: '0rem'
      },
      '& row': {
        fontFamily: "Impact",
      }
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const TraineeUserAccountSetup = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  // retrieving inputs
  const [TraineeName, setTraineeName] = useState('')
  const [TraineeDateOfBirth, setTraineeDateOfBirth] = useState('')
  const [TraineeEmail, setTraineeEmail] = useState('')
  const [GroupName, setGroupName] = useState('')
  const [TraineePassword, setTraineePassword] = useState('')
  const [StartDate, setStartDate] = useState(new Date)
  const [EndDate, setEndDate] = useState('')
  const [AssignedCourseName, setAssignedCourseName] = useState('')
  const [AssignedCourse, setAssignedCourse] = useState('ASSIGN COURSE*')
  const [AssignedCourseId, setAssignedCourseId] = useState('')
  const [NumOfSessionsAssigned, setNumOfSessionsAssigned] = useState(0)
  const [EnableDisable, setEnableDisable] = useState(true)

  const traineecoursesSanitized = useSelector((state: any) => state.corporateReducer.traineeCoursesId)
  const traineecoursesNameSanitized = useSelector((state: any) => state.corporateReducer.traineeCoursesName)
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const corporateenddate = useSelector((state: any) => state.auth.loggedinuser.user.corporateinfo.enddate)
  const numberofsession = useSelector((state: any) => state.auth.numberofsession)
  const traineecourseid = useSelector((state: any) => state.traineecourse.selectedcourseid)
  const courses = useSelector((state: any) => state.course.data)
  const [toggleOption, settoggleOption] = useState('enable')
  const [startofPage, setstartofPage] = useState(true)

  function retrieveInfoFromInput1(inputType: any, input: any) {
    inputType(input)
  }

  const saveButtonClicked = () => {
    if(AssignedCourse == 'ASSIGN COURSE*'){
      dispatch(modal.show.started({type: 'courseRequired', source: ''}))
    } else {
      dispatch(corporateinfo.deductsession.started({userid: id, numberofsession: NumOfSessionsAssigned, callbackparams: {
        "name": TraineeName,
        "email": TraineeEmail,
        "accounttype": "TRAINEE",
        "password": TraineePassword,
        "status": EnableDisable,
        "belongstoagent": "",
        "belongstouser": id,
        "dateofbirth": TraineeDateOfBirth,
        "startdate": StartDate,
        "enddate": EndDate,
        "groupname": GroupName,
        "isediting": false,
        "corporatecurrentsession": numberofsession,
        "assignedcoursename": traineecoursesNameSanitized,
        "assignedcourseid": traineecoursesSanitized,
      }}))
    }
  }

  useEffect(() => {
    if (startofPage === true) {
      dispatch(course.getcoursesbyuserid.started({userid: id}))
      setstartofPage(false)
    } else {
      temp()
    }
  }, [StartDate]);

  const [isSwiperClicked, setisSwiperClicked] = useState(false)

  function arrowButtonClicked(whichDirection: any) {
    if (whichDirection === 'right') {
      setisSwiperClicked(true)
    } else {
      setisSwiperClicked(false)
    }
  }

  const arrowButtonSeen = (determiningFactor: any) => {
    if (determiningFactor === true) {
      return (
        <div className="arrowBox">
          <div onClick={() => arrowButtonClicked('left')}><img src={arrowLeft} alt="arrowLeft" /> PREVIOUS PAGE</div>
          <img src={arrowRight} alt="arrowRight" style={{opacity: 0}} />
        </div>
      )
    } else {
      return(
        <div className="arrowBox">
          <div><img src={arrowLeft} alt="arrowLeft" style={{ opacity: 0 }} /></div>
          <div onClick={() => arrowButtonClicked('right')}>NEXT PAGE<img src={arrowRight} alt="arrowRight"  /></div>
        </div>
      )
    }
  }

  /* function retrieveAndCallAPIInput(input: any) {
    courses.data.map((data: any) => {
      if(data.id == input){
        console.log(data.name)
        setAssignedCourseName(data.name)
        setAssignedCourse(data?.coursetype?.name)
        setAssignedCourseId(data.id)
      }
    });
  } */

  function retrieveAndCallAPIInput(input: any) {
    let array:any = [];
    let array2:any = [];
    dispatch(corporatePageActions.updateTraineeCoursesId(''));
    let sanitizedInput = input.toString().replace(',ASSIGN COURSE*', '').substring(1).split(',');

    courses.data.map((data: any) => {
      sanitizedInput.map((i: any, index: any) => {
        if(data.id == i) {
          array.push(data.name)
          array2.push(data.id)
          setAssignedCourse(data?.coursetype?.name)
          setAssignedCourseId(traineecourseid)
          dispatch(corporatePageActions.updateTraineeCoursesId(array2.toString()));
          dispatch(corporatePageActions.updateTraineeCoursesName(array.toString()));
        }
      });
    });

    /* dispatch(traineeCoursesAction.setselectedtraineecourse.started({courseid: input}));
    dispatch(corporatePageActions.updateTraineeCoursesId(input.toString().replace(',ASSIGN COURSE*', '').substring(1)));

    courses.data.map((data: any) => {
      input.map((i: any, index: any) => {
        if(data.id == i) {
          setAssignedCourseName(data.name)
          setAssignedCourse(data?.coursetype?.name)
          setAssignedCourseId(traineecourseid)
        }
      });
    }); */
  }

  const disableButtonClicked = () => {
    settoggleOption('enable')
    setEnableDisable(true)
  }
  const enableButtonClicked = () => {
    settoggleOption('disable')
    setEnableDisable(false)
  }

  const [values, setValues] = React.useState({
    password: TraineePassword,
    showPassword: false,
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const customToggleButton = (isItEnableOrDisable: any) => {
    if (isItEnableOrDisable === 'disable') {
      // setEnableDisable('disable')
      return (
        <img src={disableButton} alt="disableButton" onClick={() => disableButtonClicked()}/>
      )
    } else {
      return (
        <img src={enableButton} alt="enableButton" onClick={() => enableButtonClicked()}/>
      )
    }
  }

  function temp() {
    let x = 1
  }

  const [isDateOfBirthClicked, setisDateOfBirthClicked] = useState(false)
  const dateOfBirthClicked = () => {
    setisDateOfBirthClicked(true)
    let dateInput = document.getElementById("MANAGER")
    if (dateInput !== null) {
      dateInput.click()
      dateInput.click()
    } else {
      temp()
    }
  }

  const mydate = require('current-date')
  const [hasDateInputBeenClick, sethasDateInputBeenClick] = useState(false)
  const [hasDateInputBeenClick1, sethasDateInputBeenClick1] = useState(false)

  const swiperTestPaginationIcon = (LeftorRight: any) => {
    if (LeftorRight === true) {
      return (
        <div className="swiperTestPagination">
          <p>2 / 2</p>
        </div>
      )
    }
    else {
      return (
        <div className="swiperTestPagination">
          <p>1 / 2</p>
        </div>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <div className={classes.containerOverViewData}>
          <h4>Remaining Sessions: {numberofsession}</h4>
          <AccountInfoNew />
        </div>
      </div>
      <div className="SwiperTestMainContainer">
        <h3>TRAINEE ACCOUNT SETUP</h3>
        <h5>*INDICATES REQUIRED FIELD</h5>
        <div className="swiperTestContainer">
          {arrowButtonSeen(isSwiperClicked)}
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} placeholder="TRAINEE NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setTraineeName, e.target.value)} />
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={isDateOfBirthClicked === true ? classes.inputDateClicked : classes.inputDate}  placeholder="TRAINEE DATE OF BIRTH*" type={isDateOfBirthClicked === true ? "date" : "text"}  onClick={() => dateOfBirthClicked()} onChange={(e) => retrieveInfoFromInput1(setTraineeDateOfBirth, new Date(e.target.value).toISOString())} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="new-email" className={classes.input} placeholder="TRAINEE EMAIL*" type="text" onChange={(e) => retrieveInfoFromInput1(setTraineeEmail, e.target.value)} />
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} placeholder="GROUP NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setGroupName, e.target.value)} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="new-password" className={classes.input} placeholder="TRAINEE PASSWORD*" type="password" onChange={(e) => retrieveInfoFromInput1(setTraineePassword, e.target.value)} /> */}
              <input
                placeholder="TRAINEE PASSWORD*"
                type={values.showPassword ? 'text' : 'password'}
                value={TraineePassword}
                autoComplete="new-password"
                className={classes.inputPass}
                onChange={(e) => retrieveInfoFromInput1(setTraineePassword, e.target.value)}
              />
              <InputAdornment position="end" className={classes.togglebutton}>
                    <IconButton className={classes.InputAPass}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            </div>
            <div className={classes.inputWrapperHidden}>
              <img src={textbg} alt="bg" />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <Select onChange={(e) => retrieveAndCallAPIInput(e.target.value)} multiple defaultValue={['ASSIGN COURSE*']} variant="standard">
                {courses != null ?
                    courses.data.map((data: any) => {
                      return (<MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>)
                    })
                : null}
                <MenuItem value="ASSIGN COURSE*" disabled>ASSIGN COURSE*</MenuItem>
              </Select>
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <p className={classes.input}>{AssignedCourse != 'ASSIGN COURSE*' ? AssignedCourse :  'COURSE TYPE'}</p>
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} min="1" max="1000" placeholder="SET NUMBER OF SESSIONS*" type="NUMBER" onChange={(e) => retrieveInfoFromInput1(setNumOfSessionsAssigned, e.target.value)} />
            </div>
            <div className={classes.inputWrapperDate}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={hasDateInputBeenClick===true? classes.inputDateClicked:classes.inputDate} placeholder="SELECT START DATE OF ACCOUNT*" type={hasDateInputBeenClick === true ? "date" : "text"} min={mydate('date')} max={new Date(corporateenddate).toISOString()?.replace(/T.*/,'').split('-').join('-')} onClick={() => sethasDateInputBeenClick(true)} onChange={(e) => retrieveInfoFromInput1(setStartDate, new Date(e.target.value).toISOString())} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={hasDateInputBeenClick1 === true ? classes.inputDateClicked : classes.inputDate} placeholder="SELECT END DATE OF ACCOUNT*" type={hasDateInputBeenClick1 === true ? "date" : "text"} min={mydate('date')} max={new Date(corporateenddate).toISOString()?.replace(/T.*/,'').split('-').join('-')} onClick={() => sethasDateInputBeenClick1(true)} onChange={(e) => retrieveInfoFromInput1(setEndDate, new Date(e.target.value).toISOString())} />
            </div>
            <div className={classes.inputWrapper}>
              {customToggleButton(toggleOption)}
            </div>
          </div>
        </div>
      </div>
      {swiperTestPaginationIcon(isSwiperClicked)}
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/traineelisting'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="SaveSymbol" />
          <p>SAVE</p>
        </div>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default TraineeUserAccountSetup;
