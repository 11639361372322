import React from 'react'
import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Switch } from 'react-router'

import RoutePublic from 'modules/RoutePublic'
import RoutePrivate from 'modules/RoutePrivate'

import Login from 'routes/Login'
import CorporateUserOverView from '../routes/corporate/CoporateUserOverView'
import CorporateProfileSetup from '../routes/corporate/CoporateProfileSetup'
import CourseManagerManagement from '../routes/corporate/CourseManagerManagementRoutes/CourseManagerManagement'
import CourseManagerSetup from 'routes/corporate/CourseManagerManagementRoutes/CourseManagerSetup'
import TraineeUserManagement from '../routes/corporate/TraineeUserManagementRoute/TraineeUserManagement'
import TraineeUserAccountSetup from '../routes/corporate/TraineeUserManagementRoute/TraineeUserAccountSetup'
import AuthoringPlatformHome from 'routes/AuthoringPlatformHome'
import PlatformPyramid from 'routes/PlatformPyramid'

import LoadingPage from 'routes/corporate/TraineeUserManagementRoute/LoadingPage'

// User Routes
import TraineeProfileSetup from 'routes/trainee/TraineeProfileSetup'
import StartTrainingCourseSession from 'routes/trainee/StartTrainingCourseSession'
import Home from 'routes/Home'
import Props from 'routes/Props'

import { History } from 'history'
import { RootProps } from 'types/common'
import PlatformGallery from 'routes/PlatformGallery'
import TransitionsModal from '../components/Modal'
import CorporateAccountSetup from 'routes/admiral/CorporateAccountSetup'
import AgentAccountSetup from 'routes/admiral/AgentAccountSetup'
import AgentListing from 'routes/admiral/AgentListing'
import CorporateListing from 'routes/admiral/CorporateListing'
import CorporateOrAgentSelection from 'routes/admiral/CorporateOrAgentSelection'
import CorporateAccountEdit from 'routes/admiral/CorporateAccountEdit'
import TraineeOverview from 'routes/trainee/TraineeOverView'
import AgentAccountEdit from 'routes/admiral/AgentAccountEdit'
import TraineePerformanceRecords from 'routes/trainee/TraineePerformanceRecords'
import CourseSetup from 'routes/corporate/CourseGroupManagementRoutes/CourseSetup'
import CourseManagement from '../routes/corporate/CourseGroupManagementRoutes/CourseManagement'
import CorporatePerformanceRecords from '../routes/corporate/CorporatePerformanceRecords'
import CourseManagerEdit from 'routes/corporate/CourseManagerManagementRoutes/CourseManagerEdit'
import TraineeUserAccountEdit from 'routes/corporate/TraineeUserManagementRoute/TraineeUserAccountEdit'
import CourseEdit from 'routes/corporate/CourseGroupManagementRoutes/CourseEdit'
import PlatformChamber from 'routes/PlatformChamber'
import PlatformCommand from 'routes/PlatformCommand'
import { makeStyles } from '@material-ui/core/styles';
import CourseTypeSetup from 'routes/corporate/CourseTypeManagementRoutes/CourseTypeSetup'
import CourseTypeEdit from 'routes/corporate/CourseTypeManagementRoutes/CourseTypeEdit'
import CourseTypeManagement from 'routes/corporate/CourseTypeManagementRoutes/CourseTypeManagement'
import ResetPassword from 'routes/ResetPassword'
import VideoTutorials from 'routes/VideoTutorials'
import Manual from 'routes/Manual'

const useStyles = makeStyles(() => ({
  footer: {
    color: '#ffffff',
    opacity: '0.8',
    width: '100%',
    bottom: '10px',
    padding: '0px 20px',
    position: 'absolute',
    fontSize: '10px',
    textAlign: 'center',
    fontFamily: 'Lato',
    pointerEvents: 'none',
    textTransform: 'uppercase',
    wordSpacing: '1px',

    '& a': {
      color: '#ffffff',
      pointerEvents: 'all',
      textDecoration: 'underline',
    }
  }
}));

const App: React.FC<RootProps> = ({ history }: { history: History }) => {
    const user = useSelector((state: any) => state.auth)
    const classes  = useStyles();

    return (
      <>
        <ConnectedRouter history={history}>
            <Switch>
                <RoutePublic component={() => (<Login/>)} isAuthenticated={user.isAuthenticated} accountType={user.loggedinuser} path="/" exact />
                <RoutePublic component={() => (<ResetPassword/>)} isAuthenticated={user.isAuthenticated} accountType={user.loggedinuser} path="/resetpassword" exact />

                {/* admiral routes */}
                <RoutePrivate  component={() => (<CorporateOrAgentSelection />)} isAuthenticated={user.isAuthenticated} path="/admiral/selection" exact />
                <RoutePrivate component={() => (<CorporateListing />)} isAuthenticated={user.isAuthenticated} path="/admiral/corporatelisting" exact />
                <RoutePrivate component={() => (<CorporateAccountSetup />)} isAuthenticated={user.isAuthenticated} path="/admiral/createcorporate" exact />
                <RoutePrivate component={() => (<CorporateAccountEdit />)} isAuthenticated={user.isAuthenticated} path="/admiral/editcorporateaccount" exact />
                <RoutePrivate component={() => (<AgentAccountSetup />)} isAuthenticated={user.isAuthenticated} path="/admiral/createagent" exact />
                <RoutePrivate component={() => (<AgentListing />)} isAuthenticated={user.isAuthenticated} path="/admiral/agentlisting" exact />
                <RoutePrivate component={() => (<AgentAccountEdit />)} isAuthenticated={user.isAuthenticated} path="/admiral/editagentaccount" exact />

                {/* CoporateUserOverview Routes */}
                <RoutePrivate component={() => (<CorporateUserOverView />)} isAuthenticated={user.isAuthenticated} path="/corporate/overview" exact />
                {/* <Route path='/corporate/overview' component={CorporateUserOverView} /> */}
                <RoutePrivate component={() => (<CorporateProfileSetup />)} isAuthenticated={user.isAuthenticated} path="/corporate/profilesetup" exact />
                 {/* Course Manager Management Routes */}
                <RoutePrivate component={() => (<CourseManagerManagement />)} isAuthenticated={user.isAuthenticated} path="/corporate/coursemanagerlisting" exact />
                <RoutePrivate component={() => (<CourseManagerEdit />)} isAuthenticated={user.isAuthenticated} path="/corporate/editcoursemanager" exact />
                <RoutePrivate component={() => (<CourseManagerSetup />)} isAuthenticated={user.isAuthenticated} path="/corporate/createcoursemanager" exact />
                {/* Course Manager Management Routes */}

                {/* Trainee User Management Routes */}
                <RoutePrivate component={() => (<StartTrainingCourseSession />)} isAuthenticated={user.isAuthenticated} path="/trainee/startsession" exact />
                <RoutePrivate component={() => (<TraineeUserAccountSetup />)} isAuthenticated={user.isAuthenticated} path="/corporate/createtrainee" exact />
                <RoutePrivate component={() => (<TraineeUserAccountEdit />)} isAuthenticated={user.isAuthenticated} path="/corporate/edittrainee" exact />
                <RoutePrivate component={() => (<TraineeUserManagement />)} isAuthenticated={user.isAuthenticated} path="/corporate/traineelisting" exact />
                {/* End of Trainee User Management Routes */}

                {/* Course Group Management Routes */}
                <RoutePrivate component={() => (<CourseManagement />)} isAuthenticated={user.isAuthenticated} path="/corporate/courselisting" exact />
                <RoutePrivate component={() => (<VideoTutorials />)} isAuthenticated={user.isAuthenticated} path="/corporate/videotutorials" exact />
                <RoutePrivate component={() => (<Manual />)} isAuthenticated={user.isAuthenticated} path="/corporate/manual" exact />
                {/* <CourseGroupManagementRoute /> */}

                <RoutePrivate component={() => (<CourseTypeSetup />)} isAuthenticated={user.isAuthenticated} path="/corporate/createcoursetype" exact />
                <RoutePrivate component={() => (<CourseTypeEdit />)} isAuthenticated={user.isAuthenticated} path="/corporate/coursetypeedit" exact />
                <RoutePrivate component={() => (<CourseTypeManagement />)} isAuthenticated={user.isAuthenticated} path="/corporate/coursetypelisting" exact />

                {/* End of Course Type Management Routes */}
                <RoutePrivate component={() => (<TraineeOverview />)} isAuthenticated={user.isAuthenticated} path="/trainee/overview" exact />
                <RoutePrivate component={() => (<CourseSetup />)} isAuthenticated={user.isAuthenticated} path="/corporate/createtrainingcourse" exact />
                <RoutePrivate component={() => (<CourseEdit />)} isAuthenticated={user.isAuthenticated} path="/corporate/edittrainingcourse" exact />
                <RoutePrivate component={() => (<CorporatePerformanceRecords />)} isAuthenticated={user.isAuthenticated} path="/corporate/performancerecords" exact />
                {/* End of CoporateUserOverview Routes */}

                <RoutePrivate component={Home} isAuthenticated={user.isAuthenticated} path="/home" exact />
                <RoutePrivate component={Props} isAuthenticated={user.isAuthenticated} path="/props" exact />

                {/*Authoring Page */}
                <RoutePrivate component={AuthoringPlatformHome} isAuthenticated={user.isAuthenticated} path="/corporate/authoringplatform" exact />
                <RoutePrivate component={PlatformGallery} isAuthenticated={user.isAuthenticated} path="/corporate/gallery" exact />
                <RoutePrivate component={PlatformPyramid} isAuthenticated={user.isAuthenticated} path="/corporate/pyramid" exact />
                <RoutePrivate component={PlatformChamber} isAuthenticated={user.isAuthenticated} path="/corporate/chamber" exact />
                <RoutePrivate component={PlatformCommand} isAuthenticated={user.isAuthenticated} path="/corporate/controlroom" exact />

                {/* User Account Route */}
                <RoutePrivate component={TraineeProfileSetup} isAuthenticated={user.isAuthenticated} path="/trainee/profilesetup" exact />
                <RoutePrivate component={() => (<TraineePerformanceRecords />)} isAuthenticated={user.isAuthenticated} path="/trainee/performancerecords" exact />

                {/* for loading */}
                <RoutePrivate component={LoadingPage} isAuthenticated={user.isAuthenticated} path="/trainee/loadingpage" exact />
            </Switch>
        </ConnectedRouter>
        <div className={classes.footer}>
          <p>CreatEscape Interactive Training Web Portal is powered by Captivate Escape Rooms &copy; {new Date().getFullYear()} All rights reserved | <a href="https://www.createscape.com.sg/documents/terms.pdf" target="_blank">Terms and Conditions</a> | <a href="https://www.createscape.com.sg/documents/privacy-policy.pdf" target="_blank">Privacy Policy</a>{` | VERSION: ${process.env.REACT_APP_VERSION_NUMBER}`}</p>
        </div>
        <TransitionsModal />
      </>
    )
}

export default App
