import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';

// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'

import bg from 'assets/PlatformHome/AuthoringPlatformBG1.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import corporateAccount from 'assets/tipsandtuts/corporateaccount.png'
import corporateAccountActive from 'assets/tipsandtuts/corporateaccountActive.png'
import courseType from 'assets/tipsandtuts/coursetype.png'
import courseTypeActive from 'assets/tipsandtuts/coursetypeActive.png'
import traineeAccount from 'assets/tipsandtuts/traineeaccounts.png'
import traineeAccountActive from 'assets/tipsandtuts/traineeaccountsActive.png'
import authoringPlatform from 'assets/tipsandtuts/authoringplatform.png'
import authoringPlatformActive from 'assets/tipsandtuts/authoringplatformActive.png'
import { propsActions } from 'actions/propsActions'
import modal from 'actions/modal'

const useStyles = makeStyles(() => ({
  containerAuthoringPlatform: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    '& .col-sm-6': {
      cursor: 'pointer',
    },
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    paddingBottom: '1rem',
    },
    images: {
        width: '32rem',
        cursor: 'pointer'
    },
    header: {
        fontFamily: 'impact',
        color: '#FFD140',
        fontSize: '2rem'
    },
    // back button
    backBtn: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'fixed',
      bottom: '40px',
      left: '80px',
      '& p': {
        color: '#FFD84E',
        fontFamily: 'impact',
        fontSize: '1.2rem',
        letterSpacing: '1px',
        paddingLeft: '1rem'
      },
      '& img': {
        width: '3vw%',
        height: '100%',
      }
    },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    height: '12vh'
  },
  logo: {
    width: '12rem'
  },
}));

const VideoTutorials = () => {
    const dispatch = useDispatch();
    const classes  = useStyles();
    const editData = useSelector((state: any) => state.router.location.state);

    useEffect(() => {
      console.log(editData);
    }, []);

    return (
        <BaseLayoutAdjustable bg={bg}>
              <div className={classes.topInfo}>
                <img src={logo} alt="logo" className={classes.logo} />
                <AccountInfoNew />
              </div>
              <div className={classes.containerAuthoringPlatform}>
                <h3 className={classes.header}>VIDEO TUTORIALS</h3>
                <div className="row">
                    <div className="col-sm-6">
                      <img src={corporateAccountActive} alt="homeActive" className={classes.images} onClick={() => dispatch(modal.show.started({type: 'showVideoTuts', source: 'corporate'}))}/>
                    </div>
                    <div className="col-sm-6">
                      <img src={courseTypeActive} alt="homeActive" className={classes.images} onClick={() => dispatch(modal.show.started({type: 'showVideoTuts', source: 'coursetype'}))}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                      <img src={traineeAccountActive} alt="homeActive" className={classes.images} onClick={() => dispatch(modal.show.started({type: 'showVideoTuts', source: 'trainee'}))}/>
                    </div>
                    <div className="col-sm-6">
                      <img src={authoringPlatformActive} alt="homeActive" className={classes.images} onClick={() => dispatch(modal.show.started({type: 'showVideoTuts', source: 'authoring'}))}/>
                    </div>
                </div>
              </div>
          <div className={classes.backBtn} onClick={() => dispatch(goBack())}>
            <img src={BackSymbol} alt="BackSymbol" />
            <p>EXIT</p>
          </div>
        </BaseLayoutAdjustable>
    )
}

export default VideoTutorials;
