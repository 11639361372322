import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateEscaperoomParam { escaperoom: any }
interface CreateEscaperoomResult { escaperoom: any }
interface CreateEscaperoomError { error: string }

interface GetEscaperoomParam { courseid: string }
interface GetEscaperoomResult { escaperoom: any }
interface GetEscaperoomError { error: string }

interface UpdateEscaperoomParam { escaperoomid: string, escaperoom: any }
interface UpdateEscaperoomResult { escaperoom: any }
interface UpdateEscaperoomError { error: string }

interface DeleteEscaperoomParam { escaperoomid: any }
interface DeleteEscaperoomResult { escaperoom: any }
interface DeleteEscaperoomError { error: string }

export default {
    createescaperoom: ac.async<CreateEscaperoomParam, CreateEscaperoomResult, CreateEscaperoomError>(ActionTypes.CREATE_ESCAPEROOM),
    getescaperoombycourseid: ac.async<GetEscaperoomParam, GetEscaperoomResult, GetEscaperoomError>(ActionTypes.GET_ESCAPEROOMBYCOURSEID),
    updateescaperoom: ac.async<UpdateEscaperoomParam, UpdateEscaperoomResult, UpdateEscaperoomError>(ActionTypes.UPDATE_ESCAPEROOM),
    deleteescaperoom: ac.async<DeleteEscaperoomParam, DeleteEscaperoomResult, DeleteEscaperoomError>(ActionTypes.DELETE_ESCAPEROOM),
}