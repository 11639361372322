// Programmer Note
// Use of this page as a template for the rest of corporate user overview routes
// Coporate overview routes that uses template -
// 1.) CorporateUserOverview
// 2.) CourseManagerManagement
// 3.) CourseTypeManagement
// 4.) PerformanceRecords
// 5.) TraineeUserManagement
// 6.) TrainingCourseManagement

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import modal from 'actions/modal'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import textbg from 'assets/textfield.svg'
import HomeBtn from 'assets/CorporateProfileSetup/HomeBtn.png'
import Upload from 'assets/CorporateProfileSetup/UploadCompanyLogo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'

import 'assets/stylesheets/application.css';
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// text passwordfield
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import axios from 'axios';
import LoginInput from 'components/form/LoginInput';
import userActions from 'actions/user';
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  },
  login: {
        width:'250%',
        marginTop:'30px',
        minWidth:'150px',
        maxWidth:'170px',
        margin:'0 auto',
        cursor:'pointer',
        '& img' : {
            width:'250%'
        }
    },
    requiredfield: {
        color: "#FFD84C",
        fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
        paddingBottom: '1rem',
        letterSpacing: '3px',
        fontSize: '2vh'
    },
  header: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
    input: {
      // backgroundImage:`url(${bg})`,
      background: 'transparent',
      position: 'absolute',
      top: '0',
      left: '0',
      border: 'none',
      textAlign: 'center',
      color: '#ffd84d',
      fontFamily: "Impact",
      letterSpacing: "1px",
      fontSize: "1.2rem",
      width: "100%",
      height: '100%',
      textTransform: 'initial',

      '&::placeholder': {
        textAlign: 'center',
        color: '#ffd84d',
        textTransform: 'uppercase',
        border: 'none'
      },
      '&:focus': {
        border: 'none',
        outline: 'none'
      }
    },
    inputP: {
      // backgroundImage:`url(${bg})`,
      background: 'transparent',
      position: 'absolute',
      top: '0',
      left: '0',
      border: 'none',
      textAlign: 'center',
      cursor: 'default',
      height: '80px',
      color: '#ffd84d',
      fontFamily: "Impact",
      lineHeight: '80px',
      letterSpacing: "1px",
      fontSize: "1.2rem",
      width: "100%",
    },
    inputWrapper: {
      position: 'relative',
      width: "100%"
    },
    inputWrapper1: {
      position: 'relative',
      width: "100%",
      '& img': {
        width: '100%'
      }
    },
    inputWrapperDisabled: {
      // display: 'none',
      opacity: '0',
      position: 'relative',
      width: "100%"
    },
    // text field password
    noBorder: {
      border: "none",
    },
    InputA: {
      color: 'white',
      position: "relative",
      left: "19rem",
      bottom: "3px",
    },
    // back button and save button style
    backBtn: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        color: '#FFD84E',
        fontFamily: 'impact',
        fontSize: '1.2rem',
        letterSpacing: '1px',
        paddingLeft: '1rem'
      },
      '& img': {
        width: '3vw%',
        height: '100%',
        cursor: 'pointer'
      }
    },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
    pageBottom: {
      display: 'flex',
      position: 'fixed',
      bottom: "1rem",
      right: "2rem",
    },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  text: {
  fontFamily: "Impact",
  fontSize: "4vh",
  letterSpacing: "4px",
  paddingBottom: "2rem",
},
externalRow: {
  display: 'flex',
  position: "relative",
  height: "45vh",
  bottom: "0rem"
},
row1: {
  display: 'flex',
  flexDirection: 'column',
  width: "30rem",
  height: "22rem",
  justifyContent: "space-evenly",
    '& img': {
      width: '100%'
    }
  },
  row2: {
    display: 'flex',
    flexDirection: 'column',
    width: "56rem",
    height: "5rem",
    '& input': {
      width: "100%",
      position: "absolute",
      bottom: "0.3rem",
    },
  },
  internalRow1: {
    display: 'flex',
    flexDirection: 'column',
    width: "43rem",
    height: "22rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '39rem'
    }
  },
  uploadImg: {
    width: '100%',
    cursor: 'pointer'
  },
  upload: {
    // display: 'flex',
    '& img': {
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    },
    '& input': {
      position: 'fixed'
    }
  },
  containerCoporateProfileSetup : {
    textAlign: 'center',
  },
  hidden: {
    opacity: '0'
  },
  togglebutton: {
    position: 'absolute',
    right: '40px',
    top: '40px',
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    lineHeight: '80px',
    padding: '0px 66px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  // text field password
  Passwordinput: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
    padding: '0px 74px',
    width: "100%",
    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none',
      width: "28rem",
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  PasswordinputWrapper: {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  PasswordnoBorder: {
    border: "none",
  },
  PasswordInputA: {
    color: 'white',
    position: 'absolute',
    top: '-7px',
    right: '36px',
  },
  // image upload input button
  inputButton: {
    zIndex: -1,
    position: "fixed",
    left: "1000rem",
  },
  InputAPass: {
    color: 'white',
  },
  notes: {
    color: '#ffffff',
    fontSize: '12px',
    fontFamily: 'Impact',
    position: 'absolute',
    bottom: '-24px',
    left: '28px',
    letterSpacing: '1px',
    display: 'flex',
    whiteSpace: 'pre',
  },
  file: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: '7px',

    '& p': {
      color: '#ffffff',
      fontSize: '12px',
      margin: 0,
    },

    '& button': {
      borderRadius: '20px',
      background: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      width: 'auto',
      height: '16px',
      lineHeight: '12px',
      fontSize: '9px',
      textAlign: 'center',
      marginLeft: '6px',
    }
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const CorporateProfileSetup = () => {
  const dispatch = useDispatch()
  const classes  = useStyles();
  const accessToken = useSelector((state: any) => state.auth.loggedinuser.token.accessToken)
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const password = useSelector((state: any) => state.user.password)
  const [MasterAccName, setMasterAccName] = useState("MASTER ACCOUNT MANAGER NAME*")
  const [MasterAccEmail, setMasterAccEmail] = useState("MASTER ACCOUNT MANAGER EMAIL ID*")
  const [MasterAccPassword, setMasterAccPassword] = useState("MASTER ACCOUNT MANAGER PASSWORD*")
  const [CompanyName, setCompanyName] = useState('')
  const [formData, setFormData] = useState('');
  const [corporateLogo, setCorporateLogo] = useState('')

  let CoporateProfileForm = new FormData();

  const OnChangeFileInput = (e: any) => {
    let imageFile = e.target.files[0];
    const img = new Image();
    img.src = window.URL.createObjectURL(imageFile)
    if(imageFile != null) {
      img.onload = () => {
        if((img.width <= 500 && img.height <= 500) && imageFile.size < 1000000){
          setCorporateLogo('****' + imageFile.name.substr(imageFile.name.length - 4));
          setFormData(imageFile);
        } else {
          if(img.width > 500 || img.height > 500) {
            dispatch(modal.show.started({type: 'imageDimensionError', source: ''}))
          } else if(imageFile.size > 1000000) {
            dispatch(modal.show.started({type: 'fileSizeError', source: ''}))
          }
        }
      }
    }
  }

  function retrieveInfoFromInput(inputType: any, input: any) {
    CoporateProfileForm.append(inputType, input)
    switch (inputType) {
      case 'name':
        setMasterAccName(input)
      break;
      case 'companyname':
        setCompanyName(input)
      break;
      default:
        return false;
    }
  }

    const [values, setValues] = React.useState({
      password: MasterAccPassword,
      showPassword: false,
    });

    const handleChange = (prop: any) => (event: any) => {
      setMasterAccPassword(event.target.value)
      setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: any) => {
      event.preventDefault();
    };

  const saveButtonClicked = () => {
    CoporateProfileForm.append('companyname', CompanyName)
    CoporateProfileForm.append('logo', formData);
    patchDatatoSelectedUserExtraInfo()
    patchDatatoSelectedUser()
    dispatch(modal.show.started({type: 'startTrainingCourseSession', source: ''}))
  }

  const RemoveImage = () => {
    setCorporateLogo('');
    setFormData('');
  }

  const imageclicked = () => {
    let x = document.getElementById("test")
    if (x !== null) {
      x.click()
    }
  }

  const userid = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const [corateinfoId, setcorateinfoId] = useState('NIL')
  const getDataFromSelectedUserExtraInfo = async (id: any) => {
    const DataOfUser = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo/` + id,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    })
    let datacollected = DataOfUser.data.data[0]
    setCompanyName(datacollected.companyname)
    setcorateinfoId(datacollected.id)
    if(datacollected.logo != '') {
      setCorporateLogo('****' + datacollected.logo.substr(datacollected.logo.length - 4));
    } else {
      setCorporateLogo('');
    }
    return DataOfUser;
  }

  const getDataFromSelectedUser = async (id: any) => {
    const DataOfUser = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/` + id,
      headers: {
        "content-type": "application/json",
      }
    })
    let datacollected = DataOfUser.data;
    setMasterAccName(datacollected.name);
    setMasterAccEmail(datacollected.email);
    return DataOfUser;
  }

  const patchDatatoSelectedUserExtraInfo = async () => {
    const DataOfUser = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo/${corateinfoId}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: CoporateProfileForm
    })
    return DataOfUser;
  }

  const patchDatatoSelectedUser = async () => {
    let req;
    if(MasterAccPassword == "MASTER ACCOUNT MANAGER PASSWORD*" || MasterAccPassword == ""){
      req = {
        "name": MasterAccName,
      }
    } else {
      req = {
        "name": MasterAccName,
        "password": MasterAccPassword
      }
    }

    dispatch(userActions.updateuserbyid.started({user: req, userid: id}))

    /* const DataOfUser = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/${userid}`,
      headers: {
        "content-type": "application/json",
      },
      data: req
    })
    return DataOfUser; */
  }

  const [startOfPage, setstartOfPage] = useState(true)

  useEffect(() => {
    if (startOfPage === true) {
      getDataFromSelectedUser(userid)
      getDataFromSelectedUserExtraInfo(userid)
      setValues({
        password: password,
        showPassword: false
      })
      setMasterAccPassword(password)
      setstartOfPage(false)
    }
  })

    return (
        <BaseLayoutAdjustable bg={bg}>
        <div className={classes.topInfo}>
          <img src={logo} alt="logo" className={classes.logo} />
          <AccountInfoNew />
        </div>
        <div className={classes.mainContainer}>
          <div className="containerCoporateProfileSetup">
            <div className="row justify-content-center">
              <h3 className={classes.header}>CORPORATE PROFILE SETUP</h3>
            </div>
            {/* <button onClick={() => test()}>test</button> */}
            <div className="row justify-content-center pb-3">
              <h4 className={classes.requiredfield}>* INDICATES REQUIRED FIELD</h4>
            </div>
            <div className={classes.externalRow}>
              <div className={classes.row1}>
                      <div className={classes.inputWrapper}>
                        <img src={textbg} alt="bg" />
                        {/* <p className={classes.inputP}>{MasterAccName}</p> */}
                        <input autoComplete="off" value={MasterAccName} className={classes.input} placeholder="MASTER ACCOUNT MANAGER NAME" type="text" onChange={(e) => retrieveInfoFromInput('name', e.target.value)}/>
                      </div>
                      <div className={classes.inputWrapper}>
                        <img src={textbg} alt="bg" />
                        <p className={classes.inputP}>{MasterAccEmail}</p>
                        {/* <input className={classes.input} placeholder={MasterAccEmail} type="text" id="managerEmail" name="managerEmail" onChange={(e) => retrieveInfoFromInput('email', e.target.value)}/> */}
                      </div>
                      <div className={classes.PasswordinputWrapper}>
                        <img src={textbg} alt="TextfieldBG" />
                        <input
                          placeholder='PASSWORD'
                          type={values.showPassword ? 'text' : 'password'}
                          value={MasterAccPassword}
                          autoComplete="off"
                          className={classes.inputPass}
                          onChange={handleChange('password')}
                        />
                        <InputAdornment position="end" className={classes.togglebutton}>
                              <IconButton className={classes.InputAPass}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                          </InputAdornment>
                      </div>
              </div>
              <div className={classes.row1}>
                <div className={classes.inputWrapper}>
                  <img src={textbg} alt="bg" />
                  <input autoComplete="off" className={classes.input} placeholder="COMPANY NAME" value={CompanyName} type="text" id="CompanyName" name="CompanyName" onChange={(e) => retrieveInfoFromInput('co mpanyname', e.target.value)}/>
                </div>
                <div className={classes.inputWrapper}>
                    <img src={Upload} alt="Upload" className={classes.uploadImg} onClick={()=> imageclicked()}/>
                    <input autoComplete="off" type="file" id="test" name="upload" className={classes.inputButton} accept="image/png,image/jpeg,image/jpg" onChange={(e)=> OnChangeFileInput(e)}/>
                    <p className={classes.notes}>Image size: 500x500px Image Format: jpg, png{corporateLogo != '' ? <div className={classes.file}><p>&nbsp;| File: {corporateLogo}</p><button onClick={(e)=> RemoveImage()}>Remove</button></div> : null }</p>
                </div>
                <img src={textbg} alt="bg" className={classes.hidden}/>
              </div>
              </div>

              <div className={classes.pageBottom}>
                <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/overview'))}>
                  <img src={BackSymbol} alt="BackSymbol"/>
                  <p>BACK</p>
                </div>
                <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
                  <img src={SaveSymbol} alt="SaveSymbol" />
                  <p>SAVE</p>
                </div>
              </div>
          </div>
        </div>
        </BaseLayoutAdjustable>
    )
}

export default CorporateProfileSetup;
