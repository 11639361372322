import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/validation'

export const validationState = {
    status: 'idle',
    data: null,
}
export default {
    validation: reducerWithInitialState(validationState)
        .case(actions.createvalidation.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createvalidation.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.validation },
            })
        })
        .case(actions.createvalidation.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
    ,
}
