import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import textbg from 'assets/textfield.svg'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'

import 'assets/stylesheets/application.css';
import AccountInfoNew from 'components/AccountInfoNew'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import auth from 'actions/auth'
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'
import modal from 'actions/modal'

const useStyles = makeStyles(() => ({
  mainContainer: {

  },
  login: {
    width: '250%',
    marginTop: '30px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    cursor: 'pointer',
    '& img': {
      width: '250%'
    }
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    paddingBottom: '1rem',
    letterSpacing: '3px',
    fontSize: '2vh'
  },
  header: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  input: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    top: "0",
    left: '0',
    border: 'none',
    textAlign: 'center',
    height: '80px',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: "1.2rem",
    width: "100%",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: "100%"
  },
  inputWrapper1: {
    position: 'relative',
    width: "100%",
    '& img': {
      width: '100%'
    }
  },
  inputWrapperDisabled: {
    // display: 'none',
    opacity: '0',
    position: 'relative',
    width: "100%"
  },
  // text field password
  noBorder: {
    border: "none",
  },
  // InputA: {
  //   color: 'white',
  //   position: "relative",
  //   left: "19rem",
  //   bottom: "3px",
  // },
  // back button and save button style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  text: {
    fontFamily: "Impact",
    fontSize: "4vh",
    letterSpacing: "4px",
    paddingBottom: "2rem",
  },
  externalRow: {
    display: 'flex',
    position: "relative",
    height: "45vh",
    bottom: "0rem"
  },
  row1: {
    display: 'flex',
    flexDirection: 'column',
    width: "30rem",
    height: "22rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '100%'
    }
  },
  row2: {
    display: 'flex',
    flexDirection: 'column',
    width: "56rem",
    height: "5rem",
    alignItems: "center",
    '& input': {
      width: "100 %",
      position: "absolute",
      bottom: "0.3rem",
    },
  },
  internalRow1: {
    display: 'flex',
    flexDirection: 'column',
    width: "30rem",
    height: "22rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '100%'
    }
  },
  uploadImg: {
    width: '100%'
  },
  upload: {
    // display: 'flex',
    '& img': {
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    },
    '& input': {
      position: 'fixed'
    }
  },
  hidden: {
    opacity: '0'
  },
  // for password textfield
  inputPassword: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    top: '23%',
    left: '55%',
    transform: 'translate(-50%,0%)',
    width: '79%',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    fontSize: '1.7rem',
    textAlignLast: 'center',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperPassword: {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    lineHeight: '80px',
    padding: '0px 66px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  togglebutton: {
    position: 'absolute',
    right: '40px',
    top: '40px',
  },
  InputAPass: {
    color: 'white',
  },
  InputA: {
    color: 'white',
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const CourseManagerSetup = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  // textfield password
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  let word = "null"
  let code = '*'
  const handleChange = (prop: any) => (event: any) => {
    console.log(event.target.value)
    word = event.target.value
    setPasswordinput(event.target.value)
    setCourseManagerPasswordSymbol(code.repeat(word.length))
    setValues({ ...values, [prop]: event.target.value });
    console.log(CourseManagerPasswordSymbol)
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [LogoUpload = "empty", setLogoUpload] = useState();
  const [LogoUploadName, setLogoUploadName] = useState('Choose File');
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const registered = useSelector((state: any) => state.auth.registered)
  const registereduser = useSelector((state: any) => state.auth.registereduser)

  const [CourseManagerNameinput, setCourseManagerNameinput] = useState('empty');
  function CourseManagerName(input: any) {
    setCourseManagerNameinput(input)
  }
  const [Department, setDepartment] = useState('empty');
  function CourseManagerDepartment(input: any) {
    console.log(input)
    setDepartment(input)
  }
  const [Email, setEmail] = useState('empty');
  function CourseManagerEmail(input: any) {
    setEmail(input)
  }
  const [Roleinput, setRoleinput] = useState('empty');
  function CourseManagerRole(input: any) {
    setRoleinput(input)
  }
  const [CourseManagerPasswordinput, setPasswordinput] = useState('empty');
  const [CourseManagerPasswordSymbol, setCourseManagerPasswordSymbol] = useState('*');

  const sendManagerSetupData = () => {
    dispatch(auth.register.started({
      user: {
        "name": CourseManagerNameinput,
        "email": Email,
        "accounttype": "MANAGER",
        "password": CourseManagerPasswordinput,
        "status": true,
        "country": "",
        "contacts": "",
        "belongstoagent": "",
        "belongstouser": id
      },
      userinfo: {
        "department": Department,
        "role": Roleinput,
      }
    }))
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div>
      <div className={classes.mainContainer}>
        <div className="containerCoporateProfileSetup">
          <div className="row justify-content-center">
            <h3 className={classes.header}>COURSE MANAGER SETUP</h3>
          </div>
          <div className="row justify-content-center pb-3">
            <h4 className={classes.requiredfield}>* INDICATES REQUIRED FIELD</h4>
          </div>
          <div className={classes.externalRow}>
            <div className={classes.row1}>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                <input autoComplete="off" className={classes.input} placeholder="COURSE MANAGER NAME*" type="text"  onChange={(e) => CourseManagerName(e.target.value)} />
              </div>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                <input autoComplete="new-password" className={classes.input} placeholder="COURSE MANAGER EMAIL ID*" type="text"  onChange={(e) => CourseManagerEmail(e.target.value)}/>
              </div>
              <div className={classes.inputWrapperPassword}>
                <img src={textbg} alt="textbg" />
                <input
                  placeholder='PASSWORD'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  autoComplete="new-password"
                  className={classes.inputPass}
                  onChange={handleChange('password')}
                />
                <InputAdornment position="end" className={classes.togglebutton}>
                      <IconButton className={classes.InputAPass}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                </InputAdornment>
              </div>
            </div>
            <div className={classes.row1}>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                <input autoComplete="off" className={classes.input} placeholder="DEPARTMENT" type="text" onChange={(e) => CourseManagerDepartment(e.target.value)}/>
              </div>
              <div className={classes.inputWrapper}>
                <img src={textbg} alt="bg" />
                <input autoComplete="off" className={classes.input} placeholder="ROLE / TITLE" type="text" onChange={(e) => CourseManagerRole(e.target.value)}/>
              </div>
              <img src={textbg} alt="bg" className={classes.hidden}/>
            </div>
          </div>

          <div className={classes.pageBottom}>
            <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/coursemanagerlisting'))}>
              <img src={BackSymbol} alt="BackSymbol" />
              <p>BACK</p>
            </div>
            <div className={classes.saveBtn} onClick={() => sendManagerSetupData()}>
              <img src={SaveSymbol} alt="SaveSymbol" />
              <p>SAVE</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.tips} onClick={() => dispatch(modal.show.started({type: 'showTips', source: ''}))} >
        <img src={tips}/>
        <p>TIPS</p>
      </div>
      <div className={classes.tutorials} onClick={() => dispatch(push('/corporate/videotutorials'))} >
        <img src={tutorials}/>
        <p>TUTORIALS</p>
      </div>
      <div className={classes.manual} onClick={() => dispatch(push('/corporate/manual'))} >
        <img src={manual}/>
        <p>MANUAL</p>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default CourseManagerSetup;
