import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/props'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import { propsActions } from 'actions/propsActions'
import { dispatch } from 'rxjs/internal-compatibility'

export const createProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.props
        }).pipe(
            map(data => {
                return actions.createprops.done({
                    params: param.payload,
                    result: { props: data },
                })
            }),
            catchError(error =>
                Observable.of(actions.createprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const createInitialProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createinitialprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props/${param.payload.courseid}`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.createinitialprops.done({
                    params: param.payload,
                    result: { props: data },
                })
            }),
            catchError(error =>
                Observable.of(actions.createinitialprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getPropsByCourseId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getpropsbycourseid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props/${param.payload.courseid}/${param.payload.locationname}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getpropsbycourseid.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getpropsbycourseid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getPropsByCourseIdAndLocationId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getpropsbycourseidandlocationid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props/${param.payload.courseid}/${param.payload.locationname}/${param.payload.locationid}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            mergeMap(data => {
                const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

                let iframe: any = document.getElementById('PyramidIFrame');
                let sequence;
                let phonenum;
                let file;

                if(data.response.data[0].environment?.name == 'Pyramid'){
                    iframe = document.getElementById('PyramidIFrame');
                } else if (data.response.data[0].environment?.name == 'Chamber') {
                    iframe = document.getElementById('ChamberIFrame');
                } else if (data.response.data[0].environment?.name == 'Controlroom') {
                    iframe = document.getElementById('CommandIFrame');
                }

                if(data.response.data[0].file == null || data.response.data[0].file == ''){
                    file = '';
                } else {
                    file = '****' + data.response.data[0].file.substr(data.response.data[0].file.length - 4);
                }

                if(data.response.data[0].propid == 'APTP03'){
                    sequence = data.response.data[0].assignablevalue;
                    phonenum = '????';
                } else if (data.response.data[0].propid == 'APTP06') {
                    sequence = '????';
                    phonenum = data.response.data[0].assignablevalue;
                } else {
                    sequence = '????';
                    phonenum = '????';
                }

                if (isIFrame(iframe) && iframe.contentWindow) {
                    setTimeout(() => {
                        iframe.contentWindow.postMessage({
                            'assignableprops': data.response.data[0].propid
                        }, "*");
                    }, param.payload.timer);
                }

                return data.response.data[0].popuptype.name == 'Message' ? merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    }), propsActions.updateMessageTitle(data.response.data[0].popupvalue.split('|')[0]),
                        propsActions.updateMessageContent(data.response.data[0].popupvalue.split('|')[1]),
                        propsActions.locationType(data.response.data[0].location.locationtype),
                        propsActions.propsType(data.response.data[0].propstype.id),
                        propsActions.activeParam(data.response.data[0].popuptype.name),
                        propsActions.propsName(data.response.data[0].propid),
                        propsActions.file(file),
                    ),
                    of(
                        propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                ) : data.response.data[0].popuptype.name == 'Weblink' ? merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    })),
                    of(propsActions.updateWeblink(data.response.data[0].popupvalue)),
                    of(propsActions.locationType(data.response.data[0].location.locationtype)),
                    of(propsActions.propsType(data.response.data[0].propstype.id),
                        propsActions.activeParam(data.response.data[0].popuptype.name),
                        propsActions.propsName(data.response.data[0].propid),
                        propsActions.file(file),
                    ),
                    of(
                        propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                ) : data.response.data[0].popuptype.name == 'QuizText' ? merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    })),
                    of(propsActions.updateQuizQns(data.response.data[0].popupvalue.split('|')[0])),
                    of(propsActions.updateQuizText1(data.response.data[0].popupvalue.split('|')[1])),
                    of(propsActions.locationType(data.response.data[0].location.locationtype)),
                    of(propsActions.updateQuizText2(data.response.data[0].popupvalue.split('|')[2]),
                        propsActions.activeParam(data.response.data[0].popuptype.name),
                        propsActions.propsName(data.response.data[0].propid),
                        propsActions.propsType(data.response.data[0].propstype.id),
                        propsActions.file(file),
                    ),
                    of(propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                ) : data.response.data[0].popuptype.name == 'QuizChoice' ? merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    })),
                    of(propsActions.updateQuizChoiceQns(data.response.data[0].popupvalue.split('|')[0])),
                    of(propsActions.updateQuizChoiceA(data.response.data[0].popupvalue.split('|')[1]),
                        propsActions.updateQuizChoiceB(data.response.data[0].popupvalue.split('|')[2]),
                        propsActions.updateQuizChoiceC(data.response.data[0].popupvalue.split('|')[3]),
                        propsActions.updateQuizChoiceD(data.response.data[0].popupvalue.split('|')[4]),
                        propsActions.file(file)),
                    of(propsActions.updateQuizChoiceCorrectAns(data.response.data[0].popupvalue.split('|')[5])),
                    of(propsActions.locationType(data.response.data[0].location.locationtype),
                        propsActions.activeParam(data.response.data[0].popuptype.name),
                        propsActions.propsName(data.response.data[0].propid),
                        propsActions.propsType(data.response.data[0].propstype.id)),
                    of(propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                ) : data.response.data[0].popuptype.name == 'None' ? merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    })),
                    of(propsActions.locationType(data.response.data[0].location.locationtype),
                       propsActions.activeParam(data.response.data[0].popuptype.name),
                       propsActions.propsName(data.response.data[0].propid),
                       propsActions.propsType(data.response.data[0].propstype.id),
                       propsActions.file(file),
                    ),
                    of(
                        propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                ) : merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                    })),
                    of(propsActions.locationType(data.response.data[0].location.locationtype),
                       propsActions.activeParam(data.response.data[0].popuptype.name),
                       propsActions.propsName(data.response.data[0].propid),
                       propsActions.propsType(data.response.data[0].propstype.id),
                       propsActions.file(file),
                    ),
                    of(
                        propsActions.levelSequence1(sequence.split('')[0]),
                        propsActions.levelSequence2(sequence.split('')[1]),
                        propsActions.levelSequence3(sequence.split('')[2]),
                        propsActions.levelSequence4(sequence.split('')[3]),
                        propsActions.phoneNumber1(phonenum.split('')[0]),
                        propsActions.phoneNumber2(phonenum.split('')[1]),
                        propsActions.phoneNumber3(phonenum.split('')[2]),
                        propsActions.phoneNumber4(phonenum.split('')[3])
                    )
                );
            }),
            catchError(error =>
                Observable.of(actions.getpropsbycourseidandlocationid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props/${param.payload.propsid}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: param.payload.props
        }).pipe(
            map(data => {
                console.log('Edmund');
                console.log(data);
                return actions.updateprops.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deleteprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/props/${param.payload.propsid}`,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deleteprops.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deleteprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)
