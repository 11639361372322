import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';

// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'

import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import yellowBgLong from 'assets/BaseImg/yellowBgLong.png'
import darkBgLong from 'assets/BaseImg/darkBgLong.png'
import rightarrow from 'assets/doublewrrow-right.svg'
import downarrow from 'assets/doublearrow-down.svg'
import modal from 'actions/modal'

const useStyles = makeStyles(() => ({
  container1: {
    width: "70vw",
    height: "50vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  row1: {
    display: 'flex',
    width: '100%',
    '& img' : {
      width:'100%',
    }
  },
  overview: {
        width:'250%',
        marginTop:'10px',
        minWidth:'150px',
        maxWidth:'170px',
        margin:'0 auto',
        cursor:'pointer',
        '& img' : {
            width:'250%',
        }
    },
  overviewHidden: {
    width: '250%',
    marginTop: '10px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    opacity: 0,
    '& img': {
      width: '250%',
    }
  },
    headerBar : {
        padding:'40px 0px',
        display: 'flex',
        float:'right',
        color:'white',
        marginright:'20px',
        justifyContent: 'center',
        '& img': {
            height: '50%'
        },
        '& h4': {
            paddingRight: '6px',
            paddingTop: '9px',
            float:'right',
            fontFamily: 'impact',
        },
        '& h5': {
            fontFamily: 'impact'
        }
    },
  mainContainer: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    paddingBottom: '1rem',
    letterSpacing: '3px',
    fontSize: '2vh'
  },
  header: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  topInfo: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    justifyContent: "space-between",
  },
  logo: {
    width: '12rem'
  },
  containerOverViewData: {
    padding: "40px",
    marginLeft: '5rem',
    marginRight: '2rem',
    color: "white",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& h4": {
      fontFamily: "Impact",
      margin: "10px",
    },
    '& h5':{
        fontFamily: "Impact",
        letterSpacing: "1px",
        marginBottom: '0rem'
      },
      '& row': {
        fontFamily: "Impact",
      }
  },
  hidden: {
    opacity: 0
  },
  inputWrapper: {
    position: 'relative',
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      position: 'fixed',
      fontSize: '1.6vw',
      fontFamily: 'impact',
      letterSpacing: '1px',
      textAlign: 'center',
      lineHeight: '1.4em',
      margin: 0,
    },
    '& .moremenu' : {
        bottom: '0px',
        position: 'absolute',
        display: 'flex',
        width: '90%',
        justifyContent: 'space-around',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#d1b944',
        fontFamily: 'Impact',
        fontSize: '1.1vw',
        opacity: 0,
        pointerEvents: 'none',
        transition: '0.6s all ease',

        '&.active' : {
          bottom: '-20px',
          opacity: 1,
          pointerEvents: 'all',
        }
    },
    '& .arrow' : {
      top: '50%',
      right: '60px',
      position: 'absolute',
      transform: 'translateY(-50%)',
      transition: '0.6s transform ease',
      transformOrigin: 'center',

      '& img' : {
        width: '100%',
      }
    },

    '&.active' : {
      '& .arrow' : {
        transform: 'translateY(-50%) rotate(90deg)',
      },
    }
  },
  inputWrapper1: {
    position: 'relative',
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
      width: "21.5rem"
    }
  },
  inputWrapperHidden: {
    position: 'relative',
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    opacity: 0,
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
    }
  },
  temporary : {
    display: 'flex',
    flexDirection: 'column',
    width: "100vw",
    height: "85vh",
    justifyContent: "center",
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '40px',
    left: '80px',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  manual: {
    left: '15vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
}));

const Manual = () => {
    const dispatch = useDispatch();
    const classes  = useStyles();
    const editData = useSelector((state: any) => state.router.location.state);

    useEffect(() => {
      console.log(editData);
    }, []);

    function showMoreMenu(e: any) {
      let moremenus = document.querySelectorAll('.moremenu');
      let wrapper = document.querySelectorAll('.showmorebutton');
      moremenus.forEach((item: any) => {
        item.classList.remove('active');
      });
      wrapper.forEach((item: any) => {
        item.classList.remove('active');
      });
      e.currentTarget.classList.add('active');
      e.currentTarget.querySelector('.moremenu').classList.add('active');
    }

    return (
        <BaseLayoutAdjustable bg={bg}>
          <div className={classes.topInfo}>
            <img src={logo} alt="logo" className={classes.logo} />
            <AccountInfoNew />
          </div>
          <div className={classes.mainContainer}>
            <div className="row justify-content-center">
              <h3 className={classes.header}>MANUAL</h3>
            </div>
            <div className="row justify-content-center pb-3">
              <h4 className={classes.requiredfield}>SELECT A SECTION THAT YOU WISH TO LEARN MORE ABOUT</h4>
            </div>
            <div className={classes.container1}>
              <div className={classes.row1}>
                <div className={classes.inputWrapper} onClick={() => dispatch(modal.show.started({type: 'showProfileSetup', source: ''}))}>
                  <img src={yellowBgLong} alt="bg" />
                  <p>CORPORATE PROFILE SETUP</p>
                </div>
                <div className={`${classes.inputWrapper} showmorebutton`} onClick={(e) => showMoreMenu(e)}>
                  <img src={yellowBgLong} alt="bg" />
                  <p>COURSE TYPE MANAGEMENT</p>
                  <div className='arrow'><img src={rightarrow} /></div>
                  <div className="moremenu">
                    <div onClick={() => dispatch(modal.show.started({type: 'showAddCourseType', source: ''}))}>Add course type</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showEditCourseType', source: ''}))}>Edit course type</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showDeleteCourseType', source: ''}))}>Delete course type</div>
                  </div>
                </div>
              </div>
              <div className={classes.row1}>
                <div className={`${classes.inputWrapper} showmorebutton`} onClick={(e) => showMoreMenu(e)}>
                  <img src={yellowBgLong} alt="bg" />
                  <p>COURSE  MANAGEMENT</p>
                  <div className='arrow'><img src={rightarrow} /></div>
                  <div className="moremenu">
                    <div onClick={() => dispatch(modal.show.started({type: 'showAddCourse', source: ''}))}>Add course</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showEditCourse', source: ''}))}>Edit course</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showDeleteCourse', source: ''}))}>Delete course</div>
                  </div>
                </div>
                <div className={`${classes.inputWrapper} showmorebutton`} onClick={(e) => showMoreMenu(e)}>
                  <img src={yellowBgLong} alt="bg" />
                  <p>TRAINEE MANAGEMENT</p>
                  <div className='arrow'><img src={rightarrow} /></div>
                  <div className="moremenu">
                    <div onClick={() => dispatch(modal.show.started({type: 'showAddTrainee', source: ''}))}>Add trainee</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showEditTrainee', source: ''}))}>Edit trainee</div>
                    <div onClick={() => dispatch(modal.show.started({type: 'showDeleteTrainee', source: ''}))}>Delete trainee</div>
                  </div>
                </div>
              </div>
              <div className={classes.row1}>
                <div className={classes.inputWrapper} onClick={() => dispatch(modal.show.started({type: 'showPastTrainingRecord', source: ''}))}>
                  <img src={yellowBgLong} alt="bg" />
                  <p>PAST TRAINING SESSIONS<br />PERFORMANCE RECORD</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.backBtn} onClick={() => dispatch(goBack())}>
            <img src={BackSymbol} alt="BackSymbol" />
            <p>EXIT</p>
          </div>
        </BaseLayoutAdjustable>
    )
}

export default Manual;
