import { Observable } from 'rxjs'
import { Epic } from 'redux-observable'
import { ofAction } from 'typescript-fsa-redux-observable'
import { AnyAction } from 'typescript-fsa'
import modal from 'actions/modal'
import { debounceTime, delay, map, mergeMap } from 'rxjs/operators'

export const show: Epic<AnyAction> = (action$) => action$.pipe(
    ofAction(modal.show.started),
    map(data => {
        return modal.show.done({
            params: {
                type: data.payload.type,
                source: data.payload.source
            },
            result: { data: data.payload },
        })
    }),
)

export const hide: Epic<AnyAction> = (action$) => action$.pipe(
    ofAction(modal.hide.started),
    map(data => {
        return modal.hide.done({
            params: {},
            result: { data: data.payload },
        })
    }),
)