import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/traineecourse'

export const traineeCourseState = {
    status: 'idle',
    data: null,
    updated: false,
    selectedcourseid: '',
}
export default {
    traineecourse: reducerWithInitialState(traineeCourseState)
        .case(actions.createtraineecourse.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createtraineecourse.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.traineecourse },
            })
        })
        .case(actions.createtraineecourse.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.gettraineecoursebyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.gettraineecoursebyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.traineecourses },
            })
        })
        .case(actions.gettraineecoursebyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatetraineecoursebyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                updated: { $set: false },
            })
        })
        .case(actions.updatetraineecoursebyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                updated: { $set: true },
            })
        })
        .case(actions.updatetraineecoursebyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                updated: { $set: false },
            })
        })
        .case(actions.setselectedtraineecourse.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
            })
        })
        .case(actions.setselectedtraineecourse.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                selectedcourseid: { $set: action.result.courseid },
            })
        })
    ,
}
