import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateCourseParam { course: any }
interface CreateCourseResult { course: any }
interface CreateCourseError { error: string }

interface GetCourseParam { userid: string }
interface GetCourseResult { course: any }
interface GetCourseError { error: string }

interface UpdateCourseParam { courseid: string, course: any }
interface UpdateCourseResult { course: any }
interface UpdateCourseError { error: string }

interface DeleteCourseParam { courseids: string }
interface DeleteCourseResult { course: any }
interface DeleteCourseError { error: string }

export default {
    createcourse: ac.async<CreateCourseParam, CreateCourseResult, CreateCourseError>(ActionTypes.CREATE_COURSE),
    getcoursesbyuserid: ac.async<GetCourseParam, GetCourseResult, GetCourseError>(ActionTypes.GET_COURSEBYUSERID),
    updatecourse: ac.async<UpdateCourseParam, UpdateCourseResult, UpdateCourseError>(ActionTypes.UPDATE_COURSE),
    deletecourse: ac.async<DeleteCourseParam, DeleteCourseResult, DeleteCourseError>(ActionTypes.DELETE_COURSE),
}