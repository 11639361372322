import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/course'

export const courseState = {
    status: 'idle',
    data: null,
    deleted: false
}
export default {
    course: reducerWithInitialState(courseState)
        .case(actions.createcourse.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createcourse.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.course },
            })
        })
        .case(actions.createcourse.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursesbyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursesbyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.course },
            })
        })
        .case(actions.getcoursesbyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatecourse.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updatecourse.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.course },
            })
        })
        .case(actions.getcoursesbyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deletecourse.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.deletecourse.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.deletecourse.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
    ,
}
