import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles, rgbToHex } from '@material-ui/core/styles';

import Logout from 'assets/CorporateProfileSetup/Logout.png'
import Avatar from 'assets/CoporateUserOverView/ProfileAvatar.png'
import Anchor from 'assets/CoporateUserOverView/Anchor.png'

import auth from 'actions/auth'

const useStyles = makeStyles(() => ({

  logo: {
    width: '200px',
    float: 'right',
    '& img': {
      width: '100%',
    }
  },
  headerBar: {
    padding: '40px 0px',
    display: 'flex',
    float: 'right',
    color: 'white',
    marginright: '20px',
    '& img': {
      padding: '6px',
      float: 'right',
      width: '36px',
    },
    '& h2': {
      paddingRight: '6px',
      paddingTop: '9px',
      float: 'right',
      fontFamily: 'impact',
      fontSize: '1.2rem'
    }
  },
  divider: {
    padding: '10px 6px !important',
    width: '15px !important',
  },
  logout: {
    color: '#d4b943',
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontFamily: 'Impact',
    lineHeight: '38px',
    padding: '0px 4px',
    letterSpacing: '1px',
  }

}));


const AccountInfoUser = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const name = useSelector((state: any) => state.corporateReducer.displayName)

  return (
    <div className={classes.headerBar}>
      <div>
        <h2>{name}</h2>
      </div>
      <div>
        <img src={Avatar} alt="Avatar" />
      </div>
      <div>
        <img className={classes.divider} src={Anchor} alt="Anchor" />
      </div>
      <div>
        {/* <div onClick={() => dispatch(push('/'))}> */}
        <div className={classes.logout} onClick={() => dispatch(auth.logout.started({}))}>
          LOGOUT
        </div>
      </div>
    </div>
  )
}




export default AccountInfoUser;
