import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateEnvironmentParam { environment: any }
interface CreateEnvironmentResult { environment: any }
interface CreateEnvironmentError { error: string }

interface GetEnvironmentParam { escaperoomid: string }
interface GetEnvironmentResult { environment: any }
interface GetEnvironmentError { error: string }

interface UpdateEnvironmentParam { environmentid: string, environment: any }
interface UpdateEnvironmentResult { environment: any }
interface UpdateEnvironmentError { error: string }

interface DeleteEnvironmentParam { environmentid: string }
interface DeleteEnvironmentResult { environment: any }
interface DeleteEnvironmentError { error: string }

export default {
    createenvironment: ac.async<CreateEnvironmentParam, CreateEnvironmentResult, CreateEnvironmentError>(ActionTypes.CREATE_ENVIRONMENT),
    getenvironmentbyescaperoomid: ac.async<GetEnvironmentParam, GetEnvironmentResult, GetEnvironmentError>(ActionTypes.GET_ENVIRONMENTBYESCAPEROOMID),
    updateenvironment: ac.async<UpdateEnvironmentParam, UpdateEnvironmentResult, UpdateEnvironmentError>(ActionTypes.UPDATE_ENVIRONMENT),
    deleteenvironment: ac.async<DeleteEnvironmentParam, DeleteEnvironmentResult, DeleteEnvironmentError>(ActionTypes.DELETE_ENVIRONMENT),
}