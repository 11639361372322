import { merge, Observable } from 'rxjs'
import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/auth'
import modal from 'actions/modal'
import traineeinfo from 'actions/traineeinfo';
import { corporatePageActions } from 'actions/coporatePageActions'
import { ajax } from 'rxjs/ajax'
import { catchError, map, mergeMap, startWith } from 'rxjs/operators'
import { switchMap } from 'rxjs-compat/operator/switchMap'
import { Param } from '@nestjs/common'
import { useParams } from 'react-router'
import corporateinfo from 'actions/corporateinfo';
import agentinfo from 'actions/agentinfo';
import coursemanagerinfo from 'actions/coursemanagerinfo';
import traineecourse from 'actions/traineecourse';
import axios from 'axios';


export const registerUser: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.register.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/auth/register`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: param.payload.user
        }).pipe(
            mergeMap(data => {
                if(data.response.accounttype == "TRAINEE"){
                    axios.post('https://www.createscape.com.sg/login/mailer.php', {email: param.payload.user.email, name: param.payload.user.name , form: 'register', password: param.payload.user.password, sessions: param.payload.userinfo.validsessions, expiration: param.payload.userinfo.enddate }, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    }).then(data => {
                    }
                    ).catch(err => {
                        console.log(err)
                        return null
                    });
                }
                return data.response.accounttype == "AGENT" ? merge(
                    of(agentinfo.createagentinfo.started({agentinfo: {
                        "country": param.payload.userinfo.country,
                        "contacts": param.payload.userinfo.contacts,
                        "user": data.response.id
                    }})),
                    of(modal.show.started({type: 'saveAgentSetup', source: ''}))
                ) : data.response.accounttype == "CORPORATE" ? merge(
                    of(corporateinfo.createcorporateinfo.started({corporateinfo: {
                        "companyname": param.payload.userinfo.companyname,
                        "validsessions": param.payload.userinfo.validsessions,
                        "agentname": param.payload.userinfo.agentname,
                        "corporatetype": param.payload.userinfo.corporatetype,
                        "startdate": param.payload.userinfo.startdate,
                        "enddate": param.payload.userinfo.enddate,
                        "user": data.response.id
                    }})),
                    of(modal.show.started({type: 'saveCorporateSetup', source: ''}))
                ) : data.response.accounttype == "MANAGER" ? merge(
                    of(coursemanagerinfo.createcoursemanagerinfo.started({coursemanagerinfo: {
                        "department": param.payload.userinfo.department,
                        "role": param.payload.userinfo.role,
                        "user": data.response.id
                    }})),
                    of(modal.show.started({type: 'saveCourseManagerSetup', source: ''}))
                ) : merge(
                    of(traineeinfo.createtraineeinfo.started({traineeinfo: {
                        "dateofbirth": param.payload.userinfo.dateofbirth,
                        "startdate": param.payload.userinfo.startdate,
                        "enddate": param.payload.userinfo.enddate,
                        "validsessions": param.payload.userinfo.validsessions,
                        "groupname": param.payload.userinfo.groupname,
                        "user": data.response.id
                    }})),
                    of(traineecourse.createtraineecourse.started({traineecourse: {name: param.payload.userinfo.assignedcoursename, course: param.payload.userinfo.assignedcourseid, user: data.response.id}})),
                    of(modal.show.started({type: 'saveTraineeSetup', source: ''}))
                );
            }),
            catchError(error => {
                if(error.response.message == 'error.unique.email') {
                    return of(modal.show.started({type: 'conflictAccount', source: ''}), actions.register.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                } else {
                    return of(actions.register.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                }
            }),
        ),
    ),
)

export const login: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.login.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload
        }).pipe(
            mergeMap(data => {
                console.log(data);
                if(data.response.user.accounttype != 'ADMINISTRATOR' || data.response.user.accounttype != 'AGENT'){
                    if(data.response.user.accounttype == 'CORPORATE'){
                        /* if (data.response.user.status == false || new Date(data.response.user.corporateinfo.enddate) < new Date(param.payload.servertime)) {
                            return of(modal.show.started({type: 'wrongCredentials', source: ''}))
                        } */
                    }
                    if(data.response.user.accounttype == 'MANAGER'){
                        if (data.response.user.status == false || new Date(data.response.user.coursemanagerinfo.enddate) < new Date(param.payload.servertime)) {
                            return of(modal.show.started({type: 'wrongCredentials', source: ''}))
                        }
                    }
                    if(data.response.user.accounttype == 'TRAINEE'){
                        if (data.response.user.status == false || new Date(data.response.user.traineeinfo.enddate) < new Date(param.payload.servertime)) {
                            return of(modal.show.started({type: 'wrongCredentials', source: ''}))
                        }
                    }
                }
                return of(corporatePageActions.updateDisplayName(data.response.user.name), actions.login.done({
                    params: param.payload,
                    result: { loggedinuser: data.response },
                }));
            }),
            catchError(error => {
                if(error.response.message == 'error.user_not_found') {
                    return of(modal.show.started({type: 'wrongCredentials', source: ''}), actions.login.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                } else {
                    return of(actions.login.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                }
            }),
        ),
    ),
)

export const updateSession: Epic<AnyAction> = (action$) => action$.pipe(
    ofAction(actions.updatesession.started),
    map(data => {
        return actions.updatesession.done({
            params: data.payload,
            result: { numberofsession: data.payload.numberofsession },
        })
    }),
)

export const authClear: Epic<AnyAction> = (action$) => action$.pipe(
    ofAction(actions.authclear.started),
    map(data => {
        return actions.authclear.done({
            params: {},
            result: { data: data.payload },
        })
    }),
)

export const logout: Epic<AnyAction> = (action$) => action$.pipe(
    ofAction(actions.logout.started),
    map(data => {
        return actions.logout.done({
            params: {},
            result: { data: data.payload },
        })
    }),
)