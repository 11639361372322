import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import 'assets/stylesheets/swiper.min.css'
//import "swiper/components/pagination/pagination.min.css";
import message from 'assets/platform/galleryBoard/galleryBoardSlider/message.svg'
import messageActive from 'assets/platform/galleryBoard/galleryBoardSlider/message-active.svg'
import noneNew from 'assets/platform/galleryBoard/galleryBoardSlider/none.svg'
import noneActive from 'assets/platform/galleryBoard/galleryBoardSlider/none-active.svg'
import pdfNew from 'assets/platform/galleryBoard/galleryBoardSlider/img.svg'
import pdfActive from 'assets/platform/galleryBoard/galleryBoardSlider/img-active.svg'
import quizChoiceNew from 'assets/platform/galleryBoard/galleryBoardSlider/quizchoice.svg'
import quizChoiceActiveNew from 'assets/platform/galleryBoard/galleryBoardSlider/quizchoice-active.svg'
import quizTextNew from 'assets/platform/galleryBoard/galleryBoardSlider/quiztext.svg'
import quizTextActiveNew from 'assets/platform/galleryBoard/galleryBoardSlider/quiztext-active.svg'
import url from 'assets/platform/galleryBoard/galleryBoardSlider/weblink.svg'
import urlActive from 'assets/platform/galleryBoard/galleryBoardSlider/weblink-active.svg'
import next from 'assets/platform/prev.png'
import prev from 'assets/platform/next.png'

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";
import zIndex from "@material-ui/core/styles/zIndex";
import { propsActions } from "actions/propsActions";
import { relative } from "path";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const useStyles = makeStyles((theme) => ({
  paramType: {
    cursor: 'pointer',
    paddingBottom: '1rem',
  },
  paramTypeC: {
    cursor: 'pointer',
  },
  paramTypeI: {
    cursor: 'pointer',
  },
  paramTypeW: {
    cursor: 'pointer',
  },
  activeValue: {
    display: 'flex',
    flexDirection: 'row',
  },
  paramContent: {
    width: '100%',
    marginTop: '5%',
  },
  paramMessage: {
    padding: '5% 6%',
    border: '1px solid #fff',
    height: '200px',
    "&>p": {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
    "& textarea": {
      background: 'transparent',
      width: '100%',
      height: '80%',
      color: '#fff',
      fontSize: 15,
      lineHeight: '1.5',
    }
  },
  paramWeblink: {
    "& > p": {
      color: theme.palette.primary.main,
    },
    "& > input": {
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      width: '100%',
      height: '40px',
      color: '#fff',
      paddingLeft: 15
    }
  },
  mySwiper: {
    width: '100%',
    height: '12vh',
    position: 'relative',
    marginLeft: '0',
    marginRight: '0',
  },
  slideName: {
    backgroundColor: '#404040',
    color: '#797979',
    textAlign: 'center',
    fontSize: '1.4vh',
    width: "100%",
    cursor: 'pointer',

    "& img": {
      width: '49px',
      cursor: 'pointer',
      height: '100%',
    }
  },
  slideNameActive: {
    backgroundColor: '#FDD700',
    color: '#151514',
    textAlign: 'center',
    fontSize: '1.4vh',
    width: "100%",

    "& img": {
      width: '49px',
      height: '100%',
    }
  },
  slideNameCasing: {
    backgroundColor: '#FDD700',
    // width: '4rem'
  },
  SlideBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',

    "& > div": {
      backgroundColor: 'transparent',
      alignItems: 'center',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },

    "& img": {
      width: '49px',
      cursor: 'pointer',
    }
  },
  ActiveBorder: {
    width: '4vw',
    zIndex: -1,
  },
  swiperSlide: {
   /*  display: 'flex',
    alignItems: 'flex-end',
    minWidth: '70px', */
  },
  nextarrow: {
    position: 'absolute',
    color: '#fff',
    zIndex: 1,
    right: '-6px',
    top: '26px',
    cursor: 'pointer',
    width: '20px',

    "& img": {
      width: '100%',
      height: 'auto',
    },

    '&.swiper-button-disabled': {
      opacity: '0.4',
    }
  },
  prevarrow: {
    position: 'absolute',
    color: '#fff',
    zIndex: 1,
    left: '-6px',
    top: '26px',
    cursor: 'pointer',
    width: '20px',

    "& img": {
      width: '100%',
      height: 'auto',
    },

    '&.swiper-button-disabled': {
      opacity: '0.4',
    }
  },
  swiperwrapper: {
    position: 'relative',
  }
}));

export default function IconSwiperGallery() {
  // const swiperRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeParam = useSelector((state: any) => state.propsReducer.activeParam)
  const paramType = (type = "None") => {
    dispatch(propsActions.activeParam(type))
  }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    // { SwiperItem('none')}
    <div className={classes.swiperwrapper}>
      <Swiper
        // ref={swiperRef}
        slidesPerView={3}
        slidesPerGroup={3}
        centeredSlides={false}
        spaceBetween={8}
        onBeforeInit={(swiper: any) => {
          // Override prevEl & nextEl now that refs are defined
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current

          // Re-init navigation
          swiper.navigation.destroy()
          swiper.navigation.init()
          swiper.navigation.update()
          swiper.update()
        }}
        className={classes.mySwiper}
      >
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'None')
              ?
              <div>
                <div className={classes.activeValue}>
                  {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={noneActive} alt="noneActive" />
                </div>
                {/* <img src={noneNew} alt="noneNew" className={classes.paramType} /> */}
                <p className={classes.slideNameActive}>NONE</p>
              </div>
              :
              <div>
                <div>
                  <img src={noneNew} alt="noneNew" onClick={() => paramType('None')} />
                </div>
                  <p className={classes.slideName}>NONE</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'Message') ?
            <div>
              <div className={classes.activeValue}>
                {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={messageActive} alt="messageActive" />
              </div>
                <p className={classes.slideNameActive}>MESSAGE</p>
            </div>
              :
              <div>
                <img src={message} alt="message" onClick={() => paramType('Message')} />
                  <p className={classes.slideName}>MESSAGE</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'Image') ?
            <div>
              <div className={classes.activeValue}>
                {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={pdfActive} alt="pdfActive" />
              </div>
                <p className={classes.slideNameActive}>IMAGE</p>
            </div>
              :
              <div>
                <img src={pdfNew} alt="pdfNew" onClick={() => paramType('Image')} />
                  <p className={classes.slideName}>IMAGE</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'Weblink') ?
            <div>
              <div className={classes.activeValue}>
                {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={urlActive} alt="urlActive" />
              </div>
                <p className={classes.slideNameActive}>WEBLINK</p>
            </div>
              :
              <div>
                <img src={url} alt="url" onClick={() => paramType('Weblink')} />
                  <p className={classes.slideName}>WEBLINK</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'QuizText') ?
            <div>
              <div className={classes.activeValue}>
                {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={quizTextActiveNew} alt="quizTextNew" />
              </div>
                <p className={classes.slideNameActive}>QUIZ(TEXT)</p>
            </div>
              :
              <div>
                <img src={quizTextNew} alt="quizText" onClick={() => paramType('QuizText')} />
                <p className={classes.slideName}>QUIZ(TEXT)</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <SwiperSlide className={classes.swiperSlide}>
          <div className={classes.SlideBox}>
            {(activeParam === 'QuizChoice') ?
              <div>
                <div className={classes.activeValue}>
                  {/* <img src={selectionBorder} alt="selectionBorder" className={classes.ActiveBorder} /> */}
                  <img src={quizChoiceActiveNew} alt="quizChoiceActiveNew" />
                </div>
                <p className={classes.slideNameActive}>QUIZ(CHOICE)</p>
              </div>
              :
              <div>
                <img src={quizChoiceNew} alt="quizChoice" onClick={() => paramType('QuizChoice')} />
                <p className={classes.slideName}>QUIZ(CHOICE)</p>
              </div>
            }
          </div>
        </SwiperSlide>
        <div className={classes.prevarrow} ref={navigationPrevRef}><img src={next} /></div>
        <div className={classes.nextarrow} ref={navigationNextRef}><img src={prev} /></div>
      </Swiper>
    </div>
  );
}