import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateCorporateInfoParam { corporateinfo: any }
interface CreateCorporateInfoResult { corporateinfo: any }
interface CreateCorporateInfoError { error: string }

interface GetCorporateInfoParam { userid: string }
interface GetCorporateInfoResult { corporateinfo: any }
interface GetCorporateInfoError { error: string }

interface UpdateCorporateInfoParam { userid: string, corporateinfo: any }
interface UpdateCorporateInfoResult { corporateinfo: any }
interface UpdateCorporateInfoError { error: string }

interface DeductCorporateInfoSessionParam { userid: string, numberofsession: any, callbackparams: any }
interface DeductCorporateInfoSessionResult { corporateinfo: any }
interface DeductCorporateInfoSessionError { error: string }

export default {
    createcorporateinfo: ac.async<CreateCorporateInfoParam, CreateCorporateInfoResult, CreateCorporateInfoError>(ActionTypes.CREATE_CORPORATEINFO),
    getcorporateinfobyuserid: ac.async<GetCorporateInfoParam, GetCorporateInfoResult, GetCorporateInfoError>(ActionTypes.GET_CORPORATEINFOBYUSERID),
    updatecorporateinfobyid: ac.async<UpdateCorporateInfoParam, UpdateCorporateInfoResult, UpdateCorporateInfoError>(ActionTypes.UPDATE_CORPORATEINFOBYUSERID),
    deductsession: ac.async<DeductCorporateInfoSessionParam, DeductCorporateInfoSessionResult, DeductCorporateInfoSessionError>(ActionTypes.DEDUCT_CORPORATESESSION),
}