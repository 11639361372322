import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateTraineeInfoParam { traineeinfo: any }
interface CreateTraineeInfoResult { traineeinfo: any }
interface CreateTraineeInfoError { error: string }

interface GetTraineeInfoParam { userid: string }
interface GetTraineeInfoResult { traineeinfo: any }
interface GetTraineeInfoError { error: string }

interface UpdateTraineeInfoParam { userid: string, traineeinfo: any }
interface UpdateTraineeInfoResult { traineeinfo: any }
interface UpdateTraineeInfoError { error: string }

export default {
    createtraineeinfo: ac.async<CreateTraineeInfoParam, CreateTraineeInfoResult, CreateTraineeInfoError>(ActionTypes.CREATE_TRAINEEINFO),
    gettraineeinfobyuserid: ac.async<GetTraineeInfoParam, GetTraineeInfoResult, GetTraineeInfoError>(ActionTypes.GET_TRAINEEINFOBYUSERID),
    updatetraineeinfobyuserid: ac.async<UpdateTraineeInfoParam, UpdateTraineeInfoResult, UpdateTraineeInfoError>(ActionTypes.UPDATE_TRAINEEINFOBYUSERID),
}