import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/accountpackage'

export const accountPackageState = {
    status: 'idle',
    data: null,
    deleted: false
}
export default {
    accountpackage: reducerWithInitialState(accountPackageState)
        .case(actions.createaccountpackage.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createaccountpackage.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.accountpackage },
            })
        })
        .case(actions.createaccountpackage.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getaccountpackagebyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getaccountpackagebyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.accountpackage },
            })
        })
        .case(actions.getaccountpackagebyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updateaccountpackagebyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updateaccountpackagebyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.accountpackage },
            })
        })
        .case(actions.updateaccountpackagebyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
    ,
}
