import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/auth'
import { register } from 'serviceWorker'

export const userState = {
    isAuthenticated: false,
    status: 'idle',
    registered: false,
    registereduser: null,
    deleted: false,
    numberofsession: 0,
    error: '',
    loggedinuser: null,
}

export default {
    auth: reducerWithInitialState(userState)
        .case(actions.login.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                error: { $set: '' },
            })
        })
        .case(actions.login.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                isAuthenticated: { $set: true },
                loggedinuser: { $set: action.result.loggedinuser },
                numberofsession: { $set: action.result.loggedinuser.user.corporateinfo != null ? action.result.loggedinuser.user.corporateinfo.validsessions : 0 }
            })
        })
        .case(actions.login.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                isAuthenticated: { $set: false },
                error: { $set: action.error.error },
            })
        })
        .case(actions.logout.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
            })
        })
        .case(actions.logout.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                isAuthenticated: { $set: false },
            })
        })
        .case(actions.authclear.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
            })
        })
        .case(actions.authclear.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                registered: { $set: false },
                deleted: { $set: false },
                error: { $set: '' },
            })
        })
        .case(actions.authclear.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
            })
        })
        .case(actions.register.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                registered: { $set: false },
                error: { $set: '' },
            })
        })
        .case(actions.register.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                registered: { $set: true },
                registereduser: { $set: action.result.registereduser }
            })
        })
        .case(actions.register.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                registered: { $set: false },
                error: { $set: action.error.error },
            })
        })
        .case(actions.updatesession.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
            })
        })
        .case(actions.updatesession.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                numberofsession: { $set: action.result.numberofsession }
            })
        })
        .case(actions.updatesession.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
            })
        })
    ,
}
