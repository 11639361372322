import React, { useEffect } from 'react'

const Props = () => {
   return(
     <h1>
       This is props
     </h1>
   )

}

export default Props
