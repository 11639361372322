import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/coursetype'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import { corporatePageActions } from 'actions/coporatePageActions'

export const createCourseType: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createcoursetype.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursetype`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.coursetype
        }).pipe(
            map(data => {
                return actions.createcoursetype.done({
                    params: param.payload,
                    result: { coursetype: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createcoursetype.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getCourseTypById: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getcoursetypebyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursetype/${param.payload.userid}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            /* map(data => {
                return actions.getcoursetypebyuserid.done({
                    params: param.payload,
                    result: { coursetype: data.response },
                })
            }), */
            mergeMap(data => {
                return merge(
                    of(actions.getcoursetypebyuserid.done({
                        params: param.payload,
                        result: { coursetype: data.response },
                    })),
                    of(corporatePageActions.hasCoursesTypes(data.response.data.length))
                )
            }),
            catchError(error =>
                Observable.of(actions.getcoursetypebyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateCourseType: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatecoursetype.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursetype/${param.payload.coursetypeid}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.coursetype
        }).pipe(
            map(data => {
                return actions.updatecoursetype.done({
                    params: param.payload,
                    result: { coursetype: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updatecoursetype.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteCourseType: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deletecoursetype.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursetype/${param.payload.coursetypeid}`,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deletecoursetype.done({
                    params: param.payload,
                    result: { coursetype: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deletecoursetype.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)