import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface GetGameresultParam { userid: string, order: string }
interface GetGameresultResult { gameresult: any }
interface GetGameresultError { error: string }

interface GetGameresultCorporateParam { corporateid: string, order: string }
interface GetGameresultCorporateResult { gameresult: any }
interface GetGameresultCorporateError { error: string }

interface DeleteGameresultParam { gameresultids: string }
interface DeleteGameresultResult { gameresult: any }
interface DeleteGameresultError { error: string }

export default {
    getgameresultbyuserid: ac.async<GetGameresultParam, GetGameresultResult, GetGameresultError>(ActionTypes.GET_GAMERESULTBYUSERID),
    getgameresultbycorporateid: ac.async<GetGameresultCorporateParam, GetGameresultCorporateResult, GetGameresultCorporateError>(ActionTypes.GET_GAMERESULTBYCORPORATEID),
    deletegameresult: ac.async<DeleteGameresultParam, DeleteGameresultResult, DeleteGameresultError>(ActionTypes.DELETE_GAMERESULT),
}