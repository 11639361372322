import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/corporateinfo'

export const corporateInfoState = {
    status: 'idle',
    data: null,
    deleted: false,
    error: {}
}

export default {
    corporateinfo: reducerWithInitialState(corporateInfoState)
        .case(actions.createcorporateinfo.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createcorporateinfo.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.corporateinfo },
            })
        })
        .case(actions.createcorporateinfo.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getcorporateinfobyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getcorporateinfobyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.corporateinfo },
            })
        })
        .case(actions.getcorporateinfobyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatecorporateinfobyid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updatecorporateinfobyid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.corporateinfo },
            })
        })
        .case(actions.updatecorporateinfobyid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deductsession.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
                error: { $set: '' },
            })
        })
        .case(actions.deductsession.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.corporateinfo },
                error: { $set: '' },
            })
        })
        .case(actions.deductsession.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
                error: { $set: action.error },
            })
        })
    ,
}
