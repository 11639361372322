import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateCourseManagerInfoParam { coursemanagerinfo: any }
interface CreateCourseManagerInfoResult { coursemanagerinfo: any }
interface CreateCourseManagerInfoError { error: string }

interface GetCourseManagerInfoParam { userid: string }
interface GetCourseManagerInfoResult { coursemanagerinfo: any }
interface GetCourseManagerInfoError { error: string }

interface UpdateCourseManagerInfoParam { userid: string, coursemanagerinfo: any }
interface UpdateCourseManagerInfoResult { coursemanagerinfo: any }
interface UpdateCourseManagerInfoError { error: string }

export default {
    createcoursemanagerinfo: ac.async<CreateCourseManagerInfoParam, CreateCourseManagerInfoResult, CreateCourseManagerInfoError>(ActionTypes.CREATE_COURSEMANAGERINFO),
    getcoursemanagerinfobyuserid: ac.async<GetCourseManagerInfoParam, GetCourseManagerInfoResult, GetCourseManagerInfoError>(ActionTypes.GET_COURSEMANAGERINFOBYUSERID),
    updatecoursemanagerinfobyuserid: ac.async<UpdateCourseManagerInfoParam, UpdateCourseManagerInfoResult, UpdateCourseManagerInfoError>(ActionTypes.UPDATE_COURSEMANAGERINFOBYUSERID),
}