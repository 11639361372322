import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/coursetype'

export const courseTypeState = {
    status: 'idle',
    data: null,
    deleted: false
}

export default {
    coursetype: reducerWithInitialState(courseTypeState)
        .case(actions.createcoursetype.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createcoursetype.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursetype },
            })
        })
        .case(actions.createcoursetype.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursetypebyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursetypebyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursetype },
            })
        })
        .case(actions.getcoursetypebyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatecoursetype.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updatecoursetype.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursetype },
            })
        })
        .case(actions.updatecoursetype.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deletecoursetype.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.deletecoursetype.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.deletecoursetype.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
    ,
}
