import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/coursemanagerinfo'

export const courseManagerInfoState = {
    status: 'idle',
    data: null
}

export default {
    coursemanagerinfo: reducerWithInitialState(courseManagerInfoState)
        .case(actions.createcoursemanagerinfo.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createcoursemanagerinfo.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursemanagerinfo },
            })
        })
        .case(actions.createcoursemanagerinfo.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursemanagerinfobyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getcoursemanagerinfobyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursemanagerinfo },
            })
        })
        .case(actions.getcoursemanagerinfobyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatecoursemanagerinfobyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updatecoursemanagerinfobyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.coursemanagerinfo },
            })
        })
        .case(actions.updatecoursemanagerinfobyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
    ,
}
