import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/escaperoom'

export const escaperoomState = {
    status: 'idle',
    data: null,
    deleted: false,
}
export default {
    escaperoom: reducerWithInitialState(escaperoomState)
        .case(actions.createescaperoom.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createescaperoom.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.escaperoom },
            })
        })
        .case(actions.createescaperoom.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getescaperoombycourseid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getescaperoombycourseid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.escaperoom },
            })
        })
        .case(actions.getescaperoombycourseid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updateescaperoom.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updateescaperoom.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.escaperoom },
            })
        })
        .case(actions.updateescaperoom.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deleteescaperoom.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.deleteescaperoom.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.deleteescaperoom.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
    ,
}
