import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateEmailValidationParam { emailvalidation: any }
interface CreateEmailValidationResult { emailvalidation: any }
interface CreateEmailValidationError { error: string }

interface GetEmailValidationParam { id: any }
interface GetEmailValidationResult { emailvalidation: any }
interface GetEmailValidationError { error: string }

interface DeleteEmailValidationParam { id: any }
interface DeleteEmailValidationResult { emailvalidation: any }
interface DeleteEmailValidationError { error: string }

export default {
    createemailvalidation: ac.async<CreateEmailValidationParam, CreateEmailValidationResult, CreateEmailValidationError>(ActionTypes.CREATE_EMAILVALIDATION),
    getemailvalidation: ac.async<GetEmailValidationParam, GetEmailValidationResult, GetEmailValidationError>(ActionTypes.GET_EMAILVALIDATION),
    deleteemailvalidation: ac.async<DeleteEmailValidationParam, DeleteEmailValidationResult, DeleteEmailValidationError>(ActionTypes.DELETE_EMAILVALIDATION),
}