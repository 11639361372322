import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseLayoutAdjustable from '../components/form/BaseLayoutGallery'
import bg from 'assets/PlatformHome/AuthoringPlatformBG1.png'
import logo from 'assets/logo.png'
import { makeStyles } from '@material-ui/core/styles';
import PropsBox from 'components/PropBox/PropBox'
import Iframe from 'react-iframe';
import AccountInfoNew from 'components/AccountInfoNew';
import { propsActions } from 'actions/propsActions';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '40px'
  },
  iframeStyle : {
      width: '100%',
      height: '100vh',
      border: 'none',
      position: 'absolute',
      top: 0,
      zIndex: -1
  },
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '200px',
    float: 'left',
    '& img': {
      width: '100%',
    }
  },
  headerBar: {
    color: 'white',
    float: 'left',
    marginRight: 'auto',
    marginTop: '12px',
    marginLeft: '15px',
    '& p': {
      padding: '6px',
      float: 'left',
      textTransform: 'uppercase',
      fontFamily: 'Impact',
    }
  },
  btnSave: {
    marginLeft: '30px',
    position: 'fixed',
    left: '0px',
    bottom: 30,
    cursor: 'pointer',
  },
  nextPrevious: {
    width: '21vh'
  },
}));

const PlatformChamber = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [currentPropsIndex, setCurrentPropsIndex] = useState(1);
  const [propsBoxShown, setPropsBoxShown] = useState(false);
  const [totalPropCount, setTotalPropCount] = useState(1);
  const [selectedPropId, setSelectedPropId] = useState(1);
  const editData = useSelector((state: any) => state.router.location.state)

  function ModelLoadedGallery() {
    window.removeEventListener("message", getMessageGallery);
    window.addEventListener("message", getMessageGallery);
  }

  function getMessageGallery(message: any) {
    let ids = ['ECR1-HS-08', 'ECR1-HS-09', 'ECR1C-AH01', 'ECR1C-AH02', 'ECR1C-AH03'];
    if(message.data.location != undefined){
        setPropsBoxShown(true);
        setSelectedPropId(message.data.location);
        setCurrentPropsIndex(ids.indexOf(message.data.location) + 1);
        setTotalPropCount(ids.length);
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
        <div className={classes.topInfo}>
          <img src={logo} alt="logo" className={classes.logo} />
          <div className={classes.headerBar}>
            <p>{editData?.course?.name }</p>
            <p>|</p>
            <p>{editData?.course?.escaperoom?.name }</p>
            <p>|</p>
            <p>CHAMBER</p>
          </div>
          <AccountInfoNew />
        </div>
      <Iframe
          url='https://www.createscape.com.sg/login/games/authoring/chamber/index.html'
          className={classes.iframeStyle}
          onLoad={ModelLoadedGallery}
          id='ChamberIFrame'
      />
      <PropsBox propsBoxShown={propsBoxShown} editData={editData?.course} courseId={editData?.course?.id} totalPropCount={totalPropCount} selectedPropId={selectedPropId} currentPropsIndex={currentPropsIndex} environmentName={editData.environment} />
    </BaseLayoutAdjustable>
  )
}

export default PlatformChamber