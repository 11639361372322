import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/environment'

export const environmentState = {
    status: 'idle',
    data: null,
    deleted: false
}
export default {
    environment: reducerWithInitialState(environmentState)
        .case(actions.createenvironment.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createenvironment.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.environment },
            })
        })
        .case(actions.createenvironment.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getenvironmentbyescaperoomid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getenvironmentbyescaperoomid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.environment },
            })
        })
        .case(actions.getenvironmentbyescaperoomid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updateenvironment.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updateenvironment.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.environment },
            })
        })
        .case(actions.updateenvironment.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deleteenvironment.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.deleteenvironment.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.deleteenvironment.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
    ,
}
