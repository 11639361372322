import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateCorporateInfoParam { accountpackage: any }
interface CreateCorporateInfoResult { accountpackage: any }
interface CreateCorporateInfoError { error: string }

interface GetCorporateInfoParam { userid: string }
interface GetCorporateInfoResult { accountpackage: any }
interface GetCorporateInfoError { error: string }

interface UpdateCorporateInfoParam { userid: string, accountpackage: any }
interface UpdateCorporateInfoResult { accountpackage: any }
interface UpdateCorporateInfoError { error: string }

export default {
    createaccountpackage: ac.async<CreateCorporateInfoParam, CreateCorporateInfoResult, CreateCorporateInfoError>(ActionTypes.CREATE_ACCOUNTPACKAGE),
    getaccountpackagebyuserid: ac.async<GetCorporateInfoParam, GetCorporateInfoResult, GetCorporateInfoError>(ActionTypes.GET_ACCOUNTPACKAGEBYUSERID),
    updateaccountpackagebyuserid: ac.async<UpdateCorporateInfoParam, UpdateCorporateInfoResult, UpdateCorporateInfoError>(ActionTypes.UPDATE_ACCOUNTPACKAGEBYUSERID),
}