import { propsConstants } from '../constants'

export const propsActions = {
    activeParam,
    activeParam1,
    locationType,
    propsType,
    propsName,
    file,
    levelSequence1,
    levelSequence2,
    levelSequence3,
    levelSequence4,
    phoneNumber1,
    phoneNumber2,
    phoneNumber3,
    phoneNumber4,
    propsNameparam,
    CourseManagerSetUpDetails,
    TableRowIndex,
    // getAllProps,
    updateProps,
    updateMessageTitle,
    updateMessageContent,
    updateWeblink,
    updatePDFimageUpload,
    updateQuizQns,
    updateQuizText1,
    updateQuizText2,
    updateQuizChoiceQns,
    updateQuizChoice,
    updateQuizChoiceA,
    updateQuizChoiceB,
    updateQuizChoiceC,
    updateQuizChoiceD,
    updateQuizChoiceCorrectAns,
    // getUsers
}

//Desc Active Param
function activeParam(param:string) {
    return {
        type:propsConstants.PARAM_ACTIVE_REQUEST,
        param
    }
}

function activeParam1(param: string) {
  return {
    type: propsConstants.PARAM_ACTIVE_REQUEST1,
    param
  }
}

function locationType(param: string) {
  return {
    type: propsConstants.PARAM_LOCATION_TYPE,
    param
  }
}

function propsType(param: string) {
  return {
    type: propsConstants.PARAM_PROPS_TYPE,
    param
  }
}

function propsName(param: string) {
  return {
    type: propsConstants.PARAM_PROPS_NAME,
    param
  }
}

function file(param: string) {
  return {
    type: propsConstants.PARAM_FILE,
    param
  }
}

function levelSequence1(param: string) {
  return {
    type: propsConstants.PARAM_LEVEL_SEQUENCE_1,
    param
  }
}

function levelSequence2(param: string) {
  return {
    type: propsConstants.PARAM_LEVEL_SEQUENCE_2,
    param
  }
}

function levelSequence3(param: string) {
  return {
    type: propsConstants.PARAM_LEVEL_SEQUENCE_3,
    param
  }
}

function levelSequence4(param: string) {
  return {
    type: propsConstants.PARAM_LEVEL_SEQUENCE_4,
    param
  }
}

function phoneNumber1(param: string) {
  return {
    type: propsConstants.PARAM_PHONE_NUMBER_1,
    param
  }
}

function phoneNumber2(param: string) {
  return {
    type: propsConstants.PARAM_PHONE_NUMBER_2,
    param
  }
}

function phoneNumber3(param: string) {
  return {
    type: propsConstants.PARAM_PHONE_NUMBER_3,
    param
  }
}

function phoneNumber4(param: string) {
  return {
    type: propsConstants.PARAM_PHONE_NUMBER_4,
    param
  }
}

function propsNameparam(param: string) {
  return {
    type: propsConstants.PROPS_NAME_PARAM,
    param
  }
}

function CourseManagerSetUpDetails(name: string, department: string, email: string, role: string, password: string, passwordSymbol: string) {
  return {
    type: propsConstants.COURSE_MANAGER_SETUP_DETAILS,
    name,
    department,
    email,
    role,
    password,
    passwordSymbol
  }
}

function TableRowIndex(index: any, checked: boolean) {
  return {
    type: propsConstants.TABLE_ROW_INDEX,
    index,
    checked
  }
}

// function getAllProps(){
//     return {
//         type: propsConstants.GET_PROPS_REQUEST
//     }
// }

// original
// function updateProps(paramType:string,paramValue:string,id:string) {
//     return {
//         type:propsConstants.UPDATE_PROPS_REQUEST,
//         paramType,
//         paramValue,
//         id
//     }
// }

// function updateProps(value: string, id: string) {
function updateProps(value: string, id: string) {
  return {
    type: propsConstants.UPDATE_PROPS_REQUEST,
    value,
    id
  }
}

// function getUsers(value: string, id: string) {
//   return {
//     type: propsConstants.UPDATE_PROPS_REQUEST,
//     value,
//     id
//   }
// }

function updateMessageTitle(value:string) {
    return {
        type:propsConstants.UPDATE_MESSAGE_TITLE_REQUEST,
        value,
    }
}

function updateMessageContent(value2: string) {
  return {
    type: propsConstants.UPDATE_MESSAGE_CONTENT_REQUEST,
    value2,
  }
}

function updateWeblink(value: string) {
// function updateWeblink(value1: string) {
  return {
    type: propsConstants.UPDATE_WEBLINK_REQUEST,
    value
    // value1
  }
}

function updatePDFimageUpload(value: string) {
  return {
    type: propsConstants.UPDATE_WEBLINK_REQUEST,
    value
  }
}

function updateQuizQns(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_QNS,
    value
  }
}

function updateQuizText1(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_TEXT1,
    value
  }
}

function updateQuizText2(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_TEXT2,
    value
  }
}

function updateQuizChoiceQns(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_QUESTION,
    value
  }
}

function updateQuizChoice(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE,
    value
  }
}

function updateQuizChoiceA(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_A,
    value
  }
}

function updateQuizChoiceB(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_B,
    value
  }
}
function updateQuizChoiceC(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_C,
    value
  }
}
function updateQuizChoiceD(value: string) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_D,
    value
  }
}
function updateQuizChoiceCorrectAns(value: any) {
  return {
    type: propsConstants.UPDATE_QUIZ_CHOICE_CORRECT_ANS,
    value
  }
}




