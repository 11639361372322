import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const RoutePublic = ({ component: Component, isAuthenticated, accountType, path, exact = false }: { component: React.FC<any>, isAuthenticated: boolean, accountType: any | null, path: string, exact?: boolean }) => (
    <Route
        path={path}
        exact={exact}
        render={props =>
            isAuthenticated ? (
                accountType !== null ? (accountType.user.accounttype == "ADMINISTRATOR" ? <Redirect to="/admiral/selection"/> : accountType.user.accounttype == "CORPORATE" ? <Redirect to="/corporate/overview"/> : accountType.user.accounttype == "MANAGER" ? <Redirect to="/coursemanager/overview"/> : accountType.user.accounttype == "TRAINEE" ? <Redirect to="/trainee/overview"/> : accountType.user.accounttype == "AGENT" ? <Redirect to="/agent/overview"/> : null )  : null
            ) : (
                <Component {...props} />
            )
        }
        // render={props =>
        //     (
        //         <Component {...props} />
        //     )
        // }
    />
)

RoutePublic.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    accountType: PropTypes.any,
}

export default RoutePublic
