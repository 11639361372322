import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreatePropsParam { props: any }
interface CreatePropsResult { props: any }
interface CreatePropsError { error: string }

interface CreateInitialPropsParam { courseid: any }
interface CreateInitialPropsResult { props: any }
interface CreateInitialPropsError { error: string }

interface GetPropsParam { courseid: string, locationname: string }
interface GetPropsResult { props: any }
interface GetPropsError { error: string }

interface GetPropsByLocationIdParam { courseid: string, locationname: string, locationid: string, timer: number }
interface GetPropsByLocationIdResult { props: any }
interface GetPropsByLocationIdError { error: string }

interface UpdatePropsParam { propsid: string, props: any }
interface UpdatePropsResult { props: any }
interface UpdatePropsError { error: string }

interface DeletePropsParam { propsid: string }
interface DeletePropsResult { props: any }
interface DeletePropsError { error: string }

export default {
    createprops: ac.async<CreatePropsParam, CreatePropsResult, CreatePropsError>(ActionTypes.CREATE_PROPS),
    createinitialprops: ac.async<CreateInitialPropsParam, CreateInitialPropsResult, CreateInitialPropsError>(ActionTypes.CREATE_INITIAL_PROPS),
    getpropsbycourseid: ac.async<GetPropsParam, GetPropsResult, GetPropsError>(ActionTypes.GET_PROPSBYCOURSEID),
    getpropsbycourseidandlocationid: ac.async<GetPropsByLocationIdParam, GetPropsByLocationIdResult, GetPropsByLocationIdError>(ActionTypes.GET_PROPSBYCOURSEIDANDLOCATIONID),
    updateprops: ac.async<UpdatePropsParam, UpdatePropsResult, UpdatePropsError>(ActionTypes.UPDATE_PROPS),
    deleteprops: ac.async<DeletePropsParam, DeletePropsResult, DeletePropsError>(ActionTypes.DELETE_PROPS),
}