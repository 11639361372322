import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface ModalShowParam { type: string, source: any }
interface ModalShowResult { data: any }
interface ModalShowError { error: string }

interface ModalHideParam { }
interface ModalHideResult { }
interface ModalHideError { error: string }

export default {
    show: ac.async<ModalShowParam, ModalShowResult, ModalShowError>(ActionTypes.SHOW_MODAL),
    hide: ac.async<ModalHideParam, ModalHideResult, ModalHideError>(ActionTypes.HIDE_MODAL),
}