
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import textbg from 'assets/textfield.svg'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import Calendar from 'assets/BaseImg/calendar.svg'

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import modal from 'actions/modal'
import corporateinfo from 'actions/corporateinfo'

import 'assets/stylesheets/application.css'
import AccountInfoNew from 'components/AccountInfoNew'

import "swiper/swiper.min.css";
import 'assets/stylesheets/pagination.min.css'
//import "swiper/components/navigation/navigation.min.css"

import arrowLeft from 'assets/swiperItems/arrowYellowLeft.png'
import arrowRight from 'assets/swiperItems/arrowYellowRight.png'

import enableButton from 'assets/ToggleButton/enableButton.png'
import disableButton from 'assets/ToggleButton/disableButton.png'

import user from 'actions/user';
import auth from 'actions/auth';
import tips from 'assets/tipsandtuts/tips.svg'
import tutorials from 'assets/tipsandtuts/tutorials.svg'
import manual from 'assets/manual.svg'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapperContainer: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
  },
  inputWrapperContainerHidden: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
    position: "fixed",
    bottom: "74vw",
  },
  inputWrapper: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#ffd84d',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2em',
      width: '22rem',
      lineHeight: '58px',
      cursor: 'default',
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperHidden: {
    opacity: 0,
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'text',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#ffd84d',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.5vw',
      width: '22rem',
      cursor: 'text',
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperDropdown: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& select': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    '& img': {
      width: '25vw'
    }
  },
  input: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: "23rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },

    '&::after': {
      position: 'absolute',
      content: '',
      right: 0,
      top: 0,
    },

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    padding: '0px 48px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    height: '100%',
    fontFamily: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  togglebutton: {
    top: '0px',
    right: '25px',
    position: 'absolute',
    height: '100%',
    maxHeight: 'none',
  },
  InputAPass: {
    color: 'white',
  },
  row2: {
    display: 'flex',
    marginTop: '3rem'
  },
  swiperWrapper: {
    width: "100vw",
    height: "84vh",
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  tips: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  tutorials: {
    left: '9.6vw',
    width: '60px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  },
  manual: {
    left: '5vw',
    width: '50px',
    bottom: '10vh',
    height: '50px',
    position: 'absolute',
    cursor: 'pointer',
    textAlign: 'center',

    '& img': {
      width: '100%',
      objectFit: 'contain',
      height: '100%',
    },

    '& p': {
      color: '#FFD84E',
      fontsize: '1.2rem',
      fontFamily: 'impact',
    }
  }
}));

const CoporateAccountEdit = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const error = useSelector((state: any) => state.auth.error)
  const agents = useSelector((state: any) => state.user.data)
  const editData = useSelector((state: any) => state.router.location.state)

  const mydate = require('current-date')

  const [hasDateInputBeenClick, sethasDateInputBeenClick] = useState(true)
  const [toggleOption, settoggleOption] = useState('enable')
  const [MasterAccName, setMasterAccName] = useState(editData.name)
  const [Agent, setAgent] = useState(editData.belongstoagent)
  const [MasterAccEmail, setMasterAccEmail] = useState(editData.email)
  const [CompanyName, setCompanyName] = useState(editData.corporateinfo.companyname)
  const [MasterAccPassword, setMasterAccPassword] = useState('MASTER ACCOUNT MANAGER PASSWORD*')
  const [CorporateType, setCorporateType] = useState(editData.corporateinfo.corporatetype)
  const [StartDate, setStartDate] = useState(editData.corporateinfo.startdate)
  const [ValidityPeriod, setValidityPeriod] = useState(editData.corporateinfo.enddate)
  const [SessionPackage, setSessionPackage] = useState(editData.corporateinfo.validsessions)
  const [EnableDisable, setEnableDisable] = useState(editData.status)
  const [values, setValues] = React.useState({password: MasterAccPassword, showPassword: false});

  function retrieveInfoFromInput1(inputType: any, input: any) {
    inputType(input)
  }

  const saveButtonClicked = () => {
    let country;
    let contacts;
    let agentname;
    let agentid;

    agents.data.map((data: any) => {
      if(Agent == data.id) {
        country = data.country;
        contacts = data.contacts;
        agentname = data.name;
        agentid = data.id;
      }
    })

    if(MasterAccPassword == "MASTER ACCOUNT MANAGER PASSWORD*") {
      dispatch(user.updateuserbyid.started({userid: editData.id,
        user: {
          "name": MasterAccName,
          "accounttype": "CORPORATE",
          "status": EnableDisable,
          "country": country,
          "contacts": contacts,
          "belongstouser": id,
          "belongstoagent": agentid
        }
      }))
    } else {
      dispatch(user.updateuserbyid.started({userid: editData.id,
        user: {
          "name": MasterAccName,
          "accounttype": "CORPORATE",
          "password": MasterAccPassword,
          "status": EnableDisable,
          "country": country,
          "contacts": contacts,
          "belongstouser": id,
          "belongstoagent": agentid
        }
      }))
    }

    dispatch(corporateinfo.updatecorporateinfobyid.started({userid: editData.corporateinfo.id,
      corporateinfo: {
      "companyname": CompanyName,
      "validsessions": SessionPackage,
      "corporatetype": CorporateType,
      "agentname": agentname,
      "startdate": StartDate,
      "enddate": ValidityPeriod,
      "user": editData.id
    }}));
    dispatch(modal.show.started({type: 'saveCorporateSetup', source: ''}))
  }

  let year  = new Date(StartDate).getFullYear();
  let month = new Date(StartDate).getMonth();
  let day   = new Date(StartDate).getDate();

  useEffect(() => {
    dispatch(user.getuserbyaccounttype.started({userid: id, accounttype: 'AGENT'}))

    year = new Date(StartDate).getFullYear();
    month = new Date(StartDate).getMonth();
    day = new Date(StartDate).getDate();

    if(editData.status) {
      settoggleOption('enable');
    } else {
      settoggleOption('disable');
    }
    customToggleButton(toggleOption);

    if(error == true ) {
      dispatch(modal.show.started({type: 'formHasErrors', source: ''}))
    }

    return () => {
    };
  }, [error]);

  const [isSwiperClicked, setisSwiperClicked] = useState(false)

  function arrowButtonClicked(whichDirection: any) {
    if (whichDirection === 'right') {
      setisSwiperClicked(true)
    } else {
      setisSwiperClicked(false)
    }
  }

  const arrowButtonSeen = (determiningFactor: any) => {
    if (determiningFactor === true) {
      return (
        <div className="arrowBox">
          <div onClick={() => arrowButtonClicked('left')}><img src={arrowLeft} alt="arrowLeft" /> PREVIOUS PAGE</div>
          <img src={arrowRight} alt="arrowRight" style={{opacity: 0}} />
        </div>
      )
    } else {
      return(
        <div className="arrowBox">
          <div><img src={arrowLeft} alt="arrowLeft" style={{ opacity: 0 }} /></div>
          <div onClick={() => arrowButtonClicked('right')}>NEXT PAGE<img src={arrowRight} alt="arrowRight"  /></div>
        </div>
      )
    }
  }

  const disableButtonClicked = () => {
    settoggleOption('enable')
    setEnableDisable(true)
  }
  const enableButtonClicked = () => {
    settoggleOption('disable')
    setEnableDisable(false)
  }
  const customToggleButton = (isItEnableOrDisable: any) => {
    if (isItEnableOrDisable === 'disable') {
      return (
        <img src={disableButton} alt="disableButton" onClick={() => disableButtonClicked()}/>
      )
    } else {
      return (
        <img src={enableButton} alt="enableButton" onClick={() => enableButtonClicked()}/>
      )
    }
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const swiperTestPaginationIcon = (LeftorRight: any) => {
    if (LeftorRight === true) {
      return (
        <div className="swiperTestPagination">
          <p>2 / 2</p>
        </div>
      )
    }
    else {
      return (
        <div className="swiperTestPagination">
          <p>1 / 2</p>
        </div>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div>
      <div className="SwiperTestMainContainer">
        <h3>EDIT EXISTING CORPORATE ACCOUNT</h3>
        <h5>*INDICATES REQUIRED FIELD</h5>
        <div className="swiperTestContainer">
          {arrowButtonSeen(isSwiperClicked)}
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} value={MasterAccName} placeholder="MASTER ACCOUNT MANAGER NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setMasterAccName, e.target.value)} />
            </div>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <select value={Agent} onChange={(e) => retrieveInfoFromInput1(setAgent, e.target.value)}>
                <option value="DEFAULT">SELECT AGENTS / RESELLERS NAME*</option>
                {agents ?
                  agents.data.map((data: any) => {
                    return <option key={data.id} value={data.id}>{data.name}</option>
                  })
                : null}
              </select>
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="off" className={classes.input} value={MasterAccEmail} placeholder="MASTER ACCOUNT MANAGER EMAIL*" type="text" onChange={(e) => retrieveInfoFromInput1(setMasterAccEmail, e.target.value)} /> */}
              <p className={classes.input}>{MasterAccEmail}</p>
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} value={CompanyName} placeholder="COMPANY NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setCompanyName, e.target.value)} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="new-password" className={classes.input} value={MasterAccPassword} placeholder="MASTER ACCOUNT MANAGER PASSWORD*" type="password" onChange={(e) => retrieveInfoFromInput1(setMasterAccPassword, e.target.value)} /> */}
              <input
                placeholder="MASTER ACCOUNT MANAGER PASSWORD*"
                type={values.showPassword ? 'text' : 'password'}
                value={MasterAccPassword}
                autoComplete="new-password"
                className={classes.inputPass}
                onChange={(e) => retrieveInfoFromInput1(setMasterAccPassword, e.target.value)}
              />
              <InputAdornment position="end" className={classes.togglebutton}>
                    <IconButton className={classes.InputAPass}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            </div>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <select value={CorporateType} onChange={(e) => retrieveInfoFromInput1(setCorporateType, e.target.value)}>
                <option value="DEFAULT">SELECT TYPE*</option>
                <option value="BASIC">BASIC</option>
                <option value="PREMIUM">PREMIUM</option>
              </select>
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={new Date(StartDate).toISOString()?.replace(/T.*/,'').split('-').join('-')} className={classes.input} placeholder="SELECT START DATE OF ACCOUNT*" type={hasDateInputBeenClick === true ? "date" : "text"} min={mydate('date')} onClick={() => sethasDateInputBeenClick(true)} onChange={(e) => {
                  retrieveInfoFromInput1(setStartDate, new Date(e.target.value))
                }} />
            </div>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <select value={ValidityPeriod} onChange={(e) => retrieveInfoFromInput1(setValidityPeriod, e.target.value)}>
                <option value="DEFAULT">SELECT VALIDITY PERIOD*</option>
                <option value={new Date(year + 1, month, day + 1).toISOString()}>1 YEAR</option>
                <option value={new Date(year + 2, month, day + 1).toISOString()}>2 YEARS</option>
                <option value={new Date(year + 3, month, day + 1).toISOString()}>3 YEARS</option>
              </select>
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} value={SessionPackage} placeholder="SET NUMBER OF SESSIONS*" type="NUMBER" onChange={(e) => retrieveInfoFromInput1(setSessionPackage, e.target.value)} />
            </div>
            <div className={classes.inputWrapper}>
              {customToggleButton(toggleOption)}
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperHidden}>
              <img src={textbg} alt="bg" />
            </div>
            <div className={classes.inputWrapperHidden}>
              <img src={textbg} alt="bg" />
            </div>
          </div>
        </div>
      </div>
      {swiperTestPaginationIcon(isSwiperClicked)}
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/admiral/corporatelisting'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p onClick={() => dispatch(push('/admiral/corporatelisting'))}>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="BackSymbol" />
          <p>SAVE</p>
        </div>
      </div>
      {/* <div className={classes.tips} onClick={() => dispatch(modal.show.started({type: 'showTips', source: ''}))} >
        <img src={tips}/>
        <p>TIPS</p>
      </div>
      <div className={classes.tutorials} onClick={() => dispatch(push('/corporate/videotutorials'))} >
        <img src={tutorials}/>
        <p>TUTORIALS</p>
      </div> */}
      <a className={classes.manual} href="https://www.createscape.com.sg/documents/admiral-account-user-manual.pdf" target="_blank" >
        <img src={manual}/>
        <p>MANUAL</p>
      </a>
    </BaseLayoutAdjustable>
  )
}

export default CoporateAccountEdit;
