import React, { useEffect } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { propsActions } from 'actions/propsActions';

interface Props {
  options: Array<{
    option1: string;
    option2: string;
    option3: string;
    option4: string;
  }>;
}

const useStyles = makeStyles(() => ({
  Group: {
    backgroundColor: 'none',
    border: 'none',
    position: 'relative',
    top: '0.5rem',
    width: '10.5rem',
    height: '1.7rem',
    marginRight: '2rem',
    '& .Mui-selected': {
      backgroundColor: '#F4D000',
      color: '#151514'
    },
    '& .MuiToggleButton - root': {
      '& hover': {
        backgroundColor: '#F4D000',
        color: '#151514'
      }
    }
  },
  B1: {
    backgroundColor: '#342D12',
    color: '#FFFFFF',
  },
  word1: {
    fontFamily: 'Lato',
    fontSize: '1.3rem',
    fontWeight: 100,
  },
}))

function ToggleButton4Choice({ options }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const quizChoiceCorrectAns = useSelector((state: any) => state.propsReducer.quizChoiceCorrectAnsValue)

  //const [alignment, setAlignment] = React.useState<string | null>(quizChoiceCorrectAns);

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    //setAlignment(newAlignment);
    dispatch(propsActions.updateQuizChoiceCorrectAns(newAlignment))
  };

  useEffect(() => {
    dispatch(propsActions.updateQuizChoiceCorrectAns(quizChoiceCorrectAns))
  }, []);

  return (
    <ToggleButtonGroup
      value={quizChoiceCorrectAns}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      className={classes.Group}
    >
      <ToggleButton value="A" aria-label="right aligned" className={classes.B1}>
        {options.map((option) => (
          <h1 className={classes.word1} key={option.option1}>{option.option1}</h1>
        ))}
      </ToggleButton>
      <ToggleButton value="B" aria-label="right aligned" className={classes.B1}>
        {options.map((option) => (
          <h1 className={classes.word1} key={option.option2}>{option.option2}</h1>
        ))}
      </ToggleButton>
      <ToggleButton value="C" aria-label="right aligned" className={classes.B1}>
        {options.map((option) => (
          <h1 className={classes.word1} key={option.option3}>{option.option3}</h1>
        ))}
      </ToggleButton>
      <ToggleButton value="D" aria-label="right aligned" className={classes.B1}>
        {options.map((option) => (
          <h1 className={classes.word1} key={option.option4}>{option.option4}</h1>
        ))}
      </ToggleButton>

    </ToggleButtonGroup>
  );
}

export default ToggleButton4Choice;