import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/emailvalidation'

export const validationState = {
    status: 'idle',
    data: null,
}
export default {
    emailvalidation: reducerWithInitialState(validationState)
        .case(actions.createemailvalidation.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createemailvalidation.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.emailvalidation },
            })
        })
        .case(actions.createemailvalidation.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.getemailvalidation.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.getemailvalidation.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.emailvalidation },
            })
        })
        .case(actions.getemailvalidation.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.deleteemailvalidation.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.deleteemailvalidation.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.emailvalidation },
            })
        })
        .case(actions.deleteemailvalidation.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
    ,
}
