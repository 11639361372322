import React from "react";
import SimpleBarReact from "simplebar-react";
import noneSelected from 'assets/platform/galleryBoard/propsType/noneSelected.png'

import "simplebar/src/simplebar.css";

function Simplebar() {
  return (
    <div>
      <SimpleBarReact style={{ maxHeight: 300 }}>

      </SimpleBarReact>
    </div>
  );
}

export default Simplebar;
