import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/traineeinfo'

export const traineeinfoState = {
    status: 'idle',
    data: null,
}
export default {
    traineeinfo: reducerWithInitialState(traineeinfoState)
        .case(actions.createtraineeinfo.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.createtraineeinfo.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.traineeinfo },
            })
        })
        .case(actions.createtraineeinfo.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.gettraineeinfobyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.gettraineeinfobyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.traineeinfo },
            })
        })
        .case(actions.gettraineeinfobyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
        .case(actions.updatetraineeinfobyuserid.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null },
            })
        })
        .case(actions.updatetraineeinfobyuserid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.traineeinfo },
            })
        })
        .case(actions.gettraineeinfobyuserid.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null },
            })
        })
    ,
}
